.bild-row {
  min-height: 145px;
}


@media (max-width: 575.98px) {
  .bild-row {
    min-height: 113px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .ipad-col-padding{
    padding-left: 60px!important;
    padding-top:40px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bild-row {
    min-height: 145px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
