.bike-info {
  height: auto;
  width: 205.11px;
}
.bike-info-rotate {
  height: auto;
  width: 89.03px;
}
.landscape-title {
  height: 27px;
  width: 292px;
  color: #515151;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 26.37px;
  text-align: center;
}
.landscape-text {
  height: 38px;
  width: 199px;
  color: #515151;
  font-family: "Work Sans";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.92px;
  line-height: 16px;
  text-align: center;
}
