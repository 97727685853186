.chatwrapper-markt {
	position: fixed;
	right: 25px;
	bottom: 0;
	width: 280px;
	max-height: calc(100vh - 6.4rem);
	z-index: 1000;
	.char-card-markt {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 34px;
		border-top-right-radius: 34px;
		background-color: #FFFFFF;
		box-shadow: 0 7px 11px 0 rgba(0, 0, 0, 0.18);
	}
	#chatContactsList {
		//height: calc(100vh - 10.4rem);
	}
}

.userProfileName-icon{
	height: 44px;
	width: 44px;
	border-radius: 50%;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	color: #fff;
	background-color: #00A441;
	font-family: "Work Sans";
	font-size: 14px;
}
.landingmarkt-top-padding {
	padding-top: 150px;
}
.landingmarkt-middle-padding {
	padding-top: 200px;
}
.landingmarkt-bottom-padding {
	padding-top: 30px;
}

.b2b-main-bottom-padding {
	padding-bottom: 165px;
	padding-top: 30px;
}
.landingmarkt-advertwrapper {
  position: absolute;
  top: 10.2rem;
  left: 0rem;
}


.fixed-top-landingmarkt {
	position: fixed;
	top: 0;
	z-index: 1005;
}

.landingmarkt-top {
	min-height: 100vh;
	background-image: url('../../media/images/markt/Group 17.png');
	background-color: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(242,239,239,0.96) 84.6%, #F2EFEF 100%);
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.landingmarkt-top-addbike {
	min-height: 88vh;
	background-image: url('../../media/images/markt/Group 17.png');
	background-color: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(242,239,239,0.96) 84.6%, #F2EFEF 100%);
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.landingmarkt-midle1 {
	min-height: 100vh;
	background-image: url('../../media/images/markt/Group 32.png');
	background-color: #FFFFFF;
	background-size: 20% auto;
	background-repeat: no-repeat;
	background-position: left 35%;
}

.landingmarkt-midle2 {
	min-height: 100vh;
	background-image: url('../../media/images/markt/Group 16.png');
	background-color: #F1F1F1;
	background-size: 20% auto;
	background-repeat: no-repeat;
	background-position: right 80px;
}


.landingmarkt-bottom {
	min-height: 80vh;
	//background-image: url('../../media/images/historypublicbg.png');
	background-color: #292929;
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.logo-landingmarkt{
	width: 112px;
	height: 101px;
}

.e-bikeborse-logo2 {
  height: auto;
  width: 609.88px;
}

.e-bikeborse-logo3 {
  height: 95px;
  width: auto;
}

.padding-row-logo2 {
	padding-top: 300px;
}

.padding-row-logo3 {
	padding-top: 120px;
}

.padding-row-logo13 {
	padding-top: 85px;
}

.padding-row-logo4 {
	padding-top: 30px;
}

.padding-row-logo5 {
	padding-top: 240px;
	padding-bottom:110px;
}

.padding-row-logo6 {
	padding-top: 60px;
}
.padding-row-logo8 {
	padding-top: 60px;
}
.padding-row-logo7 {
	padding-top: 80px;
}

.signin-btn-markt {
	width: 150px;
	height: 32px;
	padding-top: 4px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #FF9500!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}



.landingmarkt-partner-logo {
	max-width: 60%;
	height: auto;
}



.search-col-b2b {
	padding-left: 17px;
	padding-right: 0;
	max-width: 480px;
	.md-form{
    margin-bottom: 1rem;
    margin-top: 1.2rem;
		button.mdb-autocomplete-clear svg {
		  margin-top: 4px;
		}
		label {
			left: 15px;
			margin-top: -3px;
      opacity: 0.4;
			color: #919191;
			font-family: "Work Sans";
			font-size: 16px;
			&.active {
				display:none;
			}
		}
		input[type=text].search-input {
      height: 42px;
  		border-radius: 10px;
  		border: .5px solid #ccc8c8 !important;
  		margin-bottom: 0.375em;
  		padding: 0rem 1.2rem;
  		background-color: #FFFFFF;
  		color: #000000 !important;
  		font-family: "Work Sans";
  		font-size: 16px;
  		font-weight: 300;
  		letter-spacing: 0;
  		line-height: 14px;
  		text-align: left;
			&:focus{
				border-bottom: 0;
				box-shadow: none;
				&+label{
					display:none;

				}
			}
		}
	}
}

.product-search-b2b-header {
	padding-left: 0px;
	padding-right: 0;
	max-width: 274px;
	margin-top: 4.5px;
	width: 100%;
	.form-control{
		height: 42px;
		border-radius: 11px;
		background-color: #FFFFFF;
		border-bottom: 0;
		padding-left: 15px;
		padding-top:7px;
		margin-top: 1.5rem;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
}



.search-col-borse-header {
	padding-left: 0px;
	padding-right: 0;
	max-width: 274px;
	margin-top: 4.5px;
	.md-form{
		margin-top: .3rem;
		margin-bottom: 0rem;
		input[type=text].search-input {
			height: 40px;
		  border-radius: 11px;
		  background-color: #FFFFFF;
			border-bottom: 0;
			padding-left: 15px;
			padding-top:7px;
			color: #919191;
			font-family: "Work Sans";
			font-size: 16px;
		}
	}
}

.search-col-borse-header-btn {
	margin-top: 3px;
}



.filter-btn-landingmarkt {
	height: 30px;
  min-width: 80px;
  border-radius: 6px;
  background-color: #515151!important;
	padding: 0rem 1.2rem;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.filter-btn-addKategorie {
	height: 42px;
  min-width: 432px;
	border-radius: 0.3125em;
	border: 2px solid #797575;
	min-height: 42px;
	margin-bottom: 0.375em;
	padding: 0rem 1.2rem;
  color: #0c9845!important;
  font-family: "Work Sans";
	text-transform: unset !important;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}
.filter-btn-addKategorie:hover {
	background-color: #c1bfbf !important;
	color: #065125!important;
}

.filter-btn-back {
	height: 42px;
  min-width: 432px;
	border-radius: 0.3125em;
	border: 2px solid #797575;
	margin-bottom: 0.375em;
	padding: 0rem 1.2rem;
	background-color: #c1bfbf !important;
  color: #0c9845!important;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}
.filter-btn-back:hover {
	color: #065125!important;
}

.form-outline-markt {
	.form-control{
		height: 42px;
		border-radius: 0.3125em;
		border: .5px solid #ccc8c8 !important;
		margin-bottom: 0.375em;
		padding: 0rem 1.2rem;
		background-color: #FFFFFF;
		color: #0c9845 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.select-wrapper {
	  position: relative;
	  background-color: #e9e9ed !important;
	}
	.fadeElement{
		top: 2.6rem!important;
		left: -0.1rem;
		background-color: #000;
		width: 102%;
	.fadeIn{
			top: 2.6rem!important;
			left: -0.1rem;
			background-color: #000;
			width: 102%;
		}
	}
	.dropdown-content li{
		background-color: #000;
		line-height: 0.9rem;
		:hover {
			background-color: #5b5959;
		}
		&.active{
			background-color: #5b5959;
		}
		span{
			color: #FFFFFF!important;
		  font-family: "Work Sans";
		  font-size: 16px;
		}
	}
	.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
	  color: rgba(255, 255, 255, 0.5) !important;
	  cursor: context-menu;
	  background-color: transparent !important;
	}
	.md-form {
		margin-top: 0;
		input[type="text"]:not(.browser-default){
			border-bottom: none;
		}
	}
	.select-wrapper span.caret {
	  margin-right: 15px;
	}
	.price-currency {
		margin-left: 0.375em;
		display: inline;
		font-size: 1.375em;
		line-height: 1.09091em;
		font-weight: 600;
		font-family: "Work Sans";
	}
	.form-label{
		color: #000000 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.form-anmerkung{
		font-family: "Work Sans";
		color: gray;
		font-size: 0.8125em;
		line-height: 1.38462em;
	}
}
.ckeditor-padding{
	padding-top:5px;
	padding-bottom:10px;
}

.btn-bild-hinzu {
  position: relative;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.liebe-interessenten-mark {
  height: 90px;
  width: 650px;
  color: #808080;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.row-markt-input{
	max-width: 550px;
	padding-bottom:80px;
}

.rectangle-footer-markt {
	min-height: 12vh;
	width: 100%;
	background-color: #1D1D1D;
	position: absolute;
	bottom: 0;
}

.rectangle-footer-price {
	min-height: 15vh;
	width: 100%;
	background-color: #1D1D1D;
}

.rectangle-footer-addBike {
	min-height: 12vh;
	width: 100%;
	background-color: #1D1D1D;
	//position: fixed;
	//bottom: 0;
	//z-index: 3;
}

.faq-border {
	box-sizing: border-box;
  width: 745px;
	min-height: 53px;
  border: 1.5px solid #515151;
  border-radius: 15px;
}

.arrow-white {
	height: 10.04px;
  width: auto;
}
.collapse-titel {
	color: #515151;
	font-family: "Work Sans";
	font-size: 20.91px;
	font-weight: 500;
	letter-spacing: 0.42px;
	line-height: 26.37px;
}
.card-header {
  border: none;
	border-top-left-radius: 15px!important;
	border-top-right-radius: 15px!important;
	border-bottom-right-radius: 15px!important;
	border-bottom-left-radius: 15px!important;
	background-color: transparent;
}
.collapse-text {
  color: #515151;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
.collapse-text-row {
	padding-bottom:10px;
	padding-top: 10px;
	margin: 0;
}

.news-card-borse {
	min-height: 356px;
	width: 100%;
	border-radius: 8px;
	margin-bottom: 20px;
	padding-top:25px;
	padding-bottom:15px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #FFFFFF;
	box-shadow: none;
}


.news-card-borse-first {
	height: 256px;
	width: 100%;
	border-radius: 8px;
	margin-bottom: 20px;
	padding-top:0px;
	padding-bottom:0px;
	padding-left: 0px;
	padding-right: 20px;
	background-color: #FFFFFF;
	box-shadow: none;
}

.news-image-borse {
	width: auto;
	height: 164.33px;
	max-width: 100%;
}

.news-image-borse-first {
	width: auto;
	height: 256px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}


.news-title-borse {
  color: #000000;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 26.37px;
}

.news-title-borse-first {
	height: 93px;
	width: 656px;
	color: #000000;
	font-family: "Work Sans";
	font-size: 31.91px;
	font-weight: 500;
	letter-spacing: 0.64px;
	line-height: 36px;
}

.news-url-borse {
	box-sizing: border-box;
  height: 26.07px;
  width: 184px;
  border: 1.05px solid #ECECEC;
  border-radius: 20.91px;
}

.news-url-text-borse {
  color: #9E9E9E;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-align: center;
}

.news-date-text-borse {
	color: #9E9E9E;
	font-family: "Work Sans";
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 31.37px;
}

.news-btn-borse {
	height: 26.07px;
  width: 92.81px;
	padding-top: 6px;
	padding-left: 2px;
	padding-right: 0px;
  border-radius: 20.91px;
  background-color: #00A441!important;
	text-transform: none;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-align: center;
	display: inline-block;
}
.news-btn-b2b {
	height: 26.07px;
  width: 92.81px;
	padding-top: 6px;
	padding-left: 2px;
	padding-right: 0px;
  border-radius: 20.91px;
  background-color: #FF9500!important;
	text-transform: none;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-align: center;
	display: inline-block;
}

.news-btn-icon-borse {
	height: 9.48px;
  width: 15.5px;
	margin-left: 5px;
	margin-top: -1px;
}

.blog-card {
	height: 71vh;
  width: 100%;
	border-radius: 8px;
	background-color: #F1F1F1;
}


.blog-card2 {
	height: 71vh;
  width: 100%;
	border-radius: 8px;
	background-color: #F1F1F1;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 3rem;
}

.blog-card3 {
  width: 100%;
	border-radius: 20px;
	background-color: #F1F1F1;
	margin-bottom: 110px;
}
.blog-card4 {
	height: 71vh;
  width: 100%;
	border-radius: 8px;
	background-color: #F1F1F1;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 3rem;
}

.icon-newspage {
	color: rgb(0, 0, 0);
}
.icon-newspage:hover{
	color: rgb(0, 0, 0);
	.allnews-btn{
		transition: all 0.3s ease;
		height: 40px;
	  width: 184px;
	  border-radius: 32.09px;
	  background-color: #00A441;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 1004;
	}
	.allnews-btn-b2b{
		transition: all 0.3s ease;
		height: 40px;
	  width: 184px;
	  border-radius: 32.09px;
	  background-color: #FF9500;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 1004;
	}
	.allblog-btn{
		transition: all 0.5s ease;
		height: 40px;
	  width: 184px;
	  border-radius: 32.09px;
	  background-color: #00A441;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 1004;
	}
	.allnews{
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 18.41px;
	  font-weight: bold;
	  letter-spacing: 0.77px;
	  line-height: 21px;
		position: absolute;
		top: 23%;
		left: 26%;
	}
	.allblog{
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 18.41px;
	  font-weight: bold;
	  letter-spacing: 0.77px;
	  line-height: 21px;
		position: absolute;
		top: 23%;
		left: 18%;
	}
}
.allnews-btn{
	height: 40px;
	width: 184px;
	border-radius: 32.09px;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 1004;
}
.allblog-btn{
	height: 40px;
	width: 184px;
	border-radius: 32.09px;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 1004;
}
.allnews{
	color: transparent;
	font-family: "Work Sans";
	font-size: 0px;
	///font-weight: bold;
	//letter-spacing: 0.77px;
	//line-height: 21px;
	position: absolute;
	top: 23%;
	left: 26%;
}
.allblog{
	color: transparent;
	font-family: "Work Sans";
	font-size: 0px;
	///font-weight: bold;
	//letter-spacing: 0.77px;
	//line-height: 21px;
	position: absolute;
	top: 23%;
	left: 18%;
}




.group-21-logo {
	height: 39.21px;
  width: 112.1px;
}

div.ScrollbarsCustom-Track:nth-child(2){
  background: rgba(0, 164, 65, 0.2) none repeat scroll 0% 0%!important;
  width: 6px!important;
  height: calc(100% - 120px)!important;
  top: 55px!important;
  border-radius: 3px!important;
  right: -12px!important;
}
#scrollbar div.ScrollbarsCustom-Wrapper{
	inset: 0px 0px 0px 0px!important;
}
#scrollbar-b2b div.ScrollbarsCustom-Track:nth-child(2){
  background: rgba(189, 187, 184, 0.2) none repeat scroll 0% 0%!important;
  width: 6px!important;
  height: calc(100% - 120px)!important;
  top: 55px!important;
  border-radius: 3px!important;
  right: -12px!important;
}
.ScrollbarsCustom-ThumbY {
  background: #00A441 none repeat scroll 0% 0%!important;
  border-radius: 3px!important;

}
#scrollbar-b2b .ScrollbarsCustom-ThumbY{
	background: #FF9500 none repeat scroll 0% 0%!important;
}

.gradient1 {
  height: 65px;
  width: 85%;
  bottom: 45px;
  position: absolute;
	border-radius: 8px;
	background: linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(244,244,244,0.58) 29.15%, rgba(242,242,242,0.87) 64.47%, #F1F1F1 100%);
}

.gradient2 {
  height: 65px;
  width: 100%;
	left: -1px;
  bottom: 45px;
  position: absolute;
	border-radius: 8px;
	background: linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(244,244,244,0.58) 29.15%, rgba(242,242,242,0.87) 64.47%, #F1F1F1 100%);
}
.landingmarkt-form {
	.md-form {
		margin-top: 9px;
		margin-bottom: 0px;
		padding-right: 10px;
		input[type=text].landingmarkt-input, input[type=password].landingmarkt-input, input[type=email].landingmarkt-input {
			width: 260px;
			height: 40px;
			background-color: #FFFFFF;
			border-radius: 15px;
			border-bottom: 1px solid #ced4da;
			border-top: 1px solid #ced4da;
			border-left: 1px solid #ced4da;
			border-right: 1px solid #ced4da;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			&:focus{
				border-color: #30445b !important;
				border: 2px solid #30445b !important;
				border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #30445B;
				}
			}

		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			top: 2px;
			&.active {
				transform: translateY(5px) scale(1);
				top: 0px;
			}
		}
	 }
}
.contact-btn-landingmarkt {
	width: 150px;
	height: 40px;
	border-radius: 15px;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #00A441!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.addbike-row-bottom{
	padding-bottom: 20px;
	padding-top: 40px!important;
}

.navitem-transition {
	transition: 2s ease;
}

.addbike-row-bottom-inser {
	padding-bottom: 20px;
}



.side-nav-menu {
	.deep-purple.darken-4 {
	  background-color: transparent!important;
	}
	.side-nav {
	  position: fixed;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100vh;
	  background-color: transparent!important;
	  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
	  border-radius: 0;
		padding:0;
		z-index: 1010;
		a {
			line-height: 13px!important;
			font-size: .8rem!important;
		}
		.scrollbar-container {
		  position: relative;
		  height: 100%;
		  overflow: -moz-hidden-unscrollable !important;
		}
		.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
		  display: none!important;
		  background-color: transparent;
		}
	}

	#sidenav-overlay {
	  position: fixed;
	  top: 0;
	  right: 0;
	  left: 0;
	  z-index: 997;
	  height: 100vh;
	  background-color: rgba(0,0,0,0.2);
	  will-change: opacity;
	}

}


@media (max-width: 575.98px) {
	.addbike-row-bottom {
	  padding-bottom: 20px;
	  padding-top: 90px!important;
	}
	.addbike-row-bottom-2 {
	  padding-bottom: 20px;
	  padding-top: 35px!important;
	}
	.landingmarkt-midle1 {
	  background-size: 100% auto;
	}
	.news-card-borse-first {
	  height: 175px;
	}
	.padding-row-logo5 {
	  padding-bottom: 10px;
		padding-top: 205px;
	}
	.e-bikeborse-logo3 {
	  height: auto;
	  width: 100%;
	}
	.padding-row-logo6 {
	  padding-top: 100px;
	}
	.padding-row-logo8 {
	  padding-top: 136px;
	}
	.padding-row-logo3 {
		padding-top: 145px;
	}
	.padding-row-logo13 {
		padding-top: 90px;
	}
	.filter-btn-addKategorie {
	  min-width: 100%;
		font-size: 14px;
	}
	.filter-btn-back {
	  min-width: 100%;
		font-size: 14px;
	}
	.landingmarkt-top-addbike {
		min-height: 100vh;
	}
	.landingmarkt-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landingmarkt-nav {
			justify-content: flex-end !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingmarkt-nav-container-scroll {
		padding-right: 20px;
		.landingmarkt-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.news-image-borse-first {
		width: 100%;
		height: auto;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.news-image-borse {
		width: auto;
		height: 75px;
	}
	.news-title-borse {
	  color: #000000;
	  font-family: "Work Sans";
	  font-size: 11px;
	  font-weight: 500;
	  letter-spacing: 0.42px;
	  line-height: 16px;
		height: 60px;
	}
	.news-title-borse-first {
		height: 59px;
		width: 100%;
		color: #000000;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.64px;
		line-height: 19px;
	}

	.news-url-borse {
	  height: 26px;
	  width: 100%;
	  border-radius: 13px;
	}

	.news-url-text-borse {
	  font-size: 9px;
	}

	.news-date-text-borse {
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 8px;
		font-weight: 500;
		letter-spacing: 0px;
		line-height: 11px;
	}

	.news-btn-borse {
		height: 26.07px;
	  width: 92.81px;
		padding-top: 6px;
		padding-left: 2px;
		padding-right: 0px;
	  border-radius: 20.91px;
	  background-color: #00A441!important;
		text-transform: none;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: bold;
	  letter-spacing: 0.5px;
	  line-height: 14px;
	  text-align: center;
		display: inline-block;
		&:after{
			color: #ffffff!important;
		}
	}
	.news-btn-b2b {
		height: 26.07px;
	  width: 92.81px;
		padding-top: 6px;
		padding-left: 2px;
		padding-right: 0px;
	  border-radius: 20.91px;
	  background-color: #FF9500!important;
		text-transform: none;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: bold;
	  letter-spacing: 0.5px;
	  line-height: 14px;
	  text-align: center;
		display: inline-block;
	}

	.news-btn-icon-borse {
		height: 9.48px;
	  width: 15.5px;
		margin-left: 5px;
		margin-top: -1px;
	}
	.collapse-titel {
		font-size: 16px;
	}
	.news-card-borse {
		min-height: 270px;
		width: 100%;
		border-radius: 5px;
		margin-bottom: 10px;
		padding-top:15px;
		padding-bottom:15px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #FFFFFF;
		box-shadow: 3px 10px 9px 6px rgba(16, 27, 79, 0.1);
	}
	.news-item-col {
		padding-left: 0;
	}

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.padding-row-logo6 {
		padding-top: 105px;
	}
	.addbike-row-bottom {
	  padding-bottom: 20px;
	  padding-top: 130px!important;
	}
	.addbike-row-bottom-2 {
		padding-bottom: 20px;
	  padding-top: 130px!important;
	}
	.e-bikeborse-logo3 {
	  height: auto;
	  width: 90%;
	}
	.landingmarkt-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landingmarkt-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingmarkt-nav-container-scroll {
		padding-right: 20px;

		.landingmarkt-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.news-item-col {
		padding-left: 1.5rem!important;
	}
	.padding-row-logo6 {
		padding-top: 105px;
	}
	.padding-row-logo8 {
		padding-top: 141px;
	}
	.news-card-borse {
		min-height: 356px;
		width: 100%;
		border-radius: 8px;
		margin-bottom: 20px;
		padding-top:25px;
		padding-bottom:15px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #FFFFFF;
		box-shadow: 3px 10px 9px 6px rgba(16, 27, 79, 0.1);
	}
	.first-card-col{
		padding-left: 1.5rem !important;
		padding-right: 1rem!important;
	}
	.news-image-borse-first {
		width: auto;
		height: 165px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}


	.news-title-borse {
	  color: #000000;
	  font-family: "Work Sans";
	  font-size: 18px;
	  font-weight: 500;
	  letter-spacing: 0.42px;
	  line-height: 26.37px;
	}

	.news-title-borse-first {
		height: 93px;
		width: 656px;
		color: #000000;
		font-family: "Work Sans";
		font-size: 25.91px;
		font-weight: 500;
		letter-spacing: 0.64px;
		line-height: 36px;
	}

	.news-url-borse {
		box-sizing: border-box;
	  height: 26.07px;
	  width: 184px;
	  border: 1.05px solid #ECECEC;
	  border-radius: 20.91px;
	}

	.news-url-text-borse {
	  color: #9E9E9E;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0.5px;
	  line-height: 14px;
	  text-align: center;
	}

	.news-date-text-borse {
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 31.37px;
	}

	.news-btn-borse {
		height: 26.07px;
	  width: 92.81px;
		padding-top: 6px;
		padding-left: 2px;
		padding-right: 0px;
	  border-radius: 20.91px;
	  background-color: #00A441!important;
		text-transform: none;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: bold;
	  letter-spacing: 0.5px;
	  line-height: 14px;
	  text-align: center;
		display: inline-block;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.form-outline-markt {
		.form-control{
			height: 42px;
			border-radius: 0.3125em;
			border: .5px solid #ccc8c8 !important;
			margin-bottom: 0.375em;
			padding: 0rem 1.2rem;
			background-color: #FFFFFF;
			color: #0c9845 !important;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 14px;
			text-align: left;
		}
		.select-wrapper {
		  position: relative;
		  background-color: #e9e9ed !important;
		}
		.fadeElement{
			top: 2.6rem!important;
			left: -0.1rem;
			background-color: #000;
			width: 102%;
		.fadeIn{
				top: 2.6rem!important;
				left: -0.1rem;
				background-color: #000;
				width: 102%;
			}
		}
		.dropdown-content li{
			background-color: #000;
			line-height: 0.9rem;
			:hover {
				background-color: #5b5959;
			}
			&.active{
				background-color: #5b5959;
			}
			span{
				color: #FFFFFF!important;
			  font-family: "Work Sans";
			  font-size: 16px;
			}
		}
		.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
		  color: rgba(255, 255, 255, 0.5) !important;
		  cursor: context-menu;
		  background-color: transparent !important;
		}
		.md-form {
			margin-top: 0;
			input[type="text"]:not(.browser-default){
				border-bottom: none;
				font-size: 0.8rem;
			}
		}
		.select-wrapper span.caret {
		  margin-right: 15px;
		}
		.price-currency {
			margin-left: 0.375em;
			display: inline;
			font-size: 1.375em;
			line-height: 1.09091em;
			font-weight: 600;
			font-family: "Work Sans";
		}
		.form-label{
			color: #000000 !important;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 14px;
			text-align: left;
		}
		.form-anmerkung{
			font-family: "Work Sans";
			color: gray;
			font-size: 0.8125em;
			line-height: 1.38462em;
		}
	}
	.ckeditor-padding{
		padding-top:5px;
		padding-bottom:10px;
	}
	.blog-card2 {
		padding-left: 25px;
		padding-right: 25px;
	}
	.news-card-borse {
		min-height: 356px;
		width: 100%;
		border-radius: 8px;
		margin-bottom: 20px;
		padding-top:25px;
		padding-bottom:15px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #FFFFFF;
		box-shadow: none;
	}
	.news-image-borse {
		width: auto;
		height: 141px;
	}
	.icon-newspage{
		color: rgb(0, 0, 0);
		.allnews-btn{
			transition: all 0.3s ease;
			height: 40px;
		  width: 184px;
		  border-radius: 32.09px;
		  background-color: #00A441;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1004;
		}
		.allnews-btn-b2b{
			transition: all 0.3s ease;
			height: 40px;
		  width: 184px;
		  border-radius: 32.09px;
		  background-color: #FF9500;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1004;
		}
		.allblog-btn{
			transition: all 0.5s ease;
			height: 40px;
		  width: 184px;
		  border-radius: 32.09px;
		  background-color: #00A441;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1004;
		}
		.allnews{
		  color: #FFFFFF;
		  font-family: "Work Sans";
		  font-size: 18.41px;
		  font-weight: bold;
		  letter-spacing: 0.77px;
		  line-height: 21px;
			position: absolute;
			top: 23%;
			left: 26%;
		}
		.allblog{
		  color: #FFFFFF;
		  font-family: "Work Sans";
		  font-size: 18.41px;
		  font-weight: bold;
		  letter-spacing: 0.77px;
		  line-height: 21px;
			position: absolute;
			top: 23%;
			left: 18%;
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
