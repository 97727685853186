
.bodywrapper {
	min-height: 100vh;
	background-color: #ffffff;
	background-image: url('../../media/images/emailbg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}



.logo {
	width: 112px;
	height: 101px;

}

@media (min-width: 410px) and (max-width: 575.98px) {

}


@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
		background-image: none;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 3rem;
		background-image: none;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
	.bodywrapper {
		padding-top: 5.5rem;
	}
}
