.navbar.navbar-light .navbar-nav .nav-item.active>.nav-link {
	background-color: transparent;
}


.navbar {
    padding-top: 0.05rem;
    padding-right: 0.5rem;
    padding-bottom: 0.05rem;
    padding-left: 0.5rem;
    min-height: 7rem;
    box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 0.2);
    .navbar-brand{
	    margin-right: 2rem;
	    img{
		    height: 4rem;
				width: 4rem;
	    }

	  }
    .navbar-nav {
			#toggleProfil{
				font-size: 14px;
				display: block;
				padding-bottom: 5px;
				white-space: nowrap;
			}
	    .nav-item {

				.dropdown-menu {

					top: 3px!important;
					left: -50px!important;
					border-radius: 10px;
					.dropdown-toggle {
						text-align: center;
					}
					a{
						padding-top: 0px;
						padding-bottom: 0px;
						display:block;
					}
					span{
						font-size: 12px;
					}
					div{
						#userdiv{
						padding-top: 15px;
						padding-bottom: 15px;
					}
					}
					#userprofile.dropdown-item{
						background-color: transparent;
						text-align: center;
						width: 220px;
						border-radius: 17px;
						border: 1px solid #0360ec;
						padding:0;
						white-space: nowrap;
						&:active {
							display: block;
						}
						&:hover {
							background-color: transparent;
							//border-radius: 17px;
							border-bottom:1px solid #0360ec;
							box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.3);
							height: 34px;
						}
					}
					.dropdown-item{
						font-size: 0.7rem;
						text-align: left;
						white-space: nowrap;
						height: 34px;
						&:active {
							display: block;
						}
						&:hover {
							background-color: transparent;
							//border-radius: 17px;
							border-bottom:1px solid #0360ec;
							box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
							height: 34px;
						}
					}
				}
	    	.nav-link {
				min-width: 116px;
				min-height: 107px;
				font-size: 1rem;
				color: #30445B;
				text-align: center;
				padding: 30px 10px 0;
				img {
					height: 22px;
					width: 22px;
				}
				.invitationBadge{
					display: block;
				}
				span{
					display: none;
				}

				.dropdownspan{
					display: inline;
				}
			}
			#toggleProfil.nav-link {
				min-width: 0px;
				min-height: 0px;
				padding: 0px 0px 0px;
				text-align: left;
				span{
					display: block;
					font-size: 12px;
					font-family: 'Source Sans Pro', sans-serif;
				}
			}
			#toggleProfilGast.nav-link {
				min-width: 0px;
				min-height: 0px;
				padding: 0px 0px 0px;
				text-align: center;
				span{
					display: block;
					font-size: 12px;
					font-family: 'Source Sans Pro', sans-serif;
				}
			}
			#toggleProfilUser.nav-link {
				min-width: 0px;
				min-height: 0px;
				padding: 0px 0px 0px;
				height: 28px;
				width: 150px;
				border-radius: 14px;
				color: blue;
				align-items: center !important;
				text-align: center;
				padding-top: 3px;
				span{
					display: block;
					font-size: 12px;
					font-family: 'Source Sans Pro', sans-serif;
				}
			}
			#flag.nav-link {
				min-width: 45px;
				min-height: 40px;
				&.active {
					&:after {
						content: "";
						background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;
						width: 25px;
						height: 5px;
						display: block;
						margin: 5px auto 0px;
						border-radius: 2px;
					}
				}
			}
			&:hover {
				img {
					height: 24px;
					width: 24px;
				}
				span{
					font-size: 14px;
					display: block;
					white-space: nowrap;
				}
				.dropdown-menu {
					span{
						font-size: 12px;
						color: #000;
					}
				}
			}
		}
		.nav-item.active {
			.nav-link {

				img {
					height: 24px;
					width: 24px;
				}
				span{
					font-size: 14px;
					display: block;
					white-space: nowrap;
				}
				.profilmenu{
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
			#toggleProfil.nav-link {
				min-width: 0px;
				min-height: 0px;
				padding: 0px 0px 0px;
				text-align: left;
				span{
					display: block;
					font-size: 12px;
					font-family: 'Source Sans Pro', sans-serif;
				}
			}
			#dorpdown.nav-link:after {
				content: "";
				background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;
				width: 80px;
				height: 7px;
				display: block;
				margin: 20px auto 0px;
				border-radius: 4px;
			}
		}

	}
}
.dropdown{
	.dropdown-menu{
		will-change: transform;
		top: 3px;
		left: -62px;
		transform: translate3d(0px, 107px, 0px);
	}
}

.chatheader{
	box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
}

.collapse-text-title{
	font-size: 16px;
	font-family: 'Source Sans Pro', sans-serif;
}

.collapse-text{
	font-size: 12px;
	font-family: 'Source Sans Pro', sans-serif;
}

.fixed-top {
    z-index: 1000;
}

#gastkonto.navbar {
	.btn-floating{
			img{
				height: 2rem;
				width: 2rem;
		}
	}
}

@media (max-width: 575.98px) {
	.navbar {
		min-height: 4rem;
		.collapse {
			overflow-y: scroll;
			max-height: calc(100vh - 4rem);
		}
		.navbar-brand{
	    	img{
		    	height: 3rem;
					width: 3rem;
	    	}
		}
		.navbar-nav {
				.dropdown-menu{
					top: 40px!important;
					left: 80px!important;
					border-radius: 10px;
				}
	    	.nav-item {
					 .dropdown-menu {
						 top: 5px!important;
						 left: 45px!important;
					 }
	    		.nav-link {
			    	text-align: left;
			    	min-height: 50px;
			    	padding-top: .4rem;
			    	img {
				    	margin-right: 1rem;
				    }
			    	span{
				    	display: inline;
				    }

				    &:hover {
					    .nav-link {
						    span{
								display: inline;
				    		}
					    }
					}
				}
			}
			.nav-item.active {
				.nav-link {
					span{
						display: inline;
						font-size: 16px;
				    }
				}
				.nav-link:after {
					display: none;
				}
			}
		}
	}

	#gastkonto.navbar {
			.btn-floating{
				position: absolute;
				top: 4.5px;
				left: 54px;
				width: 36px;
				height: 36px;
					img{
						height: 1.4rem;
						width: 1.4rem;
						margin-top: -0.1rem;
						margin-right: -0.1rem;
	    	}
			}
		}
}



@media (min-width: 576px) and (max-width: 767.98px) {
	.navbar {
		min-height: 4rem;
		.collapse {
			overflow-y: scroll;
			max-height: calc(100vh - 4rem);
		}
		.navbar-brand{
	    	img{
		    	height: 3rem;
					width: 3rem;
	    	}
		}
		.navbar-nav {
				.dropdown-menu{
					top: 40px!important;
					left: 80px!important;
					border-radius: 10px;
				}
	    	.nav-item {
					 .dropdown-menu {
						 top: 5px!important;
						 left: 45px!important;
						 .popover-enter-done{
							 overflow-y: scroll;
							 max-height: calc(100vh - 6rem);
						 }
					 }
	    		.nav-link {
			    	text-align: left;
			    	min-height: 50px;
			    	padding-top: .4rem;
			    	img {
				    	margin-right: 1rem;
				    }
			    	span{
				    	display: inline;
				    }

				    &:hover {
					    .nav-link {
						    span{
								display: inline;
				    		}
					    }
					}
				}
			}
			.nav-item.active {
				.nav-link {
					span{
						display: inline;
						font-size: 16px;
				    }
				}
				.nav-link:after {
					display: none;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.navbar {
		min-height: 5rem;
		.navbar-brand{
	    	img{
		    	height: 3rem;
				width: 3rem;
	    	}
		}
		.navbar-nav {
	    	.nav-item {
	    		.nav-link {
		    		min-width: 50px;
		    		font-size: 0.5rem;
		    	}
			}
			.form-inline {
				.input-group{
					max-width: 100px;
				}

			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.navbar {
		min-height: 5rem;
		.navbar-brand{
	    	img{
		    	height: 3rem;
				width: 3rem;
	    	}
		}
		.navbar-nav {
	    	.nav-item {
	    		.nav-link {
		    		min-width: 50px;
		    		font-size: 0.5rem;
		    	}
			}
		}
	}
}

@media (min-width: 1200px) and (max-width: 1699.98px) {
	.navbar {
		min-height: 5rem;
		.navbar-brand{
	    	img{
		    	height: 3rem;
				width: 3rem;
	    	}
		}
		.navbar-nav {
	    	.nav-item {
	    		.nav-link {
		    		min-width: 50px;
		    		font-size: 0.8rem;
		    	}
			}
		}
	}
}
