.image-manager-img {
	border-radius: 15px !important;
	height: 150px;
	padding: .5rem!important;
}

.image-manager-img-trash-btn {
	height: 24px;
	width: 24px;
	top: -47px;
	right: 47px;
	margin: 0 -24px 0 0;
	img {
		width: 13px;
		height: 16px;
	}
}

div.info-tooltip {
	display: inline-block;

	div.show.tooltip.px-2{
		padding: 10px;
		top: -23px!important;
		left: 30px!important;
		min-width: 215px;
		transform: translate3d(0px, 0px, 0px)!important;
	}
	.tooltip[x-placement^="right"] .popover_arrow {
	  border-width: 8px 8px 8px 0;
	  border-color: transparent #131313 transparent transparent;
	  left: -9px;
		top: 8px!important;
	  margin-left: 0;
	}
	.tooltip-text {
		height: 20px;
		margin: 0;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 17px;
	}
	.tooltip-title {
		font-weight: bold!important;
		height: 24px;
		margin: 0;
		font-family: "Work Sans";
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		display: inline-flex;
	}
	.info-tooltip-btn {
		font-size: 1.1rem;
		position: absolute;
		top: -26px;
		left: 5px;
	}
}



@media (max-width: 575.98px) {
	.image-manager-img {
		height: 93px;
	}
	.image-manager-img-trash-btn {
		top: -24px;
		right: 40px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.image-manager-img {
		height: 100px;
	}

	.image-manager-img-trash-btn {
		top: -27px;
		right: 40px;
	}

}
