
.bodywrapper {
	min-height: 100vh;
	padding-top: 1rem;
	background-color: #f2efef;
	background-image: url('../../media/images/companypublicbg.png');
	background-size: 44% auto;
	background-repeat: no-repeat;
	background-position: top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



.logo {
	width: 112px;
	height: 101px;

}

@media (min-width: 410px) and (max-width: 575.98px) {

}


@media (max-width: 575.98px) {
	.bodywrapper {
		background-image: none;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		
		background-size: 50% auto;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
	
}
