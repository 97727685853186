.bodywrapper {
	min-height: 100vh;
	padding-top: 6.5rem;
	background-color: #edeaea;
}
.bodywrapper2 {
	min-height: 88vh;
	padding-top: 6.5rem;
	background-color: #edeaea;
}

.bodywrapper3 {
	min-height: 100vh;
	padding-top: 6.5rem;
	background-color: #edeaea;
}

.page-title {
	font-size: 1.5rem;
	text-align: center;
	margin-top: 2rem;
}

.watermark{
	width:170px;
	height:135px;
}


@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
	.bodywrapper3 {
		padding-top: 8rem;
	}
	.bodywrapper2 {
		padding-top: 8.5rem;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 8rem;
	}
	.bodywrapper3 {
		padding-top: 8rem;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 8rem;
	}
	.bodywrapper3 {
		padding-top: 8rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 8rem;
	}
	.bodywrapper3 {
		padding-top: 8rem;
	}
}
