.product-search-b2b {
	padding-left: 17px;
	padding-right: 0;
	max-width: 600px;
	width: 100%;
	.form-control{
		height: 57px;
		border-radius: 15px;
		background-color: #FFFFFF;
		border-bottom: 0;
		padding-left: 15px;
		padding-top:7px;
		margin-top: 1.5rem;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
}
.product-search-b2b-productpage {
	padding-left: 0px;
	padding-right: 0;
	max-width: 274px;
	margin-top: 4.5px;
	padding-bottom: 15px;
	width: 100%;
	.form-control{
		height: 40px;
		border-radius: 11px;
		background-color: #FFFFFF;
		padding-left: 15px;
		padding-top:7px;
		margin-top: 1.5rem;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
}
.product-search-b2b-anzeigepage {
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	margin-top: 4.5px;
	padding-bottom: 15px;
	width: 100%;
	.form-control{
		height: 40px;
		border-radius: 11px;
		background-color: #FFFFFF;
		padding-left: 15px;
		padding-top:7px;
		margin-top: 1.5rem;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
}
.borse-btn-b2b {
	height: 56px;
	width: 56px;
	border-radius: 15px;
	background-color: #FF9500!important;
	margin: .375rem;
  margin-top: 1.5rem;
	padding: .84rem 1.14rem;
	.vector-1 {
	  height: 25px;
	  width: 25px;
	}
}
.borse-btn-b2b-small {
  height: 42px;
  width: 42px;
  border-radius: 10px;
  background-color: #FF9500 !important;
  margin: 0;
  padding: 0;
  margin-top:1.5rem;
  .vector-1 {
		height: 19px;
		width: 19px;
		margin-right: -2px;
	}
}
