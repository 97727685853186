.fixed-top-landingb2b {
	position: fixed;
	top: 0;
	z-index: 1005;
}
.landingb2b-top-padding {
	padding-top: 150px;
}
.landingb2b-middle-padding {
	padding-top: 200px;
}
.landingb2b-bottom-padding {
	padding-top: 125px;
}
.landingb2b-advertwrapper {
  position: absolute;
  top: 7.2rem;
  left: 0rem;
}
#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: transparent!important;
	background-image: url('../../media/images/b2b/Group 14-4.png')!important;
	background-size: 100% 100%!important;
	height: 30px!important;
	width: 30px!important;
	margin-top: 0px!important;
	margin-left: -7px!important;
}

.fp-viewing-Markt #fp-nav ul li:hover a.active span,
.fp-viewing-Markt #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/b2b/Group 14.png')!important;
	background-size: 100% 100%!important;
	height: 30px!important;
	width: 30px!important;
	margin-top: 0px!important;
	margin-left: -7px!important;
}
.fp-viewing-Borse #fp-nav ul li:hover a.active span,
.fp-viewing-Borse #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/markt/lightningGreen.png')!important;
	background-size: 100% 100%!important;
	height: 40px!important;
	width: 40px!important;
	margin: 10px 0 0 -2px!important;
	margin-top: 5px !important;
}
.fp-viewing-News #fp-nav ul li:hover a.active span,
.fp-viewing-News #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/markt/lightningGreen.png')!important;
	background-size: 100% 100%!important;
	height: 40px!important;
	width: 40px!important;
	margin: 10px 0 0 -2px!important;
	margin-top: 5px !important;
}
.fp-viewing-Info #fp-nav ul li:hover a.active span,
.fp-viewing-Info #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/markt/lightningGreen.png')!important;
	background-size: 100% 100%!important;
	height: 40px!important;
	width: 40px!important;
	margin: 10px 0 0 -2px!important;
	margin-top: 5px !important;
}
.fp-viewing-Contact #fp-nav ul li:hover a.active span,
.fp-viewing-Contact #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/markt/lightningGreen.png')!important;
	background-size: 100% 100%!important;
	height: 40px!important;
	width: 40px!important;
	margin: 10px 0 0 -2px!important;
	margin-top: 5px !important;
}
.fp-viewing-Contacts #fp-nav ul li:hover a.active span,
.fp-viewing-Contacts #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-color: transparent!important;
	background-image: url('../../media/images/b2b/Group 14-4.png')!important;
	background-size: 100% 100%!important;
	height: 30px!important;
	width: 30px!important;
	margin-top: 0px!important;
	margin-left: -7px!important;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    height: 20px!important;
    width: 20px!important;
		background-image: url('../../media/images/b2b/Group 14-2.png')!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
    margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}

.fp-viewing-Markt #fp-nav ul li a span, .fp-slidesNav ul li a span {
		height: 20px!important;
		width: 20px!important;
		background-image: url('../../media/images/b2b/Group 14-2.png')!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}
.fp-viewing-Borse #fp-nav ul li a span, .fp-slidesNav ul li a span {
		background-image: url('../../media/images/markt/lightningGrey.png')!important;
		background-size: 100% 100%!important;
		height: 40px!important;
		width: 40px!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}
.fp-viewing-News #fp-nav ul li a span, .fp-slidesNav ul li a span {
		background-image: url('../../media/images/markt/lightningGrey.png')!important;
		background-size: 100% 100%!important;
		height: 40px!important;
		width: 40px!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}
.fp-viewing-Info #fp-nav ul li a span, .fp-slidesNav ul li a span {
		background-image: url('../../media/images/markt/lightningGrey.png')!important;
		background-size: 100% 100%!important;
		height: 40px!important;
		width: 40px!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}
.fp-viewing-Contact #fp-nav ul li a span, .fp-slidesNav ul li a span {
		background-image: url('../../media/images/markt/lightningGrey.png')!important;
		background-size: 100% 100%!important;
		height: 40px!important;
		width: 40px!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}
.fp-viewing-Contacts #fp-nav ul li a span, .fp-slidesNav ul li a span {
		height: 20px!important;
		width: 20px!important;
		background-image: url('../../media/images/b2b/Group 14-1.png')!important;
		background-size: 100% 100%!important;
		background-color: transparent!important;
		margin: 10px 0 0 -2px!important;
		margin-top: 5px !important;
}

/*#fp-nav ul li .fp-tooltip {
    top: 9px!important;
    color: #12D070!important;
    font-size: 18px!important;
		font-weight: bold!important;
}*/

.fp-viewing-Markt #fp-nav ul li .fp-tooltip {
    color: #00A441!important;
}
.fp-viewing-Contact #fp-nav ul li .fp-tooltip {
    color: #808080!important;
}

/*#fp-nav ul li .fp-tooltip.fp-right {
    right: 45px!important;
}*/

#fp-nav ul li, .fp-slidesNav ul li {
    height: 35px!important;
}

#fp-nav.fp-right {
    right: 80px!important;
}


#fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 0.6!important;
}

#fp-nav ul li:hover .fp-tooltip {
	opacity: 1!important;
}


.landingb2b-nav-container {
	transition: padding-top 0.6s, background-color 0.6s;
	padding-right: 300px;
	padding-top: 55px;
	.signin-btn-landingb2b {
		width: 116px;
		height: 32px;
		border-radius: 10px;
		padding-top: 4px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #FF9500!important;
		text-transform: none;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 22px;
	}
	.landingb2b-nav {
		justify-content: flex-end !important;
		.nav-item {
			.nav-link {
				min-width: 116px;
				color: #30445B;
				text-align: center;
				//padding: 30px 10px 0;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				&.active {
					&:after {
						content: "";
						height: 3px;
					  width: 105.5px;
					  background-color: #FF9500;
						display: block;
						margin: 5px auto 0px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}
.landingb2b-nav-container-middle {
	transition: padding-top 0.6s, background-color 0.6s;
	padding-right: 300px;
	padding-top: 55px;
	.signin-btn-landingb2b {
		width: 116px;
		height: 32px;
		border-radius: 10px;
		padding-top: 4px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #00A441!important;
		text-transform: none;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 22px;
	}
	.landingb2b-nav {
		justify-content: flex-end !important;
		.nav-item {
			.nav-link {
				min-width: 116px;
				color: #30445B;
				text-align: center;
				//padding: 30px 10px 0;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				&.active {
					&:after {
						content: "";
						height: 3px;
					  width: 105.5px;
					  background-color: #00A441;
						display: block;
						margin: 5px auto 0px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

.landingb2b-nav-container-bottom {
	transition: color 0.7s, background-color 0.7s;
	padding-right: 300px;
	padding-top: 55px;
	.signin-btn-landingb2b {
		width: 116px;
		height: 32px;
		border-radius: 10px;
		padding-top: 4px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #FF9500!important;
		text-transform: none;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 22px;
		color: #FFFFFF;
	}
	.landingb2b-nav {
		justify-content: flex-end !important;
		.nav-item {
			.nav-link {
				min-width: 116px;
				color: #808080;
				text-align: center;
				//padding: 30px 10px 0;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				&.active {
					&:after {
						content: "";
						height: 3px;
					  width: 105.5px;
					  background-color: #FF9500;
						display: block;
						margin: 5px auto 0px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

/*.landingb2b-nav-container {
	padding-top: 55px;
}

.landingb2b-nav-container-scroll {
	padding-top: 0px;
	background-color: #f2efef;
}*/

.landingb2b-top {
	min-height: 100vh;
	background-image: url('../../media/images/b2b/Group 1.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.landingb2b-midle {
	min-height: 100vh;
	background-image: url('../../media/images/b2b/Group 2.png');
	background-color: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(242,239,239,0.96) 84.6%, #F2EFEF 100%);
	background-size: 110% 100%;
	background-repeat: no-repeat;
}

.landingb2b-bottom {
	min-height: 100vh;
	//background-image: url('../../media/images/historypublicbg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.logo-landingb2b{
	width: 112px;
	height: 101px;
}

.signin-btn-dealer {
	width: 150px;
	height: 32px;
	border-radius: 16px;
	padding-top: 4px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #FF9500!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.signin-btn-supplier {
	width: 150px;
	height: 32px;
	border-radius: 16px;
	padding-top: 4px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #292929!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.signin-btn-borse {
	width: 150px;
	height: 32px;
	border-radius: 16px;
	padding-top: 4px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #00A441!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.signup-btn-landingb2b {
	width: 120px;
	height: 36px;
	border-radius: 18px;
	padding-top: 6px;
	padding-left: 0px;
	padding-right: 0px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	margin-left: 0px;
}


.landingb2b-partner-logo {
	max-width: 60%;
	height: auto;
}

.landingb2b-form {
	.md-form {
		margin-top: 9px;
		margin-bottom: 0px;
		padding-right: 10px;
		input[type=text].landingb2b-input, input[type=password].landingb2b-input, input[type=email].landingb2b-input {
			width: 260px;
			height: 40px;
			background-color: #FFFFFF;
			border-radius: 16px;
			border-bottom: 1px solid #ced4da;
			border-top: 1px solid #ced4da;
			border-left: 1px solid #ced4da;
			border-right: 1px solid #ced4da;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			&:focus{
				border-color: #30445b !important;
				border: 2px solid #30445b !important;
				border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #30445B;
				}
			}

		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			top: 2px;
			&.active {
				transform: translateY(5px) scale(1);
				top: 0px;
			}
		}
	 }
}
.contact-btn-landingb2b {
	width: 150px;
	height: 40px;
	border-radius: 16px;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #FF9500!important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.group_5 {
	height: auto;
  width: 60%;
}

.group_5_small {
	height: auto;
  width: 100%;
}

.row-landing-markt {
  max-width: 440px;
  padding-top: 15px;
}

.firmen {
	height: auto;
  width: 35%;
}

.group_3 {
  height: auto;
  width: 100%;
}

.group_6 {
  height: auto;
  width: 19vw;
}

.group_7 {
	height: 5px;
  width: 21vw;
	position: absolute;
	right: -20.5vw;
	bottom: 18px;
}

.group_8 {
	height: 4px;
  width: 21vw;
	position: absolute;
	right: -20.5vw;
	bottom: 18px;
}

.profile {
	height: 99px;
	width: 99px;
	border-radius: 50%;
}

.rectangle-1 {
  height: 71px;
  width: 8px;
}

.rectangle-4 {
  min-height: 100vh;
  width: 100%;
  background-color: #292929;
}

.rectangle-5 {
  min-height: 20vh;
  width: 100%;
  background-color: #1D1D1D;
}

.rectangle-15{
  min-height: 13%;
  width: 100%;
  background-color: #1D1D1D;
  position: absolute;
  bottom: 0;
  z-index: 1004;
}
.rectangle-16{
  min-height: 87%;
  width: 40%;
	border-radius: 0 69px 69px 0;
  background-color: #FF9500;
  position: absolute;
  left: 0;
	top: 0;
  z-index: 800;
}
.rectangle-17{
  min-height: 87%;
  width: 40%;
	border-radius: 69px 0 0 69px;
  background-color: #00A441;
  position: absolute;
  right: 0;
	top: 0;
  z-index: 800;
}

.rectangle-18{
	height: 97px;
  width: 311.38px;
}


.find-your-best-parts {
  height: 64px;
  width: 376px;
  color: #808080;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.join-to-the-interb-2-b {
	color: #292929;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
}

.footertext {
  height: 11px;
  //width: 180px;
  color: #C1C1C1;
  font-family: "Work Sans";
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
	display:inline-block;
}

.logotext-footer {
  height: 55px;
  width: 177.22px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 15.28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.padding-top-footer {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.balazs-boda-interb-2-b {
  width: 251px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24.78px;
	display:inline-flex;
}

.liebe-interessenten {
  max-height: 112px;
  max-width: 650px;
  color: #808080;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.liebe-interessenten-title {
  width: 650px;
  color: #808080;
  font-family: "Work Sans";
	font-size: 23px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px;
}


@media (max-width: 409.98px) {
	.landingb2b-nav-container, .landingb2b-nav-container-scroll {
		padding-right: 15px;
		.landingb2b-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.5rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingb2b-nav-container {
		padding-top: 20px;
		.landingb2b-nav {
			justify-content: end !important;
		}
	}
	.footertext {
	  color: #FFFFFF;
	}

}

@media (min-width: 410px) and (max-width: 575.98px) {
	.landingb2b-nav-container, .landingb2b-nav-container-scroll {
		padding-right: 20px;
		.landingb2b-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingb2b-nav-container {
		padding-top: 20px;
		.landingb2b-nav {
			justify-content: end !important;
		}
	}
	.footertext {
	  color: #FFFFFF;
	}
}

@media (max-width: 575.98px) {

	.landingb2b-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landingb2b-nav {
			justify-content: flex-end !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingb2b-nav-container-scroll {
		padding-right: 20px;
		.landingb2b-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.footertext {
	  color: #FFFFFF;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.landingb2b-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landingb2b-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landingb2b-nav-container-scroll {
		padding-right: 20px;

		.landingb2b-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.footertext {
	  color: #FFFFFF;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.landingb2b-nav-container, .landingb2b-nav-container-scroll {
		padding-right: 150px;
	}
	.landingb2b-nav-container {
		padding-top: 5px;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.landingb2b-nav-container, .landingb2b-nav-container-scroll {
		padding-right: 180px;
	}
	.landingb2b-nav-container {
		padding-top: 12px;
	}
	.footertext {
	  width: 160px;
	}
	.padding-top-footer {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}
	.rectangle-15{
	  min-height: 10%;
	}
	.rectangle-16{
	  min-height: 90%;
	}
	.rectangle-17{
	  min-height: 90%;
	}
	.logotext-footer {
	  font-size: 12px;
	}
	.footertext {
	  color: #FFFFFF;
	}

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {
	.landingb2b-nav-container, .landingb2b-nav-container-scroll {
		padding-right: 230px;
	}
	.landingb2b-nav-container {
		padding-top: 30px;
	}

}
