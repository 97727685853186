.vuesax-linear-arrow-circle-lef {
  height: 46px;
  width: 46px;
}

.group_11 {
  height: 80px;
  width: auto;
}

.group_28 {
  height: 65px;
  width: auto;
}

.group_29 {
  height: 45px;
  width: auto;
}

.group_30 {
  height: 65px;
  width: auto;
}

.group_31 {
  height: 45px;
  width: auto;
}

.cant-sign-in {
  height: 14px;
  width: 210px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
	display: inline-block;
}

.cant-sign-in2 {
  height: 14px;
  width: 100px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
	display: inline-block;
}

.vuesax-linear-arrow-circle-rig {
	height: 12px;
	width: 13px;
	padding-left: 4px;
	margin-top: -3px;
}

.sign-up-to-inter-b-2-bi {
  height: 48px;
  width: 282px;
  color: #292929;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
	display: inline-block;
}

.sign-up-to-markt {
  height: 48px;
  width: 290px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.already-have-an-acco {
  height: 30px;
  width: 234px;
  color: #292929;
  font-family: "Work Sans";
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
  display: inline-block;
}

.signin-row-padding {
  padding-bottom: 135px;
}




.signinb2b-form {
	.md-form {
		margin-top: 8px;
		margin-bottom: 15px;
		input[type=text].signinb2b-input, input[type=password].signinb2b-input, input[type=email].signinb2b-input {
			width: 270px;
			height: 48px;
			background-color: transparent;
			border-radius: 10px;
			border-color: #30445b !important;
			border: 2px solid #30445b !important;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			.form-control::placeholder {
				color: #FFFFFF;
				opacity: 1;
			}
			&:focus{
				border-color: #FFFFFF !important;
				border: 2px solid #FFFFFF !important;
				//border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #FFFFFF;
				}
			}
}





















		#userprofil.signin-input {
			background-color: #F2F2F2;
		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			&.active {
				transform: translateY(5px) scale(1);
			}
		}
		.validate {
			margin-bottom: 0;
		}

	}

	.signin-remember {
		margin-top: 12px;
		.form-check-input[type="checkbox"]+label {
			padding-left: 24px;
			&:before {
				background-image: url('../../media/newicons/checkBox.png');
				background-size: cover;
				border:0;
			}
		}

		.form-check-input[type="checkbox"]:checked+label:before {
			background-image: url('../../media/newicons/checkBoxChecked.png');
			background-size: cover;
			top: 0;
			left: 0;
			width: 18px;
			height: 18px;
			transform: none;
		}
	}
}




.signin-link {
	color: #959595;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 13px;
	text-transform: uppercase;
	padding: 10px 0 0 20px;
}


.error-signin-b2b {
	color: rgb(220, 53, 69);
}

.error-signin-markt {
	color: black;
}


@media (max-width: 575.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
