.product-search-markt {
	padding-left: 17px;
	padding-right: 0;
	max-width: 600px;
	width: 100%;
	.form-control{
		height: 57px;
		width: 100%;
		border-radius: 15px;
		background-color: #FFFFFF;
		padding-left: 15px;
		padding-top:7px;
		margin-top: 1.5rem;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
  .borse-btn {
    height: 56px;
    width: 56px;
    border-radius: 15px;
    background-color: #00A441!important;
    margin: .375rem;
    padding: .84rem 1.14rem;
    margin-top: 1.5rem;
    .vector-1 {
      height: 25px;
      width: 25px;
    }
  }
}
.product-search-markt-header {
	padding-left: 0px;
	padding-right: 0;
	margin-top: 4.5px;
	width: 100%;
	.form-control{
		height: 40px;
		border-radius: 11px;
		background-color: #FFFFFF;
		padding-left: 15px;
		padding-top:7px;
		color: #919191;
		font-family: "Work Sans";
		font-size: 16px;
	}
  .borse-btn-header {
    height: 40px;
    width: 40px;
    border-radius: 11px;
    background-color: #00A441!important;
    margin: .375rem;
    padding: .54rem .75rem;
    .vector-1 {
      height: 20px;
      width: 20px;
    }
  }
}
