.user-container {
	padding-top: 6%;
}
.user-photo-navbar{
	height: 38px;
	width: 38px;
	border-radius: 50%;
	border: 1px solid #7F8A83;
	box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
}
.user-form {
	.md-form {
		margin-top: 8px;
		margin-bottom: 15px;
		input[type=text].user-input, input[type=password].user-input, input[type=email].user-input {
			width: 280px;
			height: 48px;
			background-color: #F2F2F2;
			border-radius: 10px;
			border: 0;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			&:focus{
				border-color: #30445b !important;
				border: 2px solid #30445b !important;
				border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #30445B;
				}
			}

		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			&.active {
				transform: translateY(5px) scale(1);
			}
		}
		.validate {
			margin-bottom: 0;
		}

	}

	.user-remember {
		margin-top: 12px;
		.form-check-input[type="checkbox"]+label {
			padding-left: 24px;
			&:before {
				background-image: url('../../media/newicons/checkBox.png');
				background-size: cover;
				border:0;
			}
		}

		.form-check-input[type="checkbox"]:checked+label:before {
			background-image: url('../../media/newicons/checkBoxChecked.png');
			background-size: cover;
			top: 0;
			left: 0;
			width: 18px;
			height: 18px;
			transform: none;
		}
	}
}

.user-btn {
	width: 168px;
	height: 46px;
	border-radius: 23px;
	padding: 6px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	img {
		width: 34px;
		height: 34px;
	}
}


.user-link {
	color: #959595;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 13px;
	text-transform: uppercase;
	padding: 10px 0 0 20px;
}


@media (max-width: 575.98px) {
	.user-container {
		background-color: rgba(119,113,113, 0.8);
		height: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
