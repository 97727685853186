.bodywrapper {
	min-height: 100vh;
	padding-top: 0;
	background-color: #f2efef;
}

.page-title {
	font-size: 1.5rem;
	text-align: center;
	margin-top: 2rem;
}

.watermark{
	width:170px;
	height:135px;
}


@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 0rem;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 0rem;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 0rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 0rem;
	}
}
