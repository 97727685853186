.bike-price {
	height: 26px;
  width: 84px;
  border-radius: 8px;
  background-color: rgba(0,0,0,0.58);
	color: #FFFFFF;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.28px;
	line-height: 26.37px;
	text-align: center;
	display: block;
	position: absolute;
	bottom: 10px;
	right: 10px;
}
#eintrage {
	height: 312px!important;
}
.bikeanzeige-card {
	background-image: url('../../media/images/markt/Group 26.png');
	background-color: #FFFFFF!important;
	background-size: 35% auto;
	background-position: right bottom;
	background-repeat: no-repeat;
	height: 285px;
  width: 100%;
  border-radius: 15px;
	margin-bottom: 1rem;
	.blur{
		opacity: 0.3;
	}
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		text-align: center;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-top: .2rem;
		padding-left: 1rem;
		padding-right: 1rem;

		.bookmark-hover {
			width: 100%;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				max-height: 135px;
				width: auto;
				max-width: 100%;
				border-radius: 15px;
			}
			.bike-image-keinbild {
				height: 135px;
				width: 83%;
				border-radius: 15px;
			}
			.bike-image2 {
				max-height: 135px;
				width: auto;
				max-width: 100%;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
		    opacity: 0.5;
		    z-index: 1;
			}

		}
		.bike-title {
			height: 35px;
			//width: 172px;
			color: #00A441;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.32px;
			line-height: 21px;
		}
		.username {
			height: 20px;
			//width: 150px;
			color: #30445B;
			margin: 0;
			font-family: "Work Sans";
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 17px;
		}
		.datum-text {
			height: 19px;
			width: 150px;
			color: #30445B;
			font-family: "Work Sans";
			font-size: 10px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 12px;
		}
		.user-datum {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 10px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 12px;
		}
		.userProfileName-icon-anzeige{
			height: 46px;
			width: 46px;
			border-radius: 50%;
			border: 1px solid #7F8A83;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			color: #fff;
			background-color: #00A441;
			font-family: "Work Sans";
			font-size: 14px;
		}
		.user-foto {
			box-sizing: border-box;
			height: 44.34px;
			width: 44.34px;
			border: 1px solid rgba(48,68,91,0.25);
			border-radius: 50%;
			background-color: #FFFFFF;
		}
		.list-group {
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				color: #000;
			}
		}
		.favorite-true {
			position: absolute;
			bottom: -10px;
			right: 10px;
			z-index: 1;
			padding: 0;
			margin: 0;
		}
		.edit-btn{
			height: 39.53px;
		  width: 39.53px;
		  opacity: 0.9;
		  background-color: #00A441!important;
			border-radius: 50%;
			position: absolute;
			bottom: 5px;
			right: 5px;
			z-index: 1;
			padding: 0;
			padding-top: 7px;
			margin: 0;
			.pen{
				height: 13.98px;
  			width: 12px;
			}
		}
		.favorite-icon-anzeige {
			width: 25px;
			height: auto;
		}
		.trashicon-icon{
			width: 22px;
			height: auto;
		}
	}
	.anzeige-status{
		position: absolute;
		top:-3.3px;
		left: -4px;
		z-index: 1;
	}
	.anzeige-status-boerse{
		position: absolute;
		top: 7px;
		left: 8px;
		z-index: 1;
	}
	.status{
		height: auto;
		width: 174.7px;
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}
.profilpage-menu {
	height: 29px;
  //width: 238.5px;
  color: #919191;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}
.productanzeige-card {
	background-image: url('../../media/images/b2b/Group 26.png');
	background-color: #FFFFFF!important;
	background-size: 55% auto;
	background-position: right bottom;
	background-repeat: no-repeat;
	min-height: 280px;
  width: 100%;
  border-radius: 15px;
	margin-bottom: 1rem;
	.card-body {
		padding:0;
		padding-top: .2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		.bookmark-img{
			position: absolute;
			top: 10px;
			right: 12px;
			width: 15px;
			height: auto;
			z-index: 1;
		}
		.bike-image {
			height: 100px;
			width: auto;
			background-size: cover;
			border-radius: 15px;
		}
		.background-hover {
			background-color: 'blue';
	    opacity: 0.5;
	    z-index: 1;
		}
		.bike-title {
			width: 100%;
			color: #30445B;
			font-family: "Work Sans";
			font-size: 10px;
			letter-spacing: 0.32px;
			line-height: 21px;
			text-align: left;
		}
		.username {
			height: 30px;
			//width: 150px;
			color: #30445B;
			margin: 0;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 17px;
		}
		.datum-text {
			height: 19px;
			width: 150px;
			color: #30445B;
			font-family: "Work Sans";
			font-size: 10px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 12px;
		}
		.userProfileName-icon-anzeige{
			height: 46px;
			width: 46px;
			border-radius: 50%;
			border: 1px solid #7F8A83;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			color: #fff;
			background-color: #00A441;
			font-family: "Work Sans";
			font-size: 14px;
		}
		.user-photo{
			height: 46px;
			width: 46px;
			border-radius: 50%;
			border: 1px solid #7F8A83;
		}
		.list-group {
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				color: #000;
			}
		}
		.favorite-true {
			position: absolute;
			bottom: 5px;
			right: 20px;
			z-index: 1;
			padding: 0;
			margin: 0;
		}
		.edit-btn{
			height: 39.53px;
		  width: 39.53px;
		  opacity: 0.9;
		  background-color: #00A441!important;
			border-radius: 50%;
			position: absolute;
			bottom: 22px;
			right: 14px;
			z-index: 1;
			padding: 0;
			padding-top: 7px;
			margin: 0;
			.pen{
				height: 13.98px;
  			width: 12px;
			}
		}
		.favorite-icon-anzeige {
			width: 25px;
			height: auto;
		}
		.trashicon-icon{
			width: 22px;
			height: auto;
		}
	}
	.anzeige-status{
		position: absolute;
		top:-3.3px;
		left: -4px;
		z-index: 1;
	}
	.anzeige-status-boerse{
		position: absolute;
		top: 7px;
		left: 8px;
		z-index: 1;
	}
	.status{
		height: auto;
		width: 174.7px;
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.filter-card-anzeige {
	//min-height: 590px;
	max-height: 81vh;
	width: 100%;
	border-radius: 20px;
	background-color: #FFFFFF;
}


.sticky-card-anzeige {
	position: sticky;
	top: 85px;
	margin-bottom: 15px;
}
.sticky-card-borse {
	position: sticky;
	top: 85px;
	margin-bottom: 15px;
	z-index: 1000;
}
.favorite-icon-anzeige-fix {
	width: 25px;
	height: auto;
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 1;
}

.rectangle-footer-bikeboerse {
  min-height: 12vh;
  width: 100%;
  background-color: #1D1D1D;
}

.anzeigepage-filter {
	border-bottom: 1px solid #c4c4c4;
	transition: background-color 0.6s;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
	.landingmarkt-nav {
		align-items: left;
		.nav-item {
			height: 23px;
			.nav-link {
				color: #0c9845;
				text-align: left;
				padding-top: 0;
				padding-right: 2rem;
				padding-bottom: 0;
				padding-left: 2rem;
				span{
					color: #515151;
					font-family: "Work Sans";
					font-size: 13px;
					font-weight: 500;
					letter-spacing: 0.28px;
					line-height: 26px;
				}
				&:hover {
					span{
						color: #065125;
					}
				}
				&.active {
					span{
						color: #00A441;
					  font-weight: bold;
					}
					&:after {
						display: block;
						position: absolute;
						left: 0px;
						top: 9px;
						height: 8.45px;
						width: 8.45px;
						border-radius: 50%;
						background-color: #00A441;
						content: ""
					}
				}
			}
		}
	}
}
.css-1x97c6v {
	width: 65%!important;
}
.slider-filter-umkreis{
	margin-top: 30px;
	margin-bottom: 0px;

	.MuiTypography-root {
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: bold;
	  letter-spacing: 0.28px;
	  line-height: 20px;
		position: absolute;
		top: -19px;
		left: -16px;
	}
	.MuiSlider-markLabel {
		height: 25px;
		width: 46px;
		border-radius: 5px;
		background-color: none;
		padding-top: 5px;
		color: #515151;
		font-family: "Work Sans";
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 14px;
		text-align: center;
	  position: absolute;
	  top: 30px;
	}
	.MuiSlider-thumb {
		color: #00A441;
		width: 12px;
		height: 12px;
	  &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
			color: #00A441;
			box-Shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0),0 0 0 0 rgba(0,0,0,0)
		}
	}
	.MuiSlider-root {
		border-radius: 1px;
		height: 2px;
		color: #515151;
	}
	.MuiSlider-track {
		border: none;
		background-color: #515151;
	}
	.min-euro {
		position: absolute;
		bottom: -20px;
		left: -18px;
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 14px;
		text-align: center;
	}
	.max-euro {
		position: absolute;
		bottom: -20px;
		right: -20px;
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 14px;
		text-align: center;
	}
}


.slider-filter{

	margin-top: 0px;
	padding-top: 25px;
	margin-bottom: 30px;
	.MuiTypography-root {
		color: #515151;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0.28px;
		line-height: 20px;
		position: absolute;
		top: 5px;
		left: -31px;
	}
	.MuiSlider-markLabel {
		height: 22px;
		width: 44px;
		border-radius: 5px;
		background-color: #F1EFEF;
		padding-top: 3px;
		color: #515151;
		font-family: "Work Sans";
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0.5px;
		//line-height: 14px;
		text-align: center;
		position: absolute;
		top: 29px;
	}
	.MuiSlider-thumb {
		color: #00A441;
		width: 14px;
		height: 14px;
	  &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
			color: #00A441;
			box-Shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0),0 0 0 0 rgba(0,0,0,0)
		}
	}
	.MuiSlider-root {
		border-radius: 1px;
		height: 2px;
		color: #515151;
	}
	.css-1aznpnh-MuiSlider-root {
	  margin-bottom: 14px;
	}
	.MuiSlider-track {
		border: none;
		background-color: #515151;
	}
	.min-euro {
		position: absolute;
		bottom: -20px;
		left: -18px;
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 14px;
		text-align: center;
	}
	.max-euro {
		position: absolute;
		bottom: -20px;
		right: -20px;
		color: #9E9E9E;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 14px;
		text-align: center;
	}
}

.rahmengroese-filter {
	transition: background-color 0.6s;
	padding-top: 2px;
	padding-bottom: 0px;
	width: 100%;
	text-align: center!important;
	.rahmengroese-nav {
		align-items: center;
		display: inline-flex;
		.nav-item {
			height: 26px;
			width: 46px;
			.nav-link {
				padding: 0;
				width: 38px;
				span{
					padding-top: 3px;
					height: 22px;
					width: 38px;
					border-radius: 5px;
					background-color: #F1EFEF;
				  color: #515151;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: 500;
				  letter-spacing: 0.58px;
				  line-height: 16px;
				  text-align: center;
					display: block;
				}
				&:hover {
					span{
						color: #065125;
					}
				}
				&.active {

					span{
						background-color: #00A441;
						color: #FFFFFF;
					  font-weight: bold;
					}
				}
			}
		}
	}
}

.bike-status-filter {
	transition: background-color 0.6s;
	padding-top: 5px;
	padding-bottom: 10px;
	width: 100%;
	.bike-status-nav {
		align-items: center;
		.nav-item {
			width: 120px;
			margin-left: 15px;
			margin-right: 15px;
			height: 35px;
			.nav-link {
				padding: 0;
				width: 120px;
				span{
					padding-top: 3px;
					height: 22px;
					width: 120px;
					border-radius: 5px;
					background-color: #F1EFEF;
				  color: #515151;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: 500;
				  letter-spacing: 0.58px;
				  line-height: 16px;
				  text-align: center;
					display: block;
				}
				&:hover {
					span{
						color: #065125;
					}
				}
				&.active {

					span{
						background-color: #00A441;
						color: #FFFFFF;
					  font-weight: bold;
					}
				}
			}
		}
	}
}

.lieferung-filter {
	transition: background-color 0.6s;
	padding-top: 2px;
	padding-bottom: 5px;
	width: 100%;
	text-align: center!important;
	.lieferung-nav {
		align-items: center;
		display: inline-flex;
		.nav-item {
			height: 26px;
			width: 116px;
			.nav-link {
				padding: 0;
				width: 105px;
				span{
					padding-top: 3px;
					height: 22px;
					width: 105px;
					border-radius: 5px;
					background-color: #F1EFEF;
				  color: #515151;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: 500;
				  letter-spacing: 0.58px;
				  line-height: 16px;
				  text-align: center;
					display: block;
				}
				&:hover {
					span{
						color: #065125;
					}
				}
				&.active {

					span{
						background-color: #00A441;
						color: #FFFFFF;
					  font-weight: bold;
					}
				}
			}
		}
	}
}

.rahmengroese-btn {
	padding: 0;
	margin: 0;
	span {
		padding-top: 3px;
		height: 22px;
	  width: 38px;
	  border-radius: 5px;
	  background-color: #F1EFEF;
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0.58px;
	  line-height: 16px;
	  text-align: center;
		display: block;
	}
	:hover {
		span {
			background-color: #00A441;
		  color: #FFFFFF;
		}
	}
}
.rahmengrosse {
  height: 25px;
  width: 121px;
  color: #515151;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 20px;
}

.rahmen-row {
	padding-left: 15px;
	padding-top: 7px;
}

.rahmengroese-filter-row {
	padding-left: 0.7rem;
	padding-right: 0;
}

.standort-text {
	color: #515151;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 20px;
}

.standorticon {
	height: auto;
  width: 17px;
}

.adresse-text-umkreis {
	//height: 40px;
	width: 219px;
	color: #515151;
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 18px;
	margin-bottom: 10px;
}
.umkreis-text {
	//height: 34px;
	width: 219px;
	color: #515151;
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 18px;
}

.filtercard-umkreis-btn {
	margin: 0;
	padding: 0;
	height: 40px;
  width: 194px;
  border-radius: 32.09px;
  background-color: #00A441!important;
	color: #FFFFFF!important;
  font-family: "Work Sans";
  font-size: 18.41px;
	text-transform: unset !important;
  font-weight: bold;
  letter-spacing: 0.77px;
  line-height: 21px;
  text-align: center;
}

.profile-mapholder-umkreis {
	width: 100%;
	margin-bottom: 20px;
}

.form-outline-umkreis {
	.md-form {
	  margin-top: 1.5rem;
	  margin-bottom: 0;
	  width: 50%;
		min-height: 53px;
		input[type="text"]:not(.browser-default){
		  border: 1.5px solid #515151;
		  border-radius: 15px;
		}
		input[type="text"]:not(.browser-default):focus:not([readonly]){
			border-bottom: 1.5px solid #515151;
			box-shadow: none!important;
		}
	}
	.select-wrapper input.select-dropdown {
		padding-left: 15px;
		padding-right: 19px;
		width: 85%;
		font-size: 12px;
		color: #515151;
  	font-family: "Work Sans";
	}
	.select-wrapper span.caret {
	  right: 6px;;
	}
	.select-dropdown li.disabled {
		height: 0;
		span {
			font-size: 0!important;
		}
	}
	.fadeElement {
	  top: 80%!important;
	  left: 0;
	  border-radius: 10px;
	}
	.dropdown-content li > a, .dropdown-content li > span {
	  display: inline-block;
	  padding: .5rem;
		color: #515151;
		font-family: "Work Sans";
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0.5px;
		line-height: 17px;
	}
	.dropdown-content li {
		:hover, .dropdown-content li.active {
		  background-color: #a6a4a4;
			width: 100%;
		}
		.active {
		  background-color: #eee;
		}
	}
}

.filtercard-title-umkreis {
	height: 30px;
  //width: 273px;
  color: #00A441;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 26.37px;
  text-align: center;
}
.standort-filter-modal {
	.modal-dialog {
	  max-height: 89vh;
		max-width: 450px;
		.modal-content {
		  max-height: 89vh;
		}
	}
	.modal-title {
	  margin-bottom: 0;
	  line-height: 1.5;
	  width: 100%;
	  text-align: center;
	}
	.modal-header .close {
		position: absolute;
		top: 25px;
		right: 20px;
	  padding: 0;
	  margin: 0;
	}
}
.soriterung-filter-modal {
	.modal-dialog {
	  max-height: 89vh;
		max-width: 450px;
		position: absolute;
		bottom:0;
		.modal-content {
		  max-height: 89vh;
		}
	}
	.modal-title {
	  margin-bottom: 0;
	  line-height: 1.5;
	  width: 100%;
	  text-align: center;
	}
	.modal-header .close {
		position: absolute;
		top: 25px;
		right: 20px;
	  padding: 0;
	  margin: 0;
	}
	.modal-body {
		.form-check {
		 height: 25px;
		 height: 40px;
		 align-items: center;
		 width: 100%;
		 display: inline-flex;
	 	}
		.form-check-input[type="radio"]:checked + label::after{
			background-color: #00A441;
			border: 2px solid #00A441;
		}
	}
}
.radius-text {
	height: 26px;
  color: #515151;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 20px;
}

.show.tooltip{
	top: 0px!important;
}
.tooltip {
  background-color: #6c6a6a!important;
}
.tooltip[x-placement^="top"] .popover_arrow::before {
  border-color: #6c6a6a transparent transparent transparent!important;
}

@media (max-width: 359.98px) {
	.bike-status-filter {
		text-align: center;
		.bike-status-nav {
				display: inline-flex!important;
				.nav-item {
					width: 120px;
					margin-left: 5px;
					margin-right: 5px;
					height: 45px;
					.nav-link {
						width: 120px;
						span{
							width: 120px;
						}
				}
			}
		}
	}
	#eintrage {
		height: 263px!important;
	}
	.bike-price {
		height: 24px;
	  width: 75px;
	  border-radius: 5px;
		bottom: 3px;
		right: 5px;
	}
	.filter-card-anzeige {
		margin-top: 55px;
		margin-bottom: 20px;
		min-height: 100px;
		width: 100%;
		border-radius: 0 0 20px 20px;
		background-color: #FFFFFF;
		max-height: 120vh;
	}

	.favorite-icon-anzeige-fix {
		width: 15px;
		bottom: 9px;
		right: 10px;
	}

	.bikeanzeige-card {
		background-color: #FFFFFF!important;
		background-size: 60% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		height: 225px;
		width: 100%;
		border-radius: 10px;
		margin-bottom: 1rem;
		.blur{
			opacity: 0.3;
		}
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;

			.bookmark-hover {
				width: 100%;
				.bookmark-img{
					position: absolute;
					top: 10px;
					right: 12px;
					width: 15px;
					height: auto;
					z-index: 1;
				}
				.bike-image {
					height: 105px;
					border-radius: 10px;
				}
				.bike-image-keinbild {
					height: 105px;
					border-radius: 10px;
				}
				.bike-image2 {
					height: 105px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.background-hover {
					background-color: 'blue';
					opacity: 0.5;
					z-index: 1;
				}

			}
			.bike-title {
				font-size: 12px;
			}
			.username {
				height: 18px;
				font-size: 10px;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.user-datum {
				font-size: 9px;
			}
			.userProfileName-icon-anzeige{
				height: 35px;
				width: 35px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-foto {
				height: 35px;
				width: 35px;
				border: 1px solid rgba(48,68,91,0.25);
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: -6px;
				right: 10px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
				width: 39.53px;
				opacity: 0.9;
				background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 5px;
				right: 4px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
					width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 15px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 100px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.profilpage-menu {
		height: 29px;
	  //width: 238.5px;
	  color: #919191;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 14px;
	}
	.productanzeige-card {
		background-image: url('../../media/images/b2b/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 55% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		min-height: 280px;
	  width: 100%;
	  border-radius: 15px;
		margin-bottom: 1rem;
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				height: 100px;
				width: auto;
				background-size: cover;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
		    opacity: 0.5;
		    z-index: 1;
			}
			.bike-title {
				width: 100%;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				letter-spacing: 0.32px;
				line-height: 21px;
				text-align: left;
			}
			.username {
				height: 20px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-photo{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: -6px;
				right: 10px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
			  width: 39.53px;
			  opacity: 0.9;
			  background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
	  			width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 15px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}

	.lieferung-filter {
		.lieferung-nav {
			.nav-item {
				.nav-link {
					span{
						padding-top: 5px;
						height: 28px;
					}
				}
			}
		}
	}
	.anzeigepage-filter {
		border-bottom: none;
	}
	.collapse-button-respo{
		padding:10px 0 0 0;
		margin:0;
	}
	.collapse-btn-icon {
		width: 14px;
		height: auto;
	}
	.collapse-btn-text {
		width: 75px;
		display: inline-block;
		font-family: "Work Sans";
		font-size: 12px;
	}
}

@media (min-width: 360px) and (max-width: 575.98px) {
	.bike-status-filter {
		text-align: center;
		.bike-status-nav {
				display: inline-flex!important;
			.nav-item {
				width: 120px;
				margin-left: 15px;
				margin-right: 15px;
				height: 45px;
				.nav-link {
					width: 120px;
					span{
						width: 120px;
					}
				}
			}
		}
	}
		#eintrage {
			height: 263px!important;
		}
	.filter-card-anzeige {
		margin-top: 43px;
		margin-bottom: 10px;
		min-height: 90px;
		max-height: 100vh;
		width: 100%;
		border-radius: 0 0 20px 20px;
		background-color: #FFFFFF;
	}

	.favorite-icon-anzeige-fix {
		width: 15px;
		bottom: 9px;
		right: 10px;
	}

	.bikeanzeige-card {
		background-color: #FFFFFF!important;
		background-size: 60% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		height: 225px;
	  width: 100%;
	  border-radius: 10px;
		margin-bottom: 1rem;
		.blur{
			opacity: 0.3;
		}
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;

			.bookmark-hover {
				width: 100%;
				.bookmark-img{
					position: absolute;
					top: 10px;
					right: 12px;
					width: 15px;
					height: auto;
					z-index: 1;
				}
				.bike-image {
					height: 105px;
					width: auto;
					max-width: 100%;
					border-radius: 10px;
				}
				.bike-image-keinbild {
					height: 105px;
					border-radius: 10px;
				}
				.bike-image2 {
					height: 105px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.background-hover {
					background-color: 'blue';
			    opacity: 0.5;
			    z-index: 1;
				}

			}
			.bike-title {
				font-size: 13px;
			}
			.username {
				height: 18px;
				font-size: 10px;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.user-datum {
			  font-size: 9px;
			}
			.userProfileName-icon-anzeige{
				height: 35px;
				width: 35px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 13px;
			}
			.user-foto {
				height: 35px;
				width: 35px;
				border: 1px solid rgba(48,68,91,0.25);
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: -6px;
				right: 10px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 35px;
			  width: 35px;
			  opacity: 0.9;
			  background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 5px;
				right: 4px;
				z-index: 1;
				padding: 0;
				padding-top: 5px;
				margin: 0;
				.pen{
					height: 13.98px;
	  			width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 15px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 100px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.profilpage-menu {
		height: 29px;
	  //width: 238.5px;
	  color: #919191;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 14px;
	}
	.productanzeige-card {
		background-image: url('../../media/images/b2b/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 55% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		min-height: 280px;
	  width: 100%;
	  border-radius: 15px;
		margin-bottom: 1rem;
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				height: 100px;
				width: auto;
				max-width: 100%;
				background-size: cover;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
		    opacity: 0.5;
		    z-index: 1;
			}
			.bike-title {
				width: 100%;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				letter-spacing: 0.32px;
				line-height: 21px;
				text-align: left;
			}
			.username {
				height: 20px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-photo{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
			  width: 39.53px;
			  opacity: 0.9;
			  background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
	  			width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}

	.lieferung-filter {
		.lieferung-nav {
			.nav-item {
				.nav-link {
					span{
						padding-top: 5px;
						height: 28px;
					}
				}
			}
		}
	}
	.anzeigepage-filter {
		border-bottom: none;
	}
	.collapse-button-respo{
		padding:10px 0 0 0;
		margin:0;
	}
	.collapse-btn-icon {
		width: 14px;
		height: auto;
	}
	.collapse-btn-text {
		width: 75px;
		display: inline-block;
		font-family: "Work Sans";
		font-size: 12px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bike-status-filter {
		text-align: center;
		.bike-status-nav {
				display: inline-flex!important;
			.nav-item {
				width: 120px;
				margin-left: 15px;
				margin-right: 15px;
				height: 45px;
				.nav-link {
					width: 120px;
					span{
						width: 120px;
					}
				}
			}
		}
	}
	.filter-card-anzeige {
		margin-top: 80px;
		margin-bottom: 20px;
		min-height: 90px;
		width: 100%;
		border-radius: 0 0 20px 20px;
		background-color: #FFFFFF;
		max-height: 100vh;
	}

	.fold-padding {
		padding-left:20px;
		padding-right: 20px;
	}
	.fold-padding-2 {
		padding-right: 27px!important;
	}

	.favorite-icon-anzeige-fix {
		width: 15px;
		bottom: 9px;
		right: 10px;
	}

	.bikeanzeige-card {
		background-color: #FFFFFF!important;
		background-size: 35% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		height: 285px;
		width: 100%;
		border-radius: 10px;
		margin-bottom: 1rem;
		.blur{
			opacity: 0.3;
		}
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;

			.bookmark-hover {
				width: 100%;
				.bookmark-img{
					position: absolute;
					top: 10px;
					right: 12px;
					width: 15px;
					height: auto;
					z-index: 1;
				}
				.bike-image {
					height: 150px;
					width: auto;
					max-width: 100%;
					border-radius: 10px;
				}
				.bike-image-keinbild {
					height: 105px;
					border-radius: 10px;
				}
				.bike-image2 {
					height: 165px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.background-hover {
					background-color: 'blue';
					opacity: 0.5;
					z-index: 1;
				}

			}
			.bike-title {
				font-size: 13px;
			}
			.username {
				height: 18px;
				font-size: 10px;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.user-datum {
				font-size: 9px;
			}
			.userProfileName-icon-anzeige{
				height: 35px;
				width: 35px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-foto {
				height: 35px;
				width: 35px;
				border: 1px solid rgba(48,68,91,0.25);
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
				width: 39.53px;
				opacity: 0.9;
				background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
					width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 145px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.profilpage-menu {
		height: 29px;
		//width: 238.5px;
		color: #919191;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 14px;
	}
	.productanzeige-card {
		background-image: url('../../media/images/b2b/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 55% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		min-height: 280px;
		width: 100%;
		border-radius: 15px;
		margin-bottom: 1rem;
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				height: 100px;
				width: auto;
				background-size: cover;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
				opacity: 0.5;
				z-index: 1;
			}
			.bike-title {
				width: 100%;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				letter-spacing: 0.32px;
				line-height: 21px;
				text-align: left;
			}
			.username {
				height: 20px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-photo{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
				width: 39.53px;
				opacity: 0.9;
				background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
					width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}

	.lieferung-filter {
		.lieferung-nav {
			.nav-item {
				.nav-link {
					span{
						padding-top: 5px;
						height: 28px;
					}
				}
			}
		}
	}
	.anzeigepage-filter {
		border-bottom: none;
	}
	.collapse-button-respo{
		padding:10px 0 0 0;
		margin:0;
	}
	.collapse-btn-icon {
		width: 14px;
		height: auto;
	}
	.collapse-btn-text {
		width: 75px;
		display: inline-block;
		font-family: "Work Sans";
		font-size: 12px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bike-status-filter {
		text-align: center;
		.bike-status-nav {
				display: inline-flex!important;
			.nav-item {
				width: 120px;
				margin-left: 15px;
				margin-right: 15px;
				height: 45px;
				.nav-link {
					width: 120px;
					span{
						width: 120px;
					}
				}
			}
		}
	}
	.filter-card-anzeige {
		margin-top: 55px;
		margin-bottom: 20px;
		min-height: 90px;
		width: 100%;
		border-radius: 0 0 20px 20px;
		background-color: #FFFFFF;
		max-height: 100vh;
	}

	.favorite-icon-anzeige-fix {
		width: 15px;
		bottom: 9px;
		right: 10px;
	}

	.bikeanzeige-card {
		background-color: #FFFFFF!important;
		background-size: 35% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		height: 285px;
		width: 100%;
		border-radius: 10px;
		margin-bottom: 1rem;
		.blur{
			opacity: 0.3;
		}
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;

			.bookmark-hover {
				width: 100%;
				.bookmark-img{
					position: absolute;
					top: 10px;
					right: 12px;
					width: 15px;
					height: auto;
					z-index: 1;
				}
				.bike-image {
					height: 149px;
					border-radius: 10px;
				}
				.bike-image-keinbild {
					height: 105px;
					border-radius: 10px;
				}
				.bike-image2 {
					height: 165px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.background-hover {
					background-color: 'blue';
					opacity: 0.5;
					z-index: 1;
				}

			}
			.bike-title {
				font-size: 13px;
			}
			.username {
				height: 18px;
				font-size: 10px;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.user-datum {
				font-size: 9px;
			}
			.userProfileName-icon-anzeige{
				height: 35px;
				width: 35px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-foto {
				height: 35px;
				width: 35px;
				border: 1px solid rgba(48,68,91,0.25);
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
				width: 39.53px;
				opacity: 0.9;
				background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
					width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 120.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.profilpage-menu {
		height: 29px;
		//width: 238.5px;
		color: #919191;
		font-family: "Work Sans";
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 14px;
	}
	.productanzeige-card {
		background-image: url('../../media/images/b2b/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 55% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		min-height: 280px;
		width: 100%;
		border-radius: 15px;
		margin-bottom: 1rem;
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				height: 100px;
				width: auto;
				background-size: cover;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
				opacity: 0.5;
				z-index: 1;
			}
			.bike-title {
				width: 100%;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				letter-spacing: 0.32px;
				line-height: 21px;
				text-align: left;
			}
			.username {
				height: 20px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-photo{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
				width: 39.53px;
				opacity: 0.9;
				background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
					width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}

	.lieferung-filter {
		.lieferung-nav {
			.nav-item {
				.nav-link {
					span{
						padding-top: 5px;
						height: 28px;
					}
				}
			}
		}
	}
	.anzeigepage-filter {
		border-bottom: none;
	}
	.collapse-button-respo{
		padding:10px 0 0 0;
		margin:0;
	}
	.collapse-btn-icon {
		width: 14px;
		height: auto;
	}
	.collapse-btn-text {
		width: 75px;
		display: inline-block;
		font-family: "Work Sans";
		font-size: 12px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bike-status-filter {
		text-align: center;
		.bike-status-nav {
				display: inline-flex!important;
			.nav-item {
				width: 120px;
				margin-left: 15px;
				margin-right: 15px;
				height: 35px;
				.nav-link {
					width: 120px;
					span{
						width: 120px;
					}
				}
			}
		}
	}
	.bike-price {
		bottom: 5px;
		right: 10px;
	}
	.sticky-card-borse {
		position: sticky;
		top: 85px;
		margin-bottom: 15px;
		z-index: 1010;
	}
	.bikeanzeige-card {
		background-image: url('../../media/images/markt/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 35% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		height: 275px;
	  width: 100%;
	  border-radius: 15px;
		margin-bottom: 1rem;
		.blur{
			opacity: 0.3;
		}
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;

			.bookmark-hover {
				width: 100%;
				.bookmark-img{
					position: absolute;
					top: 10px;
					right: 12px;
					width: 15px;
					height: auto;
					z-index: 1;
				}
				.bike-image {
					height: 135px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.bike-image-keinbild {
					height: 135px;
					width: 83%;
					border-radius: 15px;
				}
				.bike-image2 {
					height: 165px;
					width: auto;
					max-width: 100%;
					border-radius: 15px;
				}
				.background-hover {
					background-color: 'blue';
			    opacity: 0.5;
			    z-index: 1;
				}

			}
			.bike-title {
				height: 35px;
				//width: 172px;
				color: #00A441;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.32px;
				line-height: 21px;
			}
			.username {
				height: 20px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.user-datum {
			  color: #30445B;
			  font-family: "Work Sans";
			  font-size: 10px;
			  font-weight: 500;
			  letter-spacing: 0;
			  line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 44.84px;
				width: 44.84px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-foto {
				box-sizing: border-box;
				height: 44.34px;
				width: 44.34px;
				border: 1px solid rgba(48,68,91,0.25);
				border-radius: 50%;
				background-color: #FFFFFF;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: -10px;
				right: 10px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
			  width: 39.53px;
			  opacity: 0.9;
			  background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 5px;
				right: 5px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
	  			width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.profilpage-menu {
		height: 29px;
	  //width: 238.5px;
	  color: #919191;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 14px;
	}
	.productanzeige-card {
		background-image: url('../../media/images/b2b/Group 26.png');
		background-color: #FFFFFF!important;
		background-size: 55% auto;
		background-position: right bottom;
		background-repeat: no-repeat;
		min-height: 280px;
	  width: 100%;
	  border-radius: 15px;
		margin-bottom: 1rem;
		.card-body {
			padding:0;
			padding-top: .2rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.bookmark-img{
				position: absolute;
				top: 10px;
				right: 12px;
				width: 15px;
				height: auto;
				z-index: 1;
			}
			.bike-image {
				height: 100px;
				width: auto;
				background-size: cover;
				border-radius: 15px;
			}
			.background-hover {
				background-color: 'blue';
		    opacity: 0.5;
		    z-index: 1;
			}
			.bike-title {
				width: 100%;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				letter-spacing: 0.32px;
				line-height: 21px;
				text-align: left;
			}
			.username {
				height: 30px;
				//width: 150px;
				color: #30445B;
				margin: 0;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
			}
			.datum-text {
				height: 19px;
				width: 150px;
				color: #30445B;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 12px;
			}
			.userProfileName-icon-anzeige{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
			.user-photo{
				height: 46px;
				width: 46px;
				border-radius: 50%;
				border: 1px solid #7F8A83;
			}
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
			.favorite-true {
				position: absolute;
				bottom: 5px;
				right: 20px;
				z-index: 1;
				padding: 0;
				margin: 0;
			}
			.edit-btn{
				height: 39.53px;
			  width: 39.53px;
			  opacity: 0.9;
			  background-color: #00A441!important;
				border-radius: 50%;
				position: absolute;
				bottom: 22px;
				right: 14px;
				z-index: 1;
				padding: 0;
				padding-top: 7px;
				margin: 0;
				.pen{
					height: 13.98px;
	  			width: 12px;
				}
			}
			.favorite-icon-anzeige {
				width: 25px;
				height: auto;
			}
			.trashicon-icon{
				width: 22px;
				height: auto;
			}
		}
		.anzeige-status{
			position: absolute;
			top:-3.3px;
			left: -4px;
			z-index: 1;
		}
		.anzeige-status-boerse{
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
		}
		.status{
			height: auto;
			width: 174.7px;
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}

	.filter-card-anzeige {
		//min-height: 590px;
		max-height: 81vh;
		width: 100%;
		border-radius: 20px;
		background-color: #FFFFFF;
	}
}
