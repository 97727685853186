.invitations-title-text {
  height: 33px;
  width: 516px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
}

.invitations-status-text {
  height: 33px;
  color: #FF9500;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  padding-top: 15px;
  display: inline-block;
}

.invitations-title-text-Welcome {
  height: 37px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  padding-top: 30px;
}

.invitations-title-text-verwalten {
  height: 37px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}

.invitations-normal-text {
  height: 105px;
  width: 460px;
  color: #828282;
  font-family: "Work Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  display: inline-block;
}

.invitations-normal-text-small {
  height: 50px;
  width: 460px;
  color: #828282;
  font-family: "Work Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  display: inline-block;
}

.invitations-normal-text-small-2 {
  height: 90px;
  width: 460px;
  color: #828282;
  font-family: "Work Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  display: inline-block;
}

.invitations-einladund-text {
  height: 50px;
  width: 460px;
  color: #828282;
  font-family: "Work Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  display: inline-block;
}

.invitations-list-text {
  height: 44px;
  width: 240px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 4px;
  display: inline-block;
}

.invitations-b2b-icon {
  height: auto;
  width: 35%;
}

.invitations-landing-form {
	.md-form {
		margin-top: 4px;
		margin-bottom: 0px;
		padding-right: 10px;
		input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
			width: 445px;
			height: 40px;
			background-color: #FFFFFF;
			border-radius: 5px;
			border-bottom: 1px solid #ced4da;
			border-top: 1px solid #ced4da;
			border-left: 1px solid #ced4da;
			border-right: 1px solid #ced4da;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			&:focus{
				border-color: #30445b !important;
				border: 2px solid #30445b !important;
				border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #30445B;
				}
			}

		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			top: 2px;
			&.active {
				transform: translateY(5px) scale(1);
				top: 0px;
			}
		}
	 }
}

.invitations-btn-revoke {
  box-sizing: border-box;
  height: 32px;
  width: 107px;
  border: 1px solid #FF9500;
  border-radius: 10px;
  color: #FF9500!important;
  font-family: "Work Sans";
	padding-top: 5px;
	padding-left: 0px;
	padding-right: 0px;
	font-size: 14px;
	margin-left: 0px;
  margin-top: -4px;
}

.invitations-btn-accept {
  box-sizing: border-box;
  height: 32px;
  width: 107px;
  font-family: "Work Sans";
  background-color: #FF9500!important;
  border-radius: 10px;
  color: #FFFFFF!important;
	padding-top: 5px;
	padding-left: 0px;
	padding-right: 0px;
	font-size: 14px;
	margin-left: 0px;
  margin-top: -4px;
}

.invitations-btn-send {
    width: 120px;
    height: 36px;
    border-radius: 10px;
    padding-top: 6px;
    padding-left: 0px;
    padding-right: 0px;
    font-family: "Work Sans";
    background-color: #FF9500!important;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    margin-left: 0px;
    margin-bottom: 25px;
}

.invitation-card {
	border-radius: 15px;
	margin-bottom: 1rem;
  padding-bottom: 1rem;
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-left: 1rem;
		padding-right: 1rem;
		.list-group {
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				color: #000;
			}
		}
	}
}

@media (max-width: 575.98px) {

  .invitations-normal-text {
    height: 150px;
      width: 100%;
  }

  .invitations-normal-text-small {
    height: 90px;
      width: 100%;
    //padding-top: 15px;
  }

  .invitations-normal-text-small-2 {
    height: 90px;
      width: 100%;
    //padding-top: 15px;
  }

  .invitations-einladund-text {
    height: 45px;
      width: 100%;
    //padding-top: 15px;
  }

  .invitations-landing-form {
  	.md-form {
  		input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
  			  width: 100%;

  		}
  	}
  }

  .invitations-b2b-icon {
    width: 75%;
  }

}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .invitations-normal-text {
    height: 105px;
    width: 515px;
  }

  .invitations-normal-text-small {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-normal-text-small-2 {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-einladund-text {
    height: 45px;
    width: 515px;
    //padding-t
  }

  .invitations-landing-form {
    .md-form {
      input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
        width: 515px;

      }
    }
  }
  .invitations-b2b-icon {
    width: 55%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .invitations-normal-text {
    height: 105px;
    width: 515px;
  }

  .invitations-normal-text-small {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-normal-text-small-2 {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-einladund-text {
    height: 45px;
    width: 515px;
    //padding-t
  }
  .invitations-b2b-icon {
    width: 45%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .invitations-normal-text {
    height: 105px;
    width: 515px;
  }

  .invitations-normal-text-small {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-normal-text-small-2 {
    height: 65px;
    width: 515px;
    //padding-top: 15px;
  }

  .invitations-einladund-text {
    height: 45px;
    width: 515px;
    //padding-t
  }
  .invitations-b2b-icon {
    width: 45%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
