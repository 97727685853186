.profilepage-card-respo{
	//min-height: 50px;
  width: 100%;
  border-radius: 0 0 0 0;
  background-color: transparent;
	padding-left: 0;
	padding-right: 0;
	//margin-top: 32px;
	.profilpage-hauptmenu {
		padding-left: 15px;
		padding-right: 18px;
		display: inline-flex;
		align-items: center;
		width: 100%;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
		.currentcompany-nav {
			display:inline-flex!important;
			.nav-item {
				height: 55px;
				.nav-link {
					display: flex;
					padding: .5rem 1rem;
					height: 50px;
					width: 100px;
					text-align: center;
					display: inline-block;
					&.active {
						&:after {
							content: "";
							background-color: #00A441 !important;
							width: 40px;
							height: 4px;
							display: flex;
							margin: 5px auto 0px;
							border-radius: 2px;
							position: absolute;
							left: 29px;
							top: 38px;
						}
					}
				}
			}
		}
		.landingmarkt-nav {
			//height: 57.3px;
			//align-items: left;
			width: 100%;
			.nav-item {
				height: 33px;
				width: 16.6%;
				text-align: center;
				//margin-bottom: 5px;
				//width: 224px;
				&.active {
					&:after {
						background-color: #e8e8e8;
					}
				}
				.nav-link {
					color: #515151;
					text-align: center;
					padding: 0rem;
					height: 33px;
					.profil-logo {
						//margin-top:2px;
						height: 15px;
  					width: auto;
					}
					span{
					  font-family: "Work Sans";
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: 0.28px;
					  line-height: 26.37px;
					}
					&:hover {
						span{
							color: #065125;
						}
					}
					&.active {
						&:after {
							content: "";
								height: 3px;
								width: 60px;
								background-color: #00A441;
								display: block;
								margin: 5px auto 12px;
								border-radius: 2px;
								position: relative;
								bottom: 0px;
								left: 0;
							}
							span{
								color: #00A441;
								font-family: "Work Sans";
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.28px;
								line-height: 26.37px;
							}
							&:hover {
								span{
									color: #065125;
								}
							}
					}
				}
			}
		}
	}
}
.profilepage-card-respo-b2b{
	//min-height: 50px;
  width: 100%;
  border-radius: 0 0 0 0;
  background-color: transparent;
	padding-left: 0;
	padding-right: 0;
	//margin-top: 32px;
	.profilpage-hauptmenu {
		padding-left: 15px;
		padding-right: 18px;
		display: inline-flex;
		align-items: center;
		width: 100%;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
		.currentcompany-nav {
			display:inline-flex!important;
			.nav-item {
				height: 55px;
				.nav-link {
					display: flex;
					padding: .5rem 1rem;
					height: 50px;
					width: 100px;
					text-align: center;
					display: inline-block;
					&.active {
						&:after {
							content: "";
							background-color: #FF9500 !important;
							width: 40px;
							height: 4px;
							display: flex;
							margin: 5px auto 0px;
							border-radius: 2px;
							position: absolute;
							left: 29px;
							top: 38px;
						}
					}
				}
			}
		}
		.landingmarkt-nav {
			//height: 57.3px;
			//align-items: left;
			width: 100%;
			.nav-item {
				height: 33px;
				width: 16.6%;
				text-align: center;
				//margin-bottom: 5px;
				//width: 224px;
				&.active {
					&:after {
						background-color: #e8e8e8;
					}
				}
				.nav-link {
					color: #515151;
					text-align: center;
					padding: 0rem;
					height: 33px;
					.profil-logo {
						//margin-top:2px;
						height: 15px;
  					width: auto;
					}
					span{
					  font-family: "Work Sans";
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: 0.28px;
					  line-height: 26.37px;
					}
					&:hover {
						span{
							color: #065125;
						}
					}
					&.active {
						&:after {
							content: "";
								height: 3px;
								width: 60px;
								background-color: #FF9500;
								display: block;
								margin: 5px auto 12px;
								border-radius: 2px;
								position: relative;
								bottom: 0px;
								left: 0;
							}
							span{
								color: #FF9500;
								font-family: "Work Sans";
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.28px;
								line-height: 26.37px;
							}
							&:hover {
								span{
									color: #065125;
								}
							}
					}
				}
			}
		}
	}
}

.profilepage-card-b2b{
	min-height: 375px;
  width: 254px;
  border-radius: 20px;
  background-color: #FFFFFF;
	padding-left: 20px;
	.profilpage-hauptmenu {
		padding-top: 15px;
		padding-bottom: 10px;
		width: 100%;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
		.landingmarkt-nav {
			align-items: left;
			.nav-item {
				height: 50px;
				width: 224px;
				&.active {
					&:after {
						background-color: #e8e8e8;
					}
				}
				.nav-link {
					color: #515151;
					text-align: left;
					padding: .5rem;
					.col-height {
						height: 26px;
					}
					.profil-logo {
						margin-top:2px;
						height: 15px;
  					width: 15px;
					}
					span{
					  font-family: "Work Sans";
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: 0.28px;
					  line-height: 26.37px;
					}
					&:hover {
						span{
							color: #865E26;
						}
					}
					&.active {
							content: "";
							height: 42px;
						  width: 250px;
							display: block;
							margin: 0px auto 5px;
							border-radius: 4px;
							span{
								color: #FF9500;
								font-family: "Work Sans";
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.28px;
								line-height: 26.37px;
							}
							&:hover {
								span{
									color: #865E26;
								}
							}
						}
					}
				}
			}
		}
	}

  .profil-logo-modal {
    height: auto;
    width: 25px;
  }

@media (max-width: 575.98px) {

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .profilepage-card-respo{
  	//min-height: 50px;
    width: 100%;
    border-radius: 0 0 0 0;
    background-color: transparent;
  	padding-left: 0;
  	padding-right: 0;
  	//margin-top: 32px;
  	.profilpage-hauptmenu {
  		padding-left: 15px;
  		padding-right: 18px;
  		display: inline-flex;
  		align-items: center;
  		width: 100%;
  		.anmelden-logo {
  		  height: auto;
  		  width: 17px;
  		}
  		.currentcompany-nav {
  			display:inline-flex!important;
  			.nav-item {
  				height: 55px;
  				.nav-link {
  					display: flex;
  					padding: .5rem 1rem;
  					height: 50px;
  					width: 100px;
  					text-align: center;
  					display: inline-block;
  					&.active {
  						&:after {
  							content: "";
  							background-color: #00A441 !important;
  							width: 40px;
  							height: 4px;
  							display: flex;
  							margin: 5px auto 0px;
  							border-radius: 2px;
  							position: absolute;
  							left: 29px;
  							top: 38px;
  						}
  					}
  				}
  			}
  		}
  		.landingmarkt-nav {
  			//height: 57.3px;
  			//align-items: left;
  			width: 100%;
  			.nav-item {
  				height: 33px;
  				width: 16.6%;
  				text-align: center;
  				//margin-bottom: 5px;
  				//width: 224px;
  				&.active {
  					&:after {
  						background-color: #e8e8e8;
  					}
  				}
  				.nav-link {
  					color: #515151;
  					text-align: center;
  					padding: 0rem;
  					height: 33px;
  					.profil-logo {
  						//margin-top:2px;
  						height: 15px;
    					width: auto;
  					}
  					span{
  					  font-family: "Work Sans";
  					  font-size: 14px;
  					  font-weight: 500;
  					  letter-spacing: 0.28px;
  					  line-height: 26.37px;
  					}
  					&:hover {
  						span{
  							color: #065125;
  						}
  					}
  					&.active {
  						&:after {
  							content: "";
  								height: 3px;
  								width: 60px;
  								background-color: #00A441;
  								display: block;
  								margin: 5px auto 12px;
  								border-radius: 2px;
                  position: relative;
                  bottom: 0px;
                  left: 0px;
  							}
  							span{
  								color: #00A441;
  								font-family: "Work Sans";
  								font-size: 14px;
  								font-weight: bold;
  								letter-spacing: 0.28px;
  								line-height: 26.37px;
  							}
  							&:hover {
  								span{
  									color: #065125;
  								}
  							}
  					}
  				}
  			}
  		}
  	}
  }

  .profilepage-card-b2b{
  	min-height: 375px;
    width: 254px;
    border-radius: 20px;
    background-color: #FFFFFF;
  	padding-left: 20px;
  	.profilpage-hauptmenu {
  		padding-top: 15px;
  		padding-bottom: 10px;
  		width: 100%;
  		.anmelden-logo {
  		  height: auto;
  		  width: 17px;
  		}
  		.landingmarkt-nav {
  			align-items: left;
  			.nav-item {
  				height: 50px;
  				width: 224px;
  				&.active {
  					&:after {
  						background-color: #e8e8e8;
  					}
  				}
  				.nav-link {
  					color: #515151;
  					text-align: left;
  					padding: .5rem;
  					.col-height {
  						height: 26px;
  					}
  					.profil-logo {
  						margin-top:2px;
  						height: 15px;
    					width: 15px;
  					}
  					span{
  					  font-family: "Work Sans";
  					  font-size: 14px;
  					  font-weight: 500;
  					  letter-spacing: 0.28px;
  					  line-height: 26.37px;
  					}
  					&:hover {
  						span{
  							color: #865E26;
  						}
  					}
  					&.active {
  							content: "";
  							height: 42px;
  						  width: 250px;
  							display: block;
  							margin: 0px auto 5px;
  							border-radius: 4px;
  							span{
  								color: #FF9500;
  								font-family: "Work Sans";
  								font-size: 14px;
  								font-weight: bold;
  								letter-spacing: 0.28px;
  								line-height: 26.37px;
  							}
  							&:hover {
  								span{
  									color: #865E26;
  								}
  							}
  						}
  					}
  				}
  			}
  		}
  	}
  .chatlist-card-markt {
    height: calc(100vh - 8.2rem);
    border-radius: 0px;
    margin-bottom: 1rem;
    padding-bottom: 15px;
    .card-title {
      font-size: 14px;
      font-weight: 600;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;
      color: #0360EC;
      text-align: center;
      a {
        color: #000;
      }
    }
    .card-body {
      padding:0;
      padding-left: 1rem;
      padding-right: 1rem;
      overflow: hidden;
      .scrollbar {
        height: calc(100vh - 14.5rem);
      }
      .filter-text-green {
        color: #00A441;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        height: 28px;
        display: inline-block;
        width: 65px;
      }
      .filter-text-grau {
        opacity: 0.5;
        color: #101B4F;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        height: 28px;
        display: inline-block;
        width: 65px;
      }
      .filter-text-green-inactive {
        color: #00A441;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        height: 28px;
        display: inline-block;
        width: 75px;
      }
      .filter-text-grau-inactive {
        opacity: 0.5;
        color: #101B4F;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        height: 28px;
        display: inline-block;
        width: 75px;
      }
      .filter-icon {
        height: auto;
        width: 32px;
      }
      .list-group {
        max-width: 298px;
        .list-group-item {
          height: 64px;
          padding: .35rem 0;
          border-left:0;
          border-right:0;
          border-top:0;
          border-bottom:0;
          color: #000;
          .nav-link {
            padding: .5rem 0rem;
            height: 64px;
          }
          .photo {
            height: 35px;
            width: 35px;
            box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
            border-radius: 50%;
          }
          .chat-name {
            color: #101B4F;
            font-size: 13px;
            height: 20px;
            width: 100%;
            font-family: "Work Sans";
            letter-spacing: 0;
            //line-height: 14px;
            display: inline-block;
          }
          .chat-name-white {
            color: #ffff;
          }
          .user-online {
            box-sizing: border-box;
            border-radius: 50%;
            height: 14.5px;
            width: 14.5px;
            border: 2px solid #FFFFFF;
            background-color: #62EC03;
            position: absolute;
            top: 24px;
            right: -3px;
          }
          .user-offline {
            box-sizing: border-box;
            border-radius: 50%;
            height: 14.5px;
            width: 14.5px;
            border: 2px solid #FFFFFF;
            background-color: rgb(161, 161, 170);
            position: absolute;
            top: 24px;
            right: -3px;
          }
          p {
            color: #9B9B9B;
            margin-bottom: 0;
          }
          span{
            font-size: 12px;
            color: #353030;
          }
        }
        .list-group-item-2 {
          padding: .75rem 0;
          border-left:0;
          border-right:0;
          border-top:0;
          border-bottom:0;
          color: #edeaea;
          .chat-name {
            height: 24px;
            width: 100%;
            color: #101B4F;
            font-family: "Work Sans";
            letter-spacing: 0;
            line-height: 14px;
            font-size: 16px;
            display: inline-block;
          }
          .chat-name-white {
            color: #ffff;
          }
          p {
            color: #9B9B9B;
            margin-bottom: 0;
          }
          span{
            font-size: 12px;
            color: #353030;
          }
        }
      }
    }
    .card-footer {
      background-color: #ffffff;
      border-top: 0;
      border-radius: .5rem;
    }
  }
  .grau-back {
    height: 33px;
  }
  .chatlist-respo-btn {
    position:fixed!important;
    top: 111px;
    left: 16px;
    margin: 0;
    padding: 0;
    z-index: 1000;
    .respo-hamburger-menu {
      width:30px;
      height: auto;
    }
  }
  .side-nav {
    position: fixed;
    top: 149px;
    left: 0;
    width: 100% !important;
    height: 100%;
    background-color: transparent!important;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
    border-radius: 0;
    padding:0;

  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .profilepage-card-respo{
  	//min-height: 50px;
    width: 100%;
    border-radius: 0 0 0 0;
    background-color: transparent;
  	padding-left: 0;
  	padding-right: 0;
  	//margin-top: 32px;
  	.profilpage-hauptmenu {
  		padding-left: 15px;
  		padding-right: 18px;
  		display: inline-flex;
  		align-items: center;
  		width: 100%;
  		.anmelden-logo {
  		  height: auto;
  		  width: 17px;
  		}
  		.currentcompany-nav {
  			display:inline-flex!important;
  			.nav-item {
  				height: 55px;
  				.nav-link {
  					display: flex;
  					padding: .5rem 1rem;
  					height: 50px;
  					width: 100px;
  					text-align: center;
  					display: inline-block;
  					&.active {
  						&:after {
  							content: "";
  							background-color: #00A441 !important;
  							width: 40px;
  							height: 4px;
  							display: flex;
  							margin: 5px auto 0px;
  							border-radius: 2px;
  							position: absolute;
  							left: 29px;
  							top: 38px;
  						}
  					}
  				}
  			}
  		}
  		.landingmarkt-nav {
  			//height: 57.3px;
  			//align-items: left;
  			width: 100%;
  			.nav-item {
  				height: 33px;
  				width: 16.6%;
  				text-align: center;
  				//margin-bottom: 5px;
  				//width: 224px;
  				&.active {
  					&:after {
  						background-color: #e8e8e8;
  					}
  				}
  				.nav-link {
  					color: #515151;
  					text-align: center;
  					padding: 0rem;
  					height: 33px;
  					.profil-logo {
  						//margin-top:2px;
  						height: 15px;
    					width: auto;
  					}
  					span{
  					  font-family: "Work Sans";
  					  font-size: 14px;
  					  font-weight: 500;
  					  letter-spacing: 0.28px;
  					  line-height: 26.37px;
  					}
  					&:hover {
  						span{
  							color: #065125;
  						}
  					}
  					&.active {
  						&:after {
  							content: "";
  								height: 3px;
  								width: 60px;
  								background-color: #00A441;
  								display: block;
  								margin: 5px auto 12px;
  								border-radius: 2px;
                  position: relative;
  								bottom: 0px;
  								left: 0;
  							}
  							span{
  								color: #00A441;
  								font-family: "Work Sans";
  								font-size: 14px;
  								font-weight: bold;
  								letter-spacing: 0.28px;
  								line-height: 26.37px;
  							}
  							&:hover {
  								span{
  									color: #065125;
  								}
  							}
  					}
  				}
  			}
  		}
  	}
  }

  .profilepage-card-b2b{
  	min-height: 375px;
    width: 254px;
    border-radius: 20px;
    background-color: #FFFFFF;
  	padding-left: 20px;
  	.profilpage-hauptmenu {
  		padding-top: 15px;
  		padding-bottom: 10px;
  		width: 100%;
  		.anmelden-logo {
  		  height: auto;
  		  width: 17px;
  		}
  		.landingmarkt-nav {
  			align-items: left;
  			.nav-item {
  				height: 50px;
  				width: 224px;
  				&.active {
  					&:after {
  						background-color: #e8e8e8;
  					}
  				}
  				.nav-link {
  					color: #515151;
  					text-align: left;
  					padding: .5rem;
  					.col-height {
  						height: 26px;
  					}
  					.profil-logo {
  						margin-top:2px;
  						height: 15px;
    					width: 15px;
  					}
  					span{
  					  font-family: "Work Sans";
  					  font-size: 14px;
  					  font-weight: 500;
  					  letter-spacing: 0.28px;
  					  line-height: 26.37px;
  					}
  					&:hover {
  						span{
  							color: #865E26;
  						}
  					}
  					&.active {
  							content: "";
  							height: 42px;
  						  width: 250px;
  							display: block;
  							margin: 0px auto 5px;
  							border-radius: 4px;
  							span{
  								color: #FF9500;
  								font-family: "Work Sans";
  								font-size: 14px;
  								font-weight: bold;
  								letter-spacing: 0.28px;
  								line-height: 26.37px;
  							}
  							&:hover {
  								span{
  									color: #865E26;
  								}
  							}
  						}
  					}
  				}
  			}
  		}
  	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
