.profil-background {
	min-height: 91vh;
	background-image: url('../../media/images/markt/profil/Group 0.png');
	background-color: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(242,239,239,0.96) 84.6%, #F2EFEF 100%);
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.eigene-padding-bottom{
	padding-bottom: 20px;
}

.profilpage-bottom {
	padding-bottom: 20px;
}

.btn-done-profil {
	width: 150px;
	height: 40px;
	border-radius: 15px;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #00A441 !important;
	text-transform: none;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 22px;
	color: #ffffff;
}

.btn-done-profil-b2b {
	width: 150px;
	height: 40px;
	border-radius: 10px;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #FF9500 !important;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	color: #ffffff;
}

.newpost-1-b2b {
	//height: 58.84px;
	width: 240.05px;
	color: #292929;
	font-family: "Work Sans";
	font-size: 26px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 22px;
}
.newpost-2-b2b {
	//height: 40.11px;
	width: 240.05px;
	color: #FF9500;
	font-family: "Work Sans";
	font-size: 26px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 22px;
}
.newpost-1-b2b-small {
	//height: 58.84px;
	width: 240.05px;
	color: #292929;
	font-family: "Work Sans";
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
}
.newpost-2-b2b-small {
	//height: 40.11px;
	width: 240.05px;
	color: #FF9500;
	font-family: "Work Sans";
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
}
.newpost-1-b2b-middle {
	//height: 58.84px;
	width: 240.05px;
	color: #292929;
	font-family: "Work Sans";
	font-size: 34px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 31px;
}
.newpost-2-b2b-middle {
	//height: 40.11px;
	width: 240.05px;
	color: #FF9500;
	font-family: "Work Sans";
	font-size: 34px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 31px;
}

.group_5_b2b {
	height: auto;
  width: 100%;
}
.group_5_middle {
	height: auto;
  width: 55%;
}

.profilpage-image {
  width: 120px;
  height: 120px;
  border-radius:15px;
	cursor: pointer;
	border: 1px solid #797575;
}

.profil-title-logo {
	height: 67px;
  width: auto;
}
.profil-title-logo-profilpage {
	height: 67px;
  width: auto;
}

.picker-format{
	height: 42px;
	border-radius: 0.3125em;
	border: .5px solid #ccc8c8 !important;
	margin-bottom: 0.375em;
	padding-top: 0.2rem;
	padding-right: 0.7rem;
	padding-bottom: 0.2rem;
	padding-left: 0.7rem;
	background-color: #FFFFFF;
	::before {
	  border-bottom: none!important;
	}
	::after {
	  border-bottom: none!important;
	}
}

.profilepage-card{
	min-height: 375px;
  width: 254px;
  border-radius: 20px;
  background-color: #FFFFFF;
	padding-left: 20px;
	.profilpage-hauptmenu {
		padding-top: 15px;
		padding-bottom: 10px;
		width: 100%;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
		.currentcompany-nav {
				display:inline-flex!important;
			.nav-item {
				height: 55px;
				.nav-link {
					display: flex;
					padding: .5rem 1rem;
					height: 50px;
					width: 100px;
					text-align: center;
					display: inline-block;
					&.active {
						&:after {
							content: "";
							background-color: #00A441 !important;
							width: 40px;
							height: 4px;
							display: flex;
							margin: 5px auto 0px;
							border-radius: 2px;
							position: absolute;
							left: 29px;
							top: 38px;
						}
					}
				}
			}
		}
		.landingmarkt-nav {
			//height: 57.3px;
			align-items: left;
			.nav-item {
				height: 50px;
				//margin-bottom: 5px;
				width: 224px;
				&.active {
					&:after {
						background-color: #e8e8e8;
					}
				}
				.nav-link {
					color: #515151;
					text-align: left;
					padding: .5rem;
					.col-height {
						height: 26px;
					}
					.profil-logo {
						margin-top:2px;
						height: 15px;
  					width: 15px;
					}
					span{
					  font-family: "Work Sans";
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: 0.28px;
					  line-height: 26.37px;
					}
					&:hover {
						span{
							color: #065125;
						}
					}
					&.active {
							content: "";
							height: 42px;
						  width: 250px;
						  //background-color: #c4c1c1;
							display: block;
							margin: 0px auto 5px;
							border-radius: 4px;
							span{
								color: #00A441;
								font-family: "Work Sans";
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.28px;
								line-height: 26.37px;
							}
							&:hover {
								span{
									color: #065125;
								}
							}
							&:after {
								//display: block;
								//position: absolute;
								//right: 0.75em;
								//top: 0.4375em;
								//content: "→"
							}
					}
				}
			}
		}
	}

}

.profilepage-card-b2b{
	min-height: 375px;
  width: 254px;
  border-radius: 20px;
  background-color: #FFFFFF;
	padding-left: 20px;
	.profilpage-hauptmenu {
		padding-top: 15px;
		padding-bottom: 10px;
		width: 100%;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
		.landingmarkt-nav {
			//height: 57.3px;
			align-items: left;
			.nav-item {
				height: 50px;
				//margin-bottom: 5px;
				width: 224px;
				&.active {
					&:after {
						background-color: #e8e8e8;
					}
				}
				.nav-link {
					color: #515151;
					text-align: left;
					padding: .5rem;
					.col-height {
						height: 26px;
					}
					.profil-logo {
						margin-top:2px;
						height: 15px;
  					width: 15px;
					}
					span{
					  font-family: "Work Sans";
					  font-size: 14px;
					  font-weight: 500;
					  letter-spacing: 0.28px;
					  line-height: 26.37px;
					}
					&:hover {
						span{
							color: #865E26;
						}
					}
					&.active {
							content: "";
							height: 42px;
						  width: 250px;
						  //background-color: #c4c1c1;
							display: block;
							margin: 0px auto 5px;
							border-radius: 4px;
							span{
								color: #FF9500;
								font-family: "Work Sans";
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.28px;
								line-height: 26.37px;
							}
							&:hover {
								span{
									color: #865E26;
								}
							}
							&:after {
								//display: block;
								//position: absolute;
								//right: 0.75em;
								//top: 0.4375em;
								//content: "→"
							}
					}
				}
			}
		}
	}
	.currentcompany-nav {
			display:inline-flex!important;
		.nav-item {
			height: 55px;
			.nav-link {
				display: flex;
				padding: .5rem 1rem;
				height: 50px;
				width: 100px;
				text-align: center;
				display: inline-block;
				&.active {
					&:after {
						content: "";
						background-color: #FF9500 !important;
						width: 40px;
						height: 4px;
						display: flex;
						margin: 5px auto 0px;
						border-radius: 2px;
						position: absolute;
						left: 29px;
						top: 38px;
					}
				}
			}
		}
	}
}
.person-card {
	min-height: 614px;
  width: 254px;
  border-radius: 20px;
  background-color: #FFFFFF;
	padding-top: 40px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 40px;
	.contact-btn-person {
		height: 40px;
		width: 119px;
		margin: 0;
		padding: 0;
		border-radius: 13px;
		background-color: #00A441!important;
		.contact-btn-text {
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0.58px;
			line-height: 16px;
			text-align: center;
		}
	}
	.user-foto{
		height: 162px;
		width: 162px;
		border-radius: 50%;
		border: 1px solid #7F8A83;
	}
	.userProfileName-icon {
		height: 162px;
		width: 162px;
		border: 1px solid #7F8A83;
		border-radius: 50%;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		align-items: center;
		color: #fff;
		background-color: #00A441;
		font-family: "Work Sans";
		font-size: 14px;
	}
	.user-name{
		color: #515151;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0.28px;
		line-height: 20px;
		text-align: center;
	}
	.user-telefon{
	  color: #00A441;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: bold;
	  letter-spacing: 0.28px;
	  line-height: 20px;
	  text-align: center;
	}
	.user-aboutUs{
	  color: #30445B;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 17px;
		text-align: left;
	}
	.profile-mapholder {
		border-width: 5px;
		border-radius: 5px;
	}
}
.person-card-respo {
	margin-top: 80px;
	.contact-btn-person {
		height: 40px;
		width: 119px;
		margin: 0;
		padding: 0;
		border-radius: 13px;
		background-color: #00A441!important;
		.contact-btn-text {
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0.58px;
			line-height: 16px;
			text-align: center;
		}
	}
	.user-foto{
		height: 162px;
		width: 162px;
		border-radius: 50%;
		border: 1px solid #7F8A83;
	}
	.userProfileName-icon {
		height: 162px;
		width: 162px;
		border: 1px solid #7F8A83;
		border-radius: 50%;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		align-items: center;
		color: #fff;
		background-color: #00A441;
		font-family: "Work Sans";
		font-size: 14px;
	}
	.user-name{
		color: #515151;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0.28px;
		line-height: 20px;
		text-align: center;
	}
	.user-telefon{
	  color: #00A441;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: bold;
	  letter-spacing: 0.28px;
	  line-height: 20px;
	  text-align: center;
	}
	.user-aboutUs{
	  color: #30445B;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 17px;
		text-align: left;
	}
	.profile-mapholder {
		border-width: 5px;
		border-radius: 5px;
	}
}
.logo-text-name{
	height: 37.31px;
	color: #00A441;
	font-family: "Work Sans";
	font-size: 47.17px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 37.31px;
}






@media (max-width: 575.98px) {
	.profil-title-logo {
		height: auto;
	  width: 100%;
	}
	.profil-title-logo-profilpage {
		height: auto;
	  width: 85%;
	}
	.logo-text-name{
		height: 37.31px;
		color: #00A441;
		font-family: "Work Sans";
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 37.31px;
	}
	.profil-background {
		min-height: 90vh;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
