.weHost {
  background-color: red !important;
}

.registered {
  background-color: green !important;
}

.other {
  background-color: blue !important;
}

.past {
  background-color: lightgrey !important;
}

.hidden {
  background-color: lightgrey !important;
}
