.companies-title {
	font-size: 16px;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 20px;
}

.companiespublic-ib2b {
	margin-bottom: 20px;
	width: 65%;
}

.companiespublic-row {
	max-width: 484px;
	padding: 15px;
	padding-left:85px;
}

.historypublic-row {
	max-width: 428px;
	padding: 15px;
}

.companiespublic-row-right {
	padding-left: 30px;
}

.companies-search-btn {
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
}

.companiespublic-title {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.companiespublic-text {
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
.companiespublic-title2 {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.companiespublic-text2 {
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.companiespublic-standardtitle {
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 23px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px
}

.companiespublic-standardtext {
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
}

.companiespublic-cardtitle {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.companiespublic-cardtext {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 10px;
  line-height: 14px;
  text-align: center;
}

.companiespublic-partner {
  width: 66%;
}



.history-ib2b {
	margin-bottom: 20px;
	width: 70%;
}

.companiespublic-logo {
	padding-top:15px;
  width: 25%;
	padding-bottom: 15px;
}

.historypublic-logo {
	padding-top:15px;
  width: 25%;
	padding-bottom: 15px;
}

.companiesprofile-logo {
	padding-top:25px;
  width: 19%;
	padding-bottom: 20px;
}

.transparent-card-companiespublic {
	box-shadow: none;
	background-color: transparent;
	padding-left:30px;
}

.transparent-card-historypublic {
	box-shadow: none;
	background-color: transparent;
	padding-left:120px;
}

.sticky-card-companiespublic {
    position: sticky;
    top: 3.2rem;
}

.responsiv-row-right {

}

.responsiv-card-companies {

}



.companiespublic-row-button {
	padding-left:20px;
	padding-bottom:20px;
	padding-top:20px;
}

.signin-btn-public {
	width: 168px;
	height: 42px;
	border-radius: 21px;
	padding: 6px;
	margin:0;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	img {
		width: 34px;
		height: 34px;
	}
}

.publicCompaniesWrapper {
	position:fixed;
	right:0;
	top: 0;
	width: 280px;
	max-height: calc(100vh - 6.4rem);
	z-index: 10;
}

.companiesprofile-bottom {
	background-image: url('../../media/images/historypublicbg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 0;
	padding-top: 290px;
	.companiesprofile-bottom-left {
		margin-right: 100px;
		
		.companiesprofile-bottom-form {
			margin-top: 20px;
		}
	}
	
	.companiesprofile-bottom-right {
		margin-left: 120px;
		img {
			margin-bottom: 50px;
		}
		.companiespublic-standardtitle {
			margin-bottom: 50px;
		}
		.companiespublic-standardtext {
			margin-bottom: 20px;
		}
	}
	
	
	

	.profileresponsiv-row-right-title {
		padding-top: 90px;
	}

	.profileresponsiv-row-right-text {
		padding-top: 10px;
		padding-bottom: 20px;
	}
}

.historypublic-nav {
	margin-top: 8px;
	margin-right: 50px;
}
.historypublic-right {
	margin-left: 140px;
	position: sticky;
	top: 60px;
	
	.historypublic-partner {
		width: 68%
	}
	
	.historypublic-signinbox {
		margin-bottom: 20px;
	}
}

@media (max-width: 409.98px) {
	.companiesprofile-bottom {
		background-image: none;
		padding-top: 0px;
		.companiesprofile-bottom-left {
			margin-right: 0px;
		}
		.companiesprofile-bottom-right {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	
	.historypublic-nav {
		position: relative;
		margin-right: 0px;
	}
	
	.historypublic-right {
		margin-left: 0px;
	}
	
}

@media (min-width: 410px) and (max-width: 575.98px) {
	.companiesprofile-bottom {
		background-image: none;
		padding-top: 0px;
		.companiesprofile-bottom-left {
			margin-right: 0px;
		}
		.companiesprofile-bottom-right {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	
	.historypublic-nav {
		position: relative;
		margin-right: 0px;
	}
	
	.historypublic-right {
		margin-left: 0px;
	}

	.responsiv-card-companies {
		padding-left:23px;
	}
	.transparent-card-companiespublic {
		padding-left:23px;
	}
	.companiespublic-row-right {
		padding-left: 0px;
	}
	.companiespublic-logo {
		padding-top: 15px;
		width: 30%;
		margin-left: -6px;
	}
	.companiespublic-partner {
		width: 78%;
		padding-left: 22px;
	}
	.responsiv-row-right-title {
		padding-top: 15px;
	}
	.companiespublic-row {
		padding-left:15px;
	}
	.companiespublic-row-button {
		padding-left:0px;
	}
	.companiespublic-standardtitle {
		font-size: 16px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.companiesprofile-bottom {
		background-image: none;
		padding-top: 0px;
		.companiesprofile-bottom-left {
			margin-right: 0px;
		}
		.companiesprofile-bottom-right {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	
	.historypublic-nav {
		position: relative;
		margin-right: 0px;
	}
	
	.historypublic-right {
		margin-left: 0px;
	}
	
	
	.sticky-card-companiespublic {
	    top: -11.3rem;
	}
	.companiespublic-partner {
	  width: 65%;
		padding-bottom:15px;
	}
	.companiespublic-logo {
		padding-top: 0px;
		width: 90%;
		margin-left: -15px;
		padding-bottom: 10px;
	}
	.companiespublic-row-right {
		padding-left: 0px;
	}
	.responsiv-row-col{
		padding:0;
	}
	.companiespublic-row {
		padding-top: 0px;
		padding-left:0px;
	}
	.historypublic-row {
		padding-top: 0px;
		padding-left:0px;
	}
	.companiespublic-title {
	  font-size: 14px;
	  line-height: 22px;
	}
	.companiespublic-standardtitle {
		font-size: 14px;
		line-height: 22px;
	}
	.companiespublic-text {
	  font-size: 12px;
	}
	
	.companiespublic-row-button {
		padding-left:0px;
		margin-left:-30px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	
	.companiesprofile-bottom {
		padding-top: 110px;
		.companiesprofile-bottom-left {
			margin-right: 0px;
		}
		.companiesprofile-bottom-right {
			margin-left: 50px;
			margin-right: 0px;
			
			img {
				width: 75%;
				margin-bottom: 50px;
			}
		}
	}
	
	
	.historypublic-nav {
		margin-right: 20px;
	}
	
	.historypublic-right {
		margin-left: 20px;
	}
	
	
	.companiespublic-partner {
		width: 75%;
	}
	.companiespublic-row-right {
		padding-left: 0px;
	}
	
	.companiespublic-row {
		padding-left: 0px;
	}
	.historypublic-row {
		padding-left: 0px;
	}
	.companiespublic-logo {
		width: 35%;
		padding-bottom: 25px;
	}
	.companiespublic-row-button {
		padding-left:0px;
	}

}

@media (min-width: 992px) and (max-width: 1199.98px) {
	
	.companiesprofile-bottom {
		padding-top: 160px;
		.companiesprofile-bottom-left {
			margin-right: 0px;
		}
		.companiesprofile-bottom-right {
			margin-left: 50px;
			margin-right: 0px;
			
			img {
				width: 55%;
				margin-bottom: 50px;
			}
		}
	}
	
	.historypublic-nav {
		margin-right: 40px;
	}
	
	.historypublic-right {
		margin-left: 40px;
	}
	
	
	.companiespublic-partner {
		width: 70%;
	}
	.companiespublic-row-right {
		padding-left: 0px;
	}
	.companiespublic-row {
		padding-left: 75px;
		max-width: 420px;
	}
	.historypublic-row {
		max-width: 420px;
	}
	.companiespublic-logo {
		width: 25%;
		padding-bottom: 25px;
	}
	.companiespublic-row-button {
		padding-left:0px;
	}
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
	.companiesprofile-bottom {
		padding-top: 200px;
		.companiesprofile-bottom-right {
			margin-left: 90px;
			margin-right: 175px;
		}
	}
	
}
