.landingmarkt-nav-container {
	padding-top: 15px;
	padding-bottom: 10px;
	background-color: #edeaea;
	width: 100%;
	.signin-btn-landingmarkt {
		background-color: transparent!important;
		padding: 0;
		.anmelden-logo {
		  height: auto;
		  width: 17px;
		}
	}
	.link-center {
		display: inline-flex;
		align-items:center;
	}
	.addBike-btn-landingmarkt {
		height: 39.93px;
	  width: 100px;
	  border-radius: 15px;
	  background-color: #00A441!important;
		padding-top: 6px;
		padding-left: 0px;
		padding-right: 0px;
		.addBike-logo {
		  height: 15.5px;
		  width: 68.38px;
		}
	}
	.landingmarkt-nav {
		height: 57.3px;
		align-items: center;
		.nav-item {
			height: 57.3px;
			padding-right: 15px;
			display: inline-flex;
			align-items:center;
			.dropdown .dropdown-menu {
				margin: .425rem 0 0;
			  padding: 0rem;
				border-radius: 8px;
				background-color: #FFFFFF;
				box-shadow: 0 7px 11px 0 rgba(0,0,0,0.18);
				border: none;
				.dropdown-item:hover{
					.anmelden-text{
						color: #515151;
						font-size: .9rem !important;
					}
					.signup-text{
						font-size: .9rem !important;
					}
					color: #515151 !important;
					background-color: rgba(0,0,0,0.05);
					transition: all 0.1s linear;
					border-radius: .125rem;
					box-shadow: 0 0 0 0 rgba(0,0,0,0.18),0 0 0 0 rgba(0,0,0,0.15);
				}
				.dropdown-item{
					.anmelden-text{
						color: #00A441;
						font-size: .9rem !important;
					}
					.signup-text{
						font-size: .9rem !important;
					}
					.signinmarkt-logo{
						height: 14px;
						width: 16px;
					}
				}

			}
			.nav-link {
				color: #30445B;
				text-align: center;
				padding: 0rem .5rem;
				height: 57.3px;
				display: inline-flex;
				align-items:center;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				.ebike-logo {
					height: 44px;
  				width: 177.29px;
				}
				&.active {
					&:after {
						content: "";
						height: 3px;
					  width: 60px;
					  background-color: #00A441;
						display: block;
						margin: 5px auto 12px;
						border-radius: 2px;
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.landingmarkt-nav-container {
		padding-top: 15px;
		padding-bottom: 10px;
		background-color: #edeaea;
		width: 100%;
		.signin-btn-landingmarkt {
			background-color: transparent!important;
			padding: 0;
			.anmelden-logo {
			  height: auto;
			  width: 17px;
			}
		}
		.borse-btn-header {
		  height: 40px;
		  width: 40px;
		  border-radius: 11px;
		  background-color: #00A441 !important;
		  margin: 0rem;
		  padding: 0;
		}
		.link-center {
			display: inline-flex;
			align-items:center;
		}
		.addBike-btn-landingmarkt {
			height: 39.93px;
		  width: 90px;
		  border-radius: 10px;
		  background-color: #00A441!important;
			padding: 0;
			margin: 0;
			.addBike-logo {
			  height: auto;
			  width: 68.38px;
				margin-top: -5px;
			}
		}
		.landingmarkt-nav {
			.nav-item {
				display: inline-flex;
				align-items:center;
				padding-right: 5px;
				.nav-link {
					display: inline-flex;
					align-items:center;
					.ebike-logo {
						height: 45px;
	  				width: auto;
					}
					&.active {
						&:after {
							height: 3px;
						  width: 60px;
							border-radius: 2px;
						}
					}
				}
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
