.landing-nav-container, .landing-nav-container-scroll {
	transition: padding-top 0.6s, background-color 0.6s;
	padding-right: 300px;
	.landing-nav {
		justify-content: flex-end !important;
		.nav-item {
			.nav-link {
				min-width: 116px;
				color: #30445B;
				text-align: center;
				//padding: 30px 10px 0;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				&.active {
					&:after {
						content: "";
						background: linear-gradient(90deg, rgba(0,198,250,1) 0%, rgba(3,96,236,1) 100%);
						width: 80px;
						height: 7px;
						display: block;
						margin: 5px auto 0px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

.landing-nav-container {
	padding-top: 55px;
}

.landing-nav-container-scroll {
	padding-top: 0px;
	background-color: #f2efef;
}

.landing-top {
	min-height: calc(100vh - 250px);
	.landing-top-left {
		padding-top: 60px;
		padding-right: 125px;
	}
	.landing-top-right{
		padding-left: 140px;
		padding-top: 60px;
		.lf-player-container {
			max-width: 300px;
		}
	}
}

.landing-bottom {
	max-height: calc(1650vh - 380px);
	background-image: url('../../media/images/historypublicbg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 20vw 65vh;
}





.landing-video-btn{

}

.pagetitle-landing {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
}

.normaltext-landing {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.profiletext-landing {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 12px;
}

.logo-landing{
	width: 112px;
	height: 101px;
}

.signin-btn-landing {
	width: 120px;
	height: 32px;
	border-radius: 16px;
	padding-top: 4px;
	padding-left: 0px;
	padding-right: 0px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
}

.signup-btn-landing {
	width: 120px;
	height: 36px;
	border-radius: 18px;
	padding-top: 6px;
	padding-left: 0px;
	padding-right: 0px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	margin-left: 0px;
}

.contact-btn-landing {
	width: 120px;
	height: 36px;
	border-radius: 18px;
	padding-top: 6px;
	padding-left: 0px;
	padding-right: 0px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	margin-left: 0px;
}

.landing-ib2b {
	width: 250px;
	height: auto;
}

.photo-landing {
  height: auto;
  width: 120px;
}



.landing-videoplayer {
		border-radius: 20px;
		box-shadow: 15px 10px 10px 0 rgba(16,27,79,0.2);
		background-color: #534d4d;
		padding: 15px;
}

.landing-video{
	padding-top: 80px;
}

.landing-trailer-col {
	padding-top:130px;
	padding-left:60px;
}

.landing-partner-logo {
	max-width: 60%;
	height: auto;
}

.landing-form {
	.md-form {
		margin-top: 9px;
		margin-bottom: 0px;
		padding-right: 10px;
		input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
			width: 260px;
			height: 40px;
			background-color: #FFFFFF;
			border-radius: 20px;
			border-bottom: 1px solid #ced4da;
			border-top: 1px solid #ced4da;
			border-left: 1px solid #ced4da;
			border-right: 1px solid #ced4da;
			color: #30445B;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 21px;
			padding: 19px 16px 8px 16px;
			&:focus{
				border-color: #30445b !important;
				border: 2px solid #30445b !important;
				border-bottom: 2px solid #30445b !important;
				box-shadow: none;
				&+label{
					color: #30445B;
				}
			}

		}
		label {
			opacity: 0.51;
			color: #30445B;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 13px;
			padding-left: 16px;
			top: 2px;
			&.active {
				transform: translateY(5px) scale(1);
				top: 0px;
			}
		}
	 }
}

.video-fotos {
	width: 200px;
	height: auto;
	border-radius: 8px;
	box-shadow: 10px 10px 10px 0 rgba(16,27,79,0.2);
}

.play-btn-landing{
	width: 35px;
	position: absolute;
	left: 110px;
	top: 50px;
}

.landing-contact-row {
	min-height: 300px;
	padding-left: 15px;
	padding-right: 15px;
}
.landing-footer-row {
	padding: 50px 15px 80px;
}
.landing-video-div {
	margin-top:65px;
}


.landing-video-container-row {
	padding-left: 15px;
	padding-right: 15px;
	min-height: 800px;
}
.landing-info-div {
	margin-top: 45px;
}

.row-landing{
	max-width: 350px;
	padding-top: 15px;
}

.row-landing-input{
	max-width: 550px;
}

.landing-video-nav-container{
	.landing-video-nav {
		.nav-item {
			width: 300px;
			.nav-link {
				display: flex;
				padding: .5rem 2rem;
				&.active {
					&:after {
						content: "";
						background: linear-gradient(90deg, #00c6fa 0%, #0360ec 100%);
						width: 7px;
						height: 80px;
						display: flex;
						margin: 5px auto 0px;
						border-radius: 4px;
						position: absolute;
						left: 0px;
						top: 20px;
					}
				}
			}
		}
	}
}

@media (max-width: 409.98px) {
	.landing-nav-container, .landing-nav-container-scroll {
		padding-right: 15px;
		.landing-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.5rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landing-nav-container {
		padding-top: 20px;
		.landing-nav {
			justify-content: end !important;
		}
	}

	.landing-top {
		.landing-top-left {
			padding-top: 0px;
			padding-right: 15px;
		}
		.landing-top-right {
			padding-left: 15px;
			padding-top: 20px;
		}
	}

	.contact-btn-landing {
		width: 85px;
	}
}

@media (min-width: 410px) and (max-width: 575.98px) {
	.landing-nav-container, .landing-nav-container-scroll {
		padding-right: 20px;
		.landing-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landing-nav-container {
		padding-top: 20px;
		.landing-nav {
			justify-content: end !important;
		}
	}

	.landing-top {
		.landing-top-right {
			padding-left: 12px;
			padding-top: 20px;
		}
	}


	.landing-bottom {
		background-image: none;
	}


	.landing-video-nav-container{
		.landing-video-nav {
			.nav-item {
				width: 140px;
				.nav-link {
					display: flex;
					padding: .5rem 0.7rem;
					&.active {
						&:after {
							width: 4px;
							height: 45px;
							border-radius: 2px;
							left: 0px;
							top: 15px;
						}
					}
				}
			}
		}
	}


	.landing-video-container-row {
		min-height: 200px;
	}


	.video-fotos {
		width: 120px;
		border-radius: 5px;
	}
	.play-btn-landing{
		width: 25px;
		height: 25px;
		position: relative;
		left: -75px;
		top: 20px;
	}
	.pagetitle-landing {
	  font-size: 18px;
	  line-height: 25px;
	}
	.normaltext-landing {
	  font-size: 12px;
	  line-height: 20px;
	}
	.landing-top-right{
		padding-left: 12px;
		padding-top: 20px;
	}
	.landing-ib2b {
		width: 220px;
	}
	.landing-video{
		padding-top: 20px;
	}
	.landing-trailer-col {
		padding-top: 0px;
		padding-left: 0px;
	}
	.landing-form {
		.md-form {
			input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
				width: 189px;
			}
		 }
	}
	.landing-contact-row {
		min-height: 200px;
	}
	.landing-footer-row {
		padding: 30px 0px 20px;
	}
	.photo-landing {
	  height: auto;
	  width: 80px;
	}

	.landing-partner-logo {
		max-width: 100%;
		height: auto;
	}
	.landing-video-div {
		margin-top:90px;
	}


	.landing-video-container-row {
		padding-left: 15px;
		padding-right: 15px;
	}
	.landing-info-div {
		margin-top: 85px;
	}
}

@media (max-width: 575.98px) {

	.landing-top {
		.landing-top-right {
			padding-left: 12px;
			padding-top: 20px;
		}
	}



	.landing-bottom {
		background-image: none;
	}
	.landing-video-container-row {
		min-height: 200px;
	}
	.landing-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landing-nav {
			justify-content: flex-end !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landing-nav-container-scroll {
		padding-right: 20px;
		.landing-nav {
			.nav-item {
				.nav-link {
					min-width: 60px;
					padding: .5rem 0.7rem;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 35px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}

	.landing-video-nav-container{
		.landing-video-nav {
			.nav-item {
				width: 140px;
				.nav-link {
					display: flex;
					padding: .5rem 0.7rem;
					&.active {
						&:after {
							width: 4px;
							height: 45px;
							border-radius: 2px;
							left: 0px;
							top: 15px;
						}
					}
				}
			}
		}
	}
	.video-fotos {
		width: 120px;
		border-radius: 5px;
	}
	.play-btn-landing{
		width: 25px;
		height: 25px;
		position: relative;
		left: -75px;
		top: 20px;
	}
	.pagetitle-landing {
	  font-size: 18px;
	  line-height: 25px;
	}
	.normaltext-landing {
	  font-size: 12px;
	  line-height: 20px;
	}

	.landing-ib2b {
		width: 220px;
	}
	.landing-video{
		padding-top: 20px;
	}
	.landing-trailer-col {
		padding-top: 0px;
		padding-left: 0px;
	}
	.landing-form {
		.md-form {
			input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
				width: 189px;
			}
		 }
	}
	.landing-contact-row {
		min-height: 200px;
	}
	.landing-footer-row {
		padding: 30px 0px 20px;
	}
	.photo-landing {
	  height: auto;
	  width: 80px;
	}

	.landing-partner-logo {
		max-width: 100%;
		height: auto;
	}
	.landing-video-div {
		margin-top:90px;
	}


	.landing-video-container-row {
		padding-left: 15px;
		padding-right: 15px;
	}
	.landing-info-div {
		margin-top: 85px;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.landing-bottom {
		max-height: calc(600vh - 280px);
		background-image: none;
	}
	.landing-video-nav-container{
		padding-top: 20px;
		.landing-video-nav {
			.nav-item {
				width: 130px;
				.nav-link {
					display: flex;
					padding: .5rem 0.7rem;
					&.active {
						&:after {
							width: 4px;
							height: 32px;
							border-radius: 2px;
							left: 0px;
							top: 15px;
						}
					}
				}
			}
		}
	}
	.video-fotos {
		width: 100px;
		border-radius: 5px;
	}
	.landing-nav-container {
		padding-right: 20px;
		padding-top: 20px;
		.landing-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
	.landing-nav-container-scroll {
		padding-right: 20px;

		.landing-nav {
			justify-content: center !important;
			.nav-item {
				.nav-link {
					min-width: 60px;
					span{
						font-size: 13px;
					}
					&.active {
						&:after {
							width: 30px;
							height: 5px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}

	.landing-top {
		.landing-top-right {
			padding-left: 12px;
			padding-top: 20px;
		}
	}


	.pagetitle-landing {
		font-size: 18px;
		line-height: 35px;
	}
	.normaltext-landing {
		font-size: 14px;
		line-height: 20px;
	}

	.landing-ib2b {
		width: 220px;
	}
	.landing-video{
		padding-top: 20px;
	}
	.landing-trailer-col {
		padding-top:75px;
		padding-left:0px;
	}
	.landing-form {
		.md-form {
			input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
				width: 365px;
			}
		 }
	}
	.landing-contact-row {
		min-height: 200px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.landing-footer-row {
		padding: 30px 80px 20px;
	}
	.photo-landing {
		height: auto;
		width: 80px;
	}

	.landing-partner-logo {
		max-width: 100%;
		height: auto;
	}
	.landing-video-div {
		margin-top:50px;
	}


	.landing-video-container-row {
		min-height: 100px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.landing-info-div {
		margin-top: 40px;
	}
	.play-btn-landing{
		width: 25px;
		height: 25px;
		position: relative;
		left: -63px;
		top: 14px;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.landing-nav-container, .landing-nav-container-scroll {
		padding-right: 150px;
	}
	.landing-nav-container {
		padding-top: 5px;
	}

	.landing-top {
		min-height: calc(100vh - 500px);
		.landing-top-left {
			padding-top: 0px;
			padding-right: 40px;
		}
		.landing-top-right {
			padding-left: 50px;
			padding-top: 0px;
			.lf-player-container {
				max-width: 200px;
			}
		}
	}
	.landing-form {
		.md-form {
			input[type=text].landing-input, input[type=password].landing-input, input[type=email].landing-input {
				width: 240px;
			}
		}
	}
	.contact-btn-landing {
		width: 85px;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.landing-nav-container, .landing-nav-container-scroll {
		padding-right: 180px;
	}
	.landing-nav-container {
		padding-top: 12px;
	}

	.landing-top {
		min-height: calc(100vh - 560px);
		.landing-top-left {
			padding-top: 0px;
			padding-right: 125px;
		}
		.landing-top-right {
			padding-left: 120px;
			padding-top: 0px;
			.lf-player-container {
				max-width: 200px;
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {
	.landing-nav-container, .landing-nav-container-scroll {
		padding-right: 230px;
	}
	.landing-nav-container {
		padding-top: 30px;
	}

	.landing-top {
		min-height: calc(100vh - 450px);
		.landing-top-left {
			padding-top: 0px;
			padding-right: 125px;
		}
		.landing-top-right {
			padding-left: 100px;
			padding-top: 0px;
			.lf-player-container {
				max-width: 250px;
			}
		}
	}
}
