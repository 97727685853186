.bike-card {
	background-image: url('../../media/images/markt/Group 27.png');
	background-color: #FFFFFF!important;
	background-size: 8% auto;
	background-position: right 10rem;
	background-repeat: no-repeat;
	min-height: 1217px;
  width: 100%;
  border-radius: 15px;
	margin-bottom: 1rem;
	.share-icon {
		position:absolute;
		top: -23px;
		right: 140px;
		color: #00A441;
	}
	.card-body {
		padding-top: 3rem;
		padding-left: 4rem;
		padding-right: 4rem;
		padding-bottom: 3rem;
		.bike-title-bikepage {
			//height: 34px;
		  width: 540px;
		  color: #00A441;
		  font-family: "Work Sans";
		  font-size: 24px;
		  font-weight: bold;
		  letter-spacing: 0.48px;
		  line-height: 21px;
		}
		.bike-img-small {
			height: 54px;
		  width: 79.89px;
		  border-radius: 15px;
		}
		.bike-img-big {
			height: auto;
		  width: 100%;
		  border-radius: 15px;
		}
		.bikepage-back-btn{
			height: auto;
		  width: 40px;
		}
		.kommentare-img {
			height: 24px;
  		width: 24px;
		}
		.kommentare-title {
			color: #00A441;
			font-family: "Work Sans";
			font-size: 22px;
			font-weight: 500;
			letter-spacing: 0.44px;
			line-height: 36px;
		}
		.line {
		  box-sizing: border-box;
		  height: 1px;
		  width: 100%;
		  border: 1px solid #B9B9B9;
		}
		.md-form {
			width: 100%;
			.textarea-bikepage {
				//overflow-y: scroll;
				height: 100px;
				resize: none;
				width: 100%;
				border-radius: 7px;
				background-color: #F1EFEF;
				padding: 15px;
			}
			textarea.md-textarea {
				border-bottom: 1px solid #F1EFEF;
				box-shadow: 0 8px 10px 0 rgba(0,0,0,0.07);
			}
	  }
		.comment-btn {
			height: 40px;
			width: 142px;
			border-radius: 13px;
			background-color: #00A441!important;
			padding: 0;
			.btn-text{
				color: #FFFFFF;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.58px;
				line-height: 16px;
			}
			.comment-icon{
				height: 16px;
				width: 16px;
			}
		}
		.favorite-btn {
			position: absolute;
			right: -90px;
			top: -50px;
			z-index: 1000;
			.add-favorite {
				color: #00A441;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: bold;
			  letter-spacing: 0.28px;
			  line-height: 26.37px;
			  text-align: right;
			}
			.favorite-icon {
				height: 27px;
				width: 19.51px;
			}
		}
		.rectangle-rahmengroese {
		  height: 22px;
		  border-radius: 5px;
		  background-color: #00A441;
			padding-left: 5px;
			padding-right: 5px;
			.text {
			  color: #FFFFFF;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: 500;
			  letter-spacing: 0.58px;
			  line-height: 16px;
			  text-align: center;
			}
		}
		.kategorie-text {
		  color: #00A441;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: bold;
		  letter-spacing: 0.28px;
		  line-height: 26.37px;
		}
		.preis-text {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 22px;
		  font-weight: bold;
		  letter-spacing: 0.44px;
		  line-height: 20px;
		}
		.sonstige-text {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0.28px;
		  line-height: 22px;
		}
		.sonstige-text-bold {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: bold;
		  letter-spacing: 0.28px;
		  line-height: 22px;
		}
		.user-foto {
			box-sizing: border-box;
			height: 51.34px;
			width: 51.34px;
			border: 2px solid rgba(48,68,91,0.25);
			border-radius: 50%;
			background-color: #FFFFFF;
		}
		.user-name {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 17px;
		}
		.user-datum {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 10px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 12px;
		}
		.user-text {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 20px;
		}
		.contact-btn-bikepage {
			height: 40px;
		  width: 119px;
			margin: 0;
			padding: 0;
		  border-radius: 13px;
		  background-color: #00A441!important;
			.contact-btn-text {
				color: #FFFFFF;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.58px;
				line-height: 16px;
				text-align: center;
			}
		}
		iframe {
		    border: 0px solid black;
		}
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.bikeb2b-card {
	background-image: url('../../media/images/markt/Group 27.png');
	background-color: #FFFFFF!important;
	background-size: 8% auto;
	background-position: right 10rem;
	background-repeat: no-repeat;
	min-height: 1217px;
  width: 100%;
  border-radius: 15px;
	margin-bottom: 1rem;
	.card-body {
		padding-top: 3rem;
		padding-left: 4rem;
		padding-right: 4rem;
		padding-bottom: 3rem;
		.bike-title-bikepage {
			height: 34px;
		  //width: 540px;
		  color: #00A441;
		  font-family: "Work Sans";
		  font-size: 20px;
		  font-weight: bold;
		  letter-spacing: 0.48px;
		  line-height: 21px;
		}
		.bike-img-small {
			height: auto;
		  width: 60px;
		  border-radius: 15px;
		}
		.bike-img-big {
			height: auto;
		  width: 100%;
		  border-radius: 15px;
		}
		.kommentare-img {
			height: 20px;
  		width: 20px;
		}
		.kommentare-title {
			color: #00A441;
			font-family: "Work Sans";
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0.44px;
			line-height: 36px;
		}
		.line {
		  box-sizing: border-box;
		  height: 1px;
		  width: 100%;
		  border: 1px solid #B9B9B9;
		}
		.md-form {
			width: 100%;
			.textarea-bikepage {
				//overflow-y: scroll;
				height: 100px;
				resize: none;
				width: 100%;
				border-radius: 7px;
				background-color: #F1EFEF;
				padding: 15px;
			}
			textarea.md-textarea {
				border-bottom: 1px solid #F1EFEF;
				box-shadow: 0 8px 10px 0 rgba(0,0,0,0.07);
				font-size: 14px;
			}
	  }
		.comment-btn {
			height: 40px;
			width: 142px;
			border-radius: 13px;
			background-color: #00A441!important;
			padding: 0;
			.btn-text{
				color: #FFFFFF;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.58px;
				line-height: 16px;
			}
			.comment-icon{
				height: 16px;
				width: 16px;
			}
		}
		.rectangle-rahmengroese {
		  height: 22px;
		  border-radius: 5px;
		  background-color: #00A441;
			padding-left: 5px;
			padding-right: 5px;
			.text {
			  color: #FFFFFF;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: 500;
			  letter-spacing: 0.58px;
			  line-height: 16px;
			  text-align: center;
			}
		}
		.kategorie-text {
		  color: #00A441;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: bold;
		  letter-spacing: 0.28px;
		  line-height: 26.37px;
		}
		.preis-text {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 18px;
		  font-weight: bold;
		  letter-spacing: 0.44px;
		  line-height: 20px;
		}
		.sonstige-text {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 14px!important;
		  font-weight: 500;
		  letter-spacing: 0.28px;
		  line-height: 22px;
		}
		.sonstige-text-bold {
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: bold;
		  letter-spacing: 0.28px;
		  line-height: 22px;
		}
		.user-foto {
			box-sizing: border-box;
			height: 51.34px;
			width: 51.34px;
			border: 2px solid rgba(48,68,91,0.25);
			border-radius: 50%;
			background-color: #FFFFFF;
		}
		.user-name {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 17px;
		}
		.user-datum {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 10px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 12px;
		}
		.user-text {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 20px;
		}
		.contact-btn-bikepage {
			height: 40px;
		  width: 119px;
			margin: 0;
			padding: 0;
		  border-radius: 13px;
		  background-color: #00A441!important;
			.contact-btn-text {
				color: #FFFFFF;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.58px;
				line-height: 16px;
				text-align: center;
			}
		}
		iframe {
		    border: 0px solid black;
		}
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.bike-card-respo {
	margin-top: 57px;
	.share-icon {
		position:absolute;
		top: 13px;
		right: 135px;
		color: #00A441;
	}
	.bike-title-bikepage {
		//height: 34px;
	  width: 100%;
	  color: #00A441;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: bold;
	  letter-spacing: 0.48px;
	  line-height: 24px;
	}
	.bikepage-back-btn{
		height: auto;
		width: 40px;
	}
	.bike-img-small {
		height: 48px;
		width: 76px;
		border-radius: 5px;
	}
	.bike-img-big {
		height: auto;
	  width: 100%;
	  border-radius: 10px;
	}
	.kommentare-img {
		height: 24px;
		width: 24px;
	}
	.kommentare-title {
		color: #00A441;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.44px;
		line-height: 36px;
	}
	.line {
	  box-sizing: border-box;
	  height: 1px;
	  width: 100%;
	  border: 1px solid #B9B9B9;
	}
	.md-form {
		width: 100%;
		margin-bottom: .6rem;
		.textarea-bikepage {
			height: 75px;
			resize: none;
			width: 100%;
			border-radius: 5px;
			background-color: #F1EFEF;
			padding: 10px;
		}
		textarea.md-textarea {
			border-bottom: 1px solid #F1EFEF;
			box-shadow: 0 8px 10px 0 rgba(0,0,0,0.07);
		}
  }
	.form-control {
		font-size: .8rem;
	}
	.comment-btn {
		height: 37px;
		width: 135px;
		border-radius: 8px;
		background-color: #00A441!important;
		padding: 0;
		margin: 0 0 20px 0;
		.btn-text{
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0.58px;
			line-height: 16px;
		}
		.comment-icon{
			height: 16px;
			width: 16px;
		}
	}
	.favorite-btn {
		position: absolute;
		right: 5px;
		top: 6px;
		.add-favorite {
			color: #00A441;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: bold;
		  letter-spacing: 0.28px;
		  line-height: 26.37px;
		  text-align: right;
		}
		.favorite-icon {
			height: 27px;
			width: 19.51px;
		}
	}
	.rectangle-rahmengroese {
	  height: 22px;
	  border-radius: 5px;
	  background-color: #00A441;
		padding-left: 5px;
		padding-right: 5px;
		.text {
		  color: #FFFFFF;
		  font-family: "Work Sans";
		  font-size: 14px;
		  font-weight: 500;
		  letter-spacing: 0.58px;
		  line-height: 16px;
		  text-align: center;
		}
	}
	.kategorie-text {
	  color: #00A441;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: bold;
	  letter-spacing: 0.28px;
	  line-height: 26.37px;
	}
	.preis-text {
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 20px;
	  font-weight: bold;
	  letter-spacing: 0.44px;
	  line-height: 16px;
	}
	.sonstige-text {
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0.28px;
	  line-height: 20px;
	}
	.sonstige-text-bold {
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: bold;
	  letter-spacing: 0.28px;
	  line-height: 22px;
	}
	.user-foto {
		box-sizing: border-box;
		height: 51.34px;
		width: 51.34px;
		border: 2px solid rgba(48,68,91,0.25);
		border-radius: 50%;
		background-color: #FFFFFF;
	}
	.user-name {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 17px;
	}
	.user-datum {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 10px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 12px;
	}
	.user-text {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 20px;
	}
	.contact-btn-bikepage {
		height: 37px;
		width: 135px;
		border-radius: 8px;
		background-color: #00A441!important;
		padding: 0;
		margin: 10px 0 0 0;
		.contact-btn-text {
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0.58px;
			line-height: 16px;
			text-align: center;
		}
	}
	iframe {
	    border: 0px solid black;
	}
}

.ebikeProductPhotos-small {
	.ebikeproduct-nav {
		.nav-item {
			.nav-link {
				opacity: 0.3;
				padding-left: 0;
				padding-top: .7rem;
				padding-right: .5rem;
				&.active {
					opacity: 1;
				}
			}
		}
	}
}

.bike-comment-card{
	min-height: 120px;
  width: 100%;
  border-radius: 20px 20px 20px 0;
  background-color: #FFFFFF;
  box-shadow: 0 8px 10px 0 rgba(0,0,0,0.07);
	padding: 15px;
	.comment-foto {
		box-sizing: border-box;
		height: 51.34px;
		width: 51.34px;
		border: 2px solid rgba(48,68,91,0.25);
		border-radius: 50%;
		background-color: #FFFFFF;
	}
	.comment-name {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 17px;
	}
	.comment-datum {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 10px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 12px;
	}
	.comment-text {
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 20px;
	}
}








@media (max-width: 575.98px) {
	.bike-comment-card{
		min-height: 90px;
	  width: 100%;
	  border-radius: 10px 10px 10px 0;
	  background-color: #FFFFFF;
	  box-shadow: 0 8px 10px 0 rgba(0,0,0,0.07);
		padding: 10px;
		.comment-foto {
			box-sizing: border-box;
			height: 45px;
			width: 45px;
			border: 1px solid rgba(48,68,91,0.25);
			border-radius: 50%;
			background-color: #FFFFFF;
		}
		.comment-name {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 12px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 15px;
		}
		.comment-datum {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 9px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 10px;
		}
		.comment-text {
		  color: #30445B;
		  font-family: "Work Sans";
		  font-size: 12px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 18px;
		}
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bike-card-respo {
		margin-top: 74px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
