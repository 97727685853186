
.side-nav-menu-b2b {
	.deep-purple.darken-4 {
	  background-color: transparent!important;
	}
	.side-nav {
	  position: fixed;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100vh;
	  background-color: transparent!important;
	  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
	  border-radius: 0;
		padding:0;
		z-index: 1010;
		a {
			line-height: 13px!important;
			font-size: .8rem!important;
		}
		.scrollbar-container {
		  position: relative;
		  height: 100%;
		  overflow: -moz-hidden-unscrollable !important;
		}
		.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
		  display: none!important;
		  background-color: transparent;
		}
	}

	#sidenav-overlay {
	  position: fixed;
	  top: 0;
	  right: 0;
	  left: 0;
	  z-index: 1007;
	  height: 100vh;
	  background-color: rgba(0,0,0,0.2);
	  will-change: opacity;
	}

}

.landing-bikeparts-logo {
  height: 72px;
  width: auto;
}

.landing-boerse-logo {
  height: 72px;
  width: auto;
}



@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
