
.bodywrapper {
	min-height: 84vh;
	padding-top: 80px;
	background-color: #f2efef;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.bodywrapper2 {
	min-height: 100vh;
	padding-top: 80px;
	background-image: url('../../media/images/markt/profil/Group 0.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.bodywrapper3 {
	min-height: 84vh;
	padding-top: 80px;
	background-color: #f2efef;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.bodywrapper4 {
	min-height: 84vh;
	padding-top: 80px;
	background-color: #f2efef;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}


.logo {
	width: 112px;
	height: 101px;

}




@media (max-width: 575.98px) {
	.bodywrapper {
		min-height: 100vh;
		padding-top: 10.5rem;
		background-image: none;
	}
	.bodywrapper3 {
		padding-top: 4.8rem;
		background-image: none;
	}
	.bodywrapper2 {
		min-height: 100vh;
		padding-top: 0rem;
		background-size: auto 100%;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 10.5rem;
		background-image: none;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		min-height: 100vh;
		padding-top: 3rem;
		background-image: none;
	}
	.bodywrapper3 {
		padding-top: 4.5rem;
		background-image: none;
	}
	.bodywrapper2 {
		background-size: auto 100%;
		padding-top: 0px;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 10.5rem;
		background-image: none;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		min-height: 100vh;
		padding-top: 9.5rem;
	}
	.bodywrapper2 {
		background-size: auto 100%;
		padding-top: 0px;
	}
	.bodywrapper3 {
		padding-top: 4.5rem;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 10.5rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
	.bodywrapper3 {
		padding-top: 4.5rem;
	}
	.bodywrapper2 {
		background-size: auto 100%;
	}
	.bodywrapper4 {
		padding-top: 10.5rem;
	}
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
	.bodywrapper {
		padding-top: 5.5rem;
	}
	.bodywrapper3 {
		padding-top: 5.5rem;
	}
	.bodywrapper2 {
		background-size: auto 100%;
	}
	.bodywrapper4 {
		padding-top: 5.5rem;
	}
}
