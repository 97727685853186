.logo-aboutus{
  padding-top: 0px;
  max-width: 90%;
height: auto;
}

.aboutus-ib2bicon{
  width: 50%;
}

.aboutus-title{
  height: 32px;
  width: 316px;
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 10px;
}

.aboutus-text{
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 22px;
  padding-top: 20px;
}

.aboutus-text-read{
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 22px;
  padding-top: 20px;
}

.aboutus-ratingtext{
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: bold;
}

.aboutus-ratingcompany{
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 16px;
  text-align: right;
}

.aboutus-normaltext{
  height: 94px;
  width: 251px;
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24.78px;
}

.ratinglogoother{
  width: 10.5rem;
  height: auto;
  margin-top: -84px;
  margin-left: -27px;
}

.ratingstarsother{
  width: 6.5rem;
  height: auto;
  margin-top: -189px;
  margin-left: 31px;
}

.ratingbackground{
  z-index: 5;
}

.ratingstars{
  z-index: 10;
}

.photo-aboutus {
  height: 5.5rem;
  width: 5.5rem;
  min-width: 5.5rem;
  border-radius: 50%;
}

.profile-image-aboutus {
  width: 148px;
  height: 148px;
  border-radius:50%;
}

.profiletext-aboutus {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 12px;
}

.about-ratingstars{
  padding-left: 0;
}

.aboutUs-top {
	min-height: 230px;
	background-color: #FFFFFF;
  padding-top:20px;
}

.aboutUs-middle {
  min-height: 385px;
	background-color: #f2efef;
  padding-top:30px;
}

.aboutUs-bottom {
  min-height: 230px;
	background-color: #FFFFFF;
  padding-top:30px;
}

.aboutUs-All {
  margin-top: -230px;
  height: 141px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.82) 42.21%, rgba(255,255,255,0.96) 59.49%, #FFFFFF 100%);
}

.about-netzwerk-title {
	font-size: 16px;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 20px;
}

.about-netzwerk-text{
	font-family: "Source Sans Pro";
  font-size: 14px;
}

.about-netzwerk-ib2b {
  width: 50%
}


@media (max-width: 575.98px) {
  .aboutUs-top {
  	min-height: 130px;
    padding-top:25px;
    padding-bottom: 40px;
  }
  .about-netzwerk-ib2b {
  	margin-bottom: 20px;
    width: 92%
  }
  .logo-aboutus{
    padding-top: 15px;
  }

  .aboutus-text{
    font-size: 11px;
    line-height: 18px;
    padding-top: 12px;
  }

  .aboutus-ib2bicon{
    width: 85%;
  }

  .aboutus-title{
    height: 27px;
    font-size: 20px;
    padding-top: 5px;
    width: 100px;
  }

  .aboutus-ratingtext{
    font-size: 18px;
    padding-left: 0px;
  }

  .aboutus-text-read{
    font-size: 12px;
    line-height: 22px;
    padding-top: 20px;
    padding-left: 18px;
  }

  .aboutus-ratingcompany{
    font-size: 14px;
  }

  .about-ratingstars{
    padding-left: 0px;
  }

  .photo-aboutus {
    height: 4.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
  }

  .ratinglogoother{
    width: 8rem;
    margin-top: -89px;
    margin-left: -9px;
  }

  .ratingstarsother{
    width: 5.5rem;
    margin-top: -91px;
    margin-left: 43px;
  }

  .profile-image-aboutus {
    width: 115px;
    height: 115px;
  }

  .ratingbackground{
    padding-left: 14px;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .logo-aboutus{
    padding-top: 20px;
    margin-left: -15px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .aboutus-ratingtext{
    font-size: 18px;
  }
  .logo-aboutus{

    padding-top: 15px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .aboutus-ratingtext{
    width: 358px;
    font-size: 18px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
