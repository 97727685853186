.bodywrapper {
	min-height: 100vh;
	padding-top: 13.2rem;
	background-color: #f2efef;
}

.bodywrapper2 {
	min-height: 100vh;
	//background-image: url('../../media/images/Group 15.png');
	background-color: #FFFFFF;
	background-size: 20% auto;
	background-repeat: no-repeat;
	background-position: left bottom;
	padding-top: 13.2rem;
}

.page-title {
	font-size: 1.5rem;
	text-align: center;
	margin-top: 2rem;
}




@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 10rem;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 10rem;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 12rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 12rem;
	}
}
