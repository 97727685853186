.footer-nav {
	.nav {
		.nav-item {
			.nav-link {
				font-size: 12px;
				padding-bottom: 0;
			}
		}
	}
}
