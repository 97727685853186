.main-page {

}

.bodywrapper{

}

.list-card {
  min-height: 319px;
}

.title-text {
  height: 37px;
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.normal-text {
  height: 57px;
  width: 460px;
  color: #828282;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.card-text {
  height: 20px;
  width: 73.42px;
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.b2b-icon {
  height: auto;
  width: 250px;
}

.company-card {
  height: 99px;
  width: 176px;
  border-radius: 20px;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 8px;
  background-color: #F2EFEF;
}
.current {
  background-color: #FFFFFF;
}

.row-width {
  width: 176px;
  padding-left: 12px;
  padding-right: 8px;
}

.img-profile {
  height: auto;
  width: 75px;
  border-radius: 10px;
}

.currentcompany-nav-container{
	.currentcompany-nav {
		.nav-item {
			//width: 300px;

			.nav-link {
				display: flex;
				padding: 1.5rem 1rem;
				&.active {
					&:after {
						content: "";
						background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;
						width: 100px;
						height: 7px;
						display: flex;
						margin: 5px auto 0px;
						border-radius: 4px;
						position: absolute;
						left: 50px;
						top: 120px;
					}
				}
			}
		}
	}
}



@media (max-width: 575.98px) {
  .b2b-icon {
    height: auto;
    width: 145px;
  }
  .title-text {
    font-size: 12px;
    text-align: center;
  }
  .current {
    background-color: #FFFFFF;
    height: 84px;
    width: 100%;
    border-radius: 10px;
    .img-profile {
      height: auto;
      width: 60px;
      border-radius: 5px;
    }
    .card-text {
      height: 20px;
      width: 73.42px;
      color: #30445B;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  .currentcompany-nav-container{
    position: fixed!important;
    bottom: 3%!important;
    left: 50%;
    transform: translate(-50%, 0%);
  	.currentcompany-nav {

  		.nav-item {
  			width: 40px;
        height: 60px;
        margin-left: 15px;
        margin-right: 15px;
  			.nav-link {
  				width: 40px;
          height: 60px;
  				padding: 0 10px 0 10px!important;

  				&.active {
  					&:after {
              content: "";
              background: #ffffff !important;
              width: 30px;
              height: 2px;
              display: flex;
              margin: 5px auto 0px;
              border-radius: 1px;
              position: absolute;
              left: 5px;
              top: 40px;
  					}
  				}
  			}
  		}

  	}
  }


}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
