.blog-collapse-btn {
  margin: 5px 0 0 0;
  text-transform: unset !important;
  span {
    margin-right: 5px!important;
    font-family: "Work Sans";
    color: #000000;
    font-size: 14px;
  }
  img {
    width: 12px;
    height: auto;

  }
}
.blog-shadow {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 100%;
  height: 45px;
  z-index: 10;
}
.blog-card-btn-b2b{
  width: 150px;

}
.collapse-btn-text-productpage {
  display: inline-block;
  font-family: "Work Sans";
  font-size: 12px;
  margin-right: 8px;
}
.news-card-b2b {
  .card-body {
    min-height: 1px;
    padding: 1.25rem;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  background-image: url('../../../media/images/b2b/Group 26.png');
	background-size: 25% auto;
	background-position: right bottom;
	background-repeat: no-repeat;
	max-height: 590px;
  min-height: 150px;
	width: 100%;
	border-radius: 20px;
	background-color: #FFFFFF;
  .group_5 {
  	height: auto;
    width: 55%;
  }
  .newpost-1 {
    //height: 58.84px;
    width: 240.05px;
    color: #292929;
    font-family: "Work Sans";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.55px;
  }
  .newpost-2 {
    //height: 40.11px;
    width: 240.05px;
    color: #FF9500;
    font-family: "Work Sans";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16.47px;
  }
}
b {
  color: #FF9500;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.myProfile-symbol-text1 {
  //height: 58.84px;
  width: 240.05px;
  color: #292929;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17.55px;
}
.myProfile-symbol-text2 {
  //height: 40.11px;
  width: 240.05px;
  color: #FF9500;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16.47px;
}

.myProfile-symbol-img {
	height: auto;
  width: 100%;
}

.fotos-done {
	width: 115px;
	height: 40px;
	border-radius: 15px;
	background-color: #FF9500 !important;
	color: #FFFFFF!important;
	padding: 0;
	text-transform: none;
  font-weight: bold!important;
	font-size: 16px;
}

.companyanzeige-card {
  height: 234px;
  width: 175px;
  border-radius: 20px;
  background-color: #FFFFFF;
	.card-body {
		padding:0;
    .profile-header-bg-b2b {
      width: 100%;
      min-height: 117px;
      background-size: 100% auto;
    	background-repeat: no-repeat;
    	background-position: top center;
      //background-size: cover;
      border-radius: 20px 20px 0 0;
    }
    .profile-image {
      position: absolute;
      left: 27%;
      bottom: 20px;
      z-index: 1000;
      width: 77px;
      height: 77px;
      border: 1px solid #fff;
    }
    .profile-header-bg-b2b {
      width: 100%;
      min-height: 117px;
      background-size: 115% 100%;
      background-repeat: no-repeat;
      background-position: top center;
      border-radius: 20px 20px 0 0;
      margin-bottom: 15px;
    }
    .adresse-text {
      width: 134px;
      height: 60px;
      color: #919191;
      font-family: "Work Sans";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      display: inline-block;
    }
    .username {
      height: 25px;
      //width: 134px;
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      display: inline-block;
    }
	}
}

.product-back-btn {
  height: 28px;
  width: 73px;
  border-radius: 20px;
  margin: 0;
  background-color: #FFFFFF!important;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 1px;
  color: #FF9500!important;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 16px;
  img {
    height: auto;
    width: 13.5px;
    padding-right: 6px;
    margin-top: -2px;
  }
}

.product-description-card {
  min-height: 102px;
  width: 557px;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding-top: 19px;
  padding-left: 15px;
  .product-description {
  	width: 350px;
  	color: #30445B;
  	font-family: "Work Sans";
  	font-size: 14px;
  	font-weight: 500;
  	letter-spacing: 0;
  	line-height: 16px;
  	display: inline-block;
  }
}
.product-description-card-small {
  margin-top: 20px;
  height: 102px;
  width: 340px;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding-top: 19px;
  padding-left: 15px;
  .product-description {
    width: 225px;
    color: #30445B;
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    display: inline-block;
  }

}

@media (max-width: 575.98px) {
  .product-description-card {
    min-height: 102px;
    width: 100%;
    border-radius: 0 0 15px 15px;
    background-color: #FFFFFF;
    padding: 10px 20px 0 20px;
    .product-description {
    	width: 100%;
    	color: #30445B;
    	font-family: "Work Sans";
    	font-size: 13px;
    	font-weight: 500;
    	letter-spacing: 0;
    	line-height: 16px;
    	display: inline-block;
    }
  }
  .product-description-card-small {
    margin-top: 20px;
    height: 102px;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding-top: 19px;
    padding-left: 15px;
    .product-description {
      width: 100%;
      color: #30445B;
      font-family: "Work Sans";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      display: inline-block;
    }

  }
  .myProfile-symbol-text1 {
    font-size: 26px;
  }
  .myProfile-symbol-text2 {
    font-size: 26px;
  }
  .fotos-done {
    width: 65px;
    height: 35px;
    border-radius: 8px;
    margin-top: 0;
    margin-left: 5px;
  }
  .companyanzeige-card {
    height: 234px;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
  	.card-body {
  		padding:0;
      .profile-header-bg-b2b {
        width: 100%;
        min-height: 117px;
        background-size: 100% auto;
      	background-repeat: no-repeat;
      	background-position: top center;
        //background-size: cover;
        border-radius: 20px 20px 0 0;
      }
      .profile-image {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 50%;
        z-index: 1000;
        width: 30%;
        height: auto;
        border: 1px solid #fff;
      }
      .profile-header-bg-b2b {
        width: 100%;
        min-height: 117px;
        background-size: 115% 100%;
        background-repeat: no-repeat;
        background-position: top center;
        border-radius: 20px 20px 0 0;
        margin-bottom: 15px;
      }
      .adresse-text {
        width: 134px;
        height: 60px;
        color: #919191;
        font-family: "Work Sans";
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        display: inline-block;
      }
      .username {
        height: 25px;
        //width: 134px;
        color: #30445B;
        font-family: "Work Sans";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        display: inline-block;
      }
  	}
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .product-description-card {
    min-height: 102px;
    width: 100%;
    border-radius: 0 0 15px 15px;
    background-color: #FFFFFF;
    padding: 5px 5px 0 5px;
    .product-description {
    	width: 100%;
    	color: #30445B;
    	font-family: "Work Sans";
    	font-size: 14px;
    	font-weight: 500;
    	letter-spacing: 0;
    	line-height: 16px;
    	display: inline-block;
    }
  }
  .product-description-card-small {
    margin-top: 20px;
    height: 102px;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding-top: 19px;
    padding-left: 15px;
    .product-description {
      width: 100%;
      color: #30445B;
      font-family: "Work Sans";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      display: inline-block;
    }

  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .product-description-card {
    min-height: 102px;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding-top: 19px;
    padding-left: 15px;
    .product-description {
    	width: 100%;
    	color: #30445B;
    	font-family: "Work Sans";
    	font-size: 14px;
    	font-weight: 500;
    	letter-spacing: 0;
    	line-height: 16px;
    	display: inline-block;
    }
  }
  .product-description-card-small {
    margin-top: 20px;
    height: 102px;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding-top: 19px;
    padding-left: 15px;
    .product-description {
      width: 100%;
      color: #30445B;
      font-family: "Work Sans";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      display: inline-block;
    }

  }

}
