.benach-form {
  .form-title {
    font-family: "Work Sans";
    font-size: 22px;
    line-height: 55px;
    font-weight: 600;
  }
  .form-check{
    padding: 0;
    height: 35px;
  }
  .form-check-input[type="radio"] + label{
    color: #30445B;
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }
  .form-check-input[type="radio"].with-gap:checked + label::before{
    border: 2px solid #00A441;
  }
  .form-check-input[type="radio"].with-gap:checked + label::after{
    border: 2px solid #00A441;
    background-color: #00A441;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00A441;
    background-color: #00A441;
  }
  .custom-control{
    padding-left: 35px;
    height: 35px;
  }
  .custom-control-label{
    color: #30445B;
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }
  .custom-control-label::after {
    top: 11px;
    left: -30px;
  }
  .custom-control-label::before {
    top: 11px;
    left: -30px;
  }
}

.custom-control-label::after {
  top: 11px;
  left: -25px;
}
.custom-control-label::before {
  top: 11px;
  left: -25px;
}



@media (max-width: 575.98px) {
  .benach-form {
    .row{
      display: inline-flex;
    }
    .form-title {
      font-size: 17px;
      line-height: 25px;
    }
    .form-check{
      padding: 0;
      height: 45px;
      align-items: center;
      display: inline-flex;
    }
    .form-check-input[type="radio"] + label{
      font-size: 11px;
      line-height: 14px;
    }
    .form-check-input[type="radio"].with-gap:checked + label::before{
      border: 2px solid #00A441;

    }
    .form-check-input[type="radio"].with-gap:checked + label::after{
      border: 2px solid #00A441;
      background-color: #00A441;

    }
    .form-check-input[type="radio"] + label::before{
      top: -4px;
    }
    .form-check-input[type="radio"] + label::after {
      top: -4px;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #00A441;
      background-color: #00A441;
    }
    .custom-control{
      padding-left: 35px;
      height: 35px;
      display: inline-flex;
      align-items: center;
    }
    .custom-control-label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }
    .custom-control-label::after {
      top: 0px;
      left: -30px;
    }
    .custom-control-label::before {
      top: 0px;
      left: -30px;
    }
  }

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .benach-form {
    .form-title {
      font-family: "Work Sans";
      font-size: 22px;
      line-height: 55px;
      font-weight: 600;
    }
    .form-check{
      padding: 0;
      height: 35px;
    }
    .form-check-input[type="radio"] + label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
    .form-check-input[type="radio"].with-gap:checked + label::before{
      border: 2px solid #00A441;
    }
    .form-check-input[type="radio"].with-gap:checked + label::after{
      border: 2px solid #00A441;
      background-color: #00A441;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #00A441;
      background-color: #00A441;
    }
    .custom-control{
      padding-left: 35px;
      height: 35px;
    }
    .custom-control-label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }
    .custom-control-label::after {
      top: 0px;
      left: -30px;
    }
    .custom-control-label::before {
      top: 0px;
      left: -30px;
    }
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .benach-form {
    .form-title {
      font-family: "Work Sans";
      font-size: 22px;
      line-height: 55px;
      font-weight: 600;
    }
    .form-check{
      padding: 0;
      height: 35px;
    }
    .form-check-input[type="radio"] + label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
    .form-check-input[type="radio"].with-gap:checked + label::before{
      border: 2px solid #00A441;
    }
    .form-check-input[type="radio"].with-gap:checked + label::after{
      border: 2px solid #00A441;
      background-color: #00A441;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #00A441;
      background-color: #00A441;
    }
    .custom-control{
      padding-left: 35px;
      height: 35px;
    }
    .custom-control-label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }
    .custom-control-label::after {
      top: 0px;
      left: -30px;
    }
    .custom-control-label::before {
      top: 0px;
      left: -30px;
    }
  }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .benach-form {
    .form-title {
      font-family: "Work Sans";
      font-size: 22px;
      line-height: 55px;
      font-weight: 600;
    }
    .form-check{
      padding: 0;
      height: 35px;
    }
    .form-check-input[type="radio"] + label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
    .form-check-input[type="radio"].with-gap:checked + label::before{
      border: 2px solid #00A441;
    }
    .form-check-input[type="radio"].with-gap:checked + label::after{
      border: 2px solid #00A441;
      background-color: #00A441;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #00A441;
      background-color: #00A441;
    }
    .custom-control{
      padding-left: 35px;
      height: 35px;
    }
    .custom-control-label{
      color: #30445B;
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }
    .custom-control-label::after {
      top: 0px;
      left: -30px;
    }
    .custom-control-label::before {
      top: 0px;
      left: -30px;
    }
  }

}
