.landingpage-text {
  color: #00A441;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 96px;
}

.e-bikeborse-logo-first {
  height: 96px;
  width: auto;
}

.landingpage-text-kontakt {
  color: #00A441;
  font-family: "Work Sans";
  font-size: 78px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 96px;
}

.e-bikeborse-logo-first-kontakt {
  height: 96px;
  width: auto;
}

.e-bikeborse-logo-first-eigene {
  height: 86px;
  width: auto;
}

.landingpage-text-eigene {
  color: #00A441;
  font-family: "Work Sans";
  font-size: 56px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 86px;
}




@media (max-width: 575.98px) {
  .landingpage-text {
    font-size: 49px;
    line-height: 68px;
  }
  .e-bikeborse-logo-first {
    height: 68px;
  }
  .landingpage-text-kontakt {
    font-size: 38px;
    line-height: 55px;
  }
  .e-bikeborse-logo-first-kontakt {
    height: 55px;
  }
  .e-bikeborse-logo-first-eigene {
    height: 55px;
  }

  .landingpage-text-eigene {
    font-size: 38px;
    line-height: 55px;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
