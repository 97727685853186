.price-page-title {
	color: #30445B;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px
}
.price-page-text {
	font-size: 12px;
	text-align: center;
	margin-top: 15px;
	margin-right: 0px;
	margin-bottom: 35px;
	margin-left: 0px;
}
.price-page-haufig-text {
	font-size: 12px;
	text-align: left;
	margin-top: 10px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
}
.price-page-haufig-text-title {
	font-weight: bold;
	font-size: 15px;
	text-align: left;
	margin-top: 10px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
}

.price-page-haufig-text-title-first{
		font-weight: bold;
		font-size: 19px;
		text-align: left;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 20px;
		margin-left: 0px;
}
.price-tabs {
	height: 46px;
	width: 320px;
	border-radius: 15px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	margin: 0 auto;
	.nav-item {
		width: 50%;
		height: 100%;
		text-align: center;
		.nav-link {
			color: #515151;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			text-align: center;
			text-transform: uppercase;
			padding: 14px;
			&.active {
				height: 100%;
				border-radius: 15px;
				background-color: #FF9500;
				color: #FFFFFF;
				font-weight: bold;
			}
		}
	}
}

.price-card-signup-btn {
	border-radius: 15px;
	border: 1px solid #30445B;
	font-family: "Work Sans";
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 15px;
	color: #ffffff;
	background-color: #FF9500;
	border: 0;

}

.price-card {
	border-radius: 20px;
	width: 70%;
	margin-top: 30px;
	margin-bottom: 30px;
	box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
	.price-card-signup-btn {
		border-radius: 15px;
		border: 1px solid #30445B;
		font-family: "Work Sans";
		font-size: 14px;
		color: #30445B;
		text-transform: uppercase;
		margin-top: 15px;

	}
	&.final{
		max-width: 259px;

	}
	&.active {
		margin-top: 15px;
		margin-bottom: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
		border: 2px solid rgb(255, 149, 0, 0.5);
		z-index: 10;
		.price-card-signup-btn {
			color: #ffffff;
			background-color: #FF9500;
			border: 0;

		}
	}


	.recommended {
		background-color: #03ECAB;
		color: #ffffff;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 13px;
		padding: 2px 10px;
	}

	h1 {
		font-size: 14px;
		font-weight: 600!important;
		margin-top: 20px;
		color: #30445B;
	}

	.price {
		color: #30445B;
		.currency {
			font-size: 20px;
		}
		.value {
			font-size: 48px;
			font-weight: 600;
			padding-left: 20px;
			padding-right: 20px;
		}
		.period {
			font-size: 12px;
		}
	}

	.func-list {
		list-style: none;
		padding-left: 0;
		text-align: left;
		margin-top: 30px;
		li {
			line-height: 30px;
			color: #30445B;
			font-size: 12px;
			&:before {
				content: "";
				display: inline-block;
				width: 14px;
				height: 14px;
				margin: 3px 6px -3px 0;
				background-image: url('../../media/newicons/check-mark-button.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&.func-plus {
				font-weight: 600;
				&:before {
					background-image: url('../../media/newicons/plus-button.png');
				}
			}
		}
	}

}

.link-text {
	color: #FF9500!important;
}
.link-text-markt {
	color: #FFFFFF!important;
}
.link-text-b2b {
	color: #FF9500!important;
}

.price-checkbox {
	margin-top: 12px;
	.form-check-input[type="checkbox"]+label {
		padding-left: 24px;
		font-weight: bold;
		&:before {
			background-image: url('../../media/newicons/checkBox.png');
			background-size: cover;
			border:0;
		}
	}

	.form-check-input[type="checkbox"]:checked+label:before {
		background-image: url('../../media/newicons/checkBoxChecked.png');
		background-size: cover;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		transform: none;
	}
}

.companies-ib2b-newcompany {
	margin-bottom: 40px;
	margin-top: 9px;
	width: 80%;
}

.price-row-padding {
	padding-top: 70px;
}

.price-row-padding2 {
	padding-top: 100px;
}

.price-back-btn{
	position: absolute;
	left: 20px;
	top: 101px;
	height: 40px;
	width: 40px;
}

@media (max-width: 575.98px) {
	.price-row-padding2 {
		padding-top: 205px;
	}
	.price-back-btn{
		position: absolute;
		left: 20px;
		top: 175px;
		height: 40px;
		width: 40px;
	}
	.price-tabs {
		height: 40px;
		width: 80%;
		border-radius: 15px;
		background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
		margin: 0 auto;
		.nav-item {
			width: 50%;
			height: 100%;
			text-align: center;
			.nav-link {
				color: #515151;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				text-align: center;
				text-transform: uppercase;
				padding: 9px;
				&.active {
					height: 100%;
					border-radius: 15px;
					background-color: #FF9500;
					color: #FFFFFF;
					font-weight: bold;
				}
			}
		}
	}

	.price-card {
		width: 100%;
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
	.companies-ib2b-newcompany {
		margin-bottom: 40px;
		margin-top: 9px;
		width: 100%;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.price-row-padding2 {
		padding-top: 205px;
	}
	.price-back-btn{
		position: absolute;
		left: 20px;
		top: 175px;
		height: 40px;
		width: 40px;
	}
	.price-card {
		width: 100%;
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.price-row-padding2 {
		padding-top: 205px;
	}
	.price-back-btn{
		position: absolute;
		left: 20px;
		top: 175px;
		height: 40px;
		width: 40px;
	}
	.price-card {
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
}
