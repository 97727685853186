.marktnews-nav-container, .marktnews-nav-container-scroll {
	transition: padding-top 0.6s, background-color 0.6s;
	//padding-right: 300px;

}

.marktnews-nav-container {
	padding-top: 35px;
}

.marktnews-nav-container-scroll {
	padding-top: 35px;
	background-color: #FFFFFF;
		#e-bikeborse-logo3{
			height: 75px;
			width: auto;
		}
		#e-bikeborse-logo3-text{
			font-size: 50px;
		  line-height: 75px;
		}

}
.marktnews-nav-container-borse {
	padding-top: 0px;
}

.marktnews-nav-container-scroll-borse {
	padding-top: 0px;
	background-color: transparent;
}


.fixed-top-marktnews {
	position: fixed;
	top: 0;
	z-index: 995;
	left: 0;
	right: 0;
}

.fixed-top-borse {
	position: absolute;
	top: 0px;
	z-index: 0;
	left: 0;
	right: 0;
}

.arrow-up {
  height: 8.45px;
  width: 14.69px;
}

.arrow-up-btn {
	height: 48px;
	width: 48px;
	background-color: #F1F1F1!important;
  padding: 0;
  position: absolute !important;
  top: 50%;
  right: 13%;
	border-radius: 50%;
}
.arrow-up-btn-borse {
	height: 48px;
	width: 48px;
	background-color: #FFFFFF!important;
  padding: 0;
  position: absolute !important;
  top: -27%;
  right: -6%;
	border-radius: 50%;
}




@media (max-width: 409.98px) {


}

@media (min-width: 410px) and (max-width: 575.98px) {

}

@media (max-width: 575.98px) {
	.marktnews-nav-container-scroll {
			#e-bikeborse-logo3{
				height: 50px;
			}
			#e-bikeborse-logo3-text{
				font-size: 36px;
			  line-height: 50px;
			}

	}

	.arrow-up-btn {
		height: 43px;
		width: 43px;
		background-color: #F1F1F1!important;
	  padding: 0;
	  position: absolute !important;
	  top: 62%;
	  right: 1%;
		border-radius: 50%;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.arrow-up-btn {
		top: 60%;
		right: 13%;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.arrow-up-btn {
		top: 60%;
		right: 13%;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.fixed-top-borse {
		position: absolute;
		top: -70px;
		z-index: 0;
		left: 45%;
		right: 45%;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
