
.bodywrapper {
	height: 100vh;
	//padding-top: 8.2rem;
	background-color: #f2efef;
	//background-image: url('../../media/images/b2b/Group 1.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	//background-attachment: fixed;
}
.bodywrapper2 {
	min-height: 100vh;
	background-color: #f2efef;
	background-image: url('../../media/images/markt/Group 33.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	padding-top: 36vh;
}
.bodywrapper3 {
	height: 100vh;
	//padding-top: 180px;
	background-color: #ffffff;
	//background-image: url('../../media/images/markt/Group 33.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;

}
.bodywrapper4 {
	min-height: 100vh;
	//padding-top: 180px;
	background-color: #F1F1F1;
	//background-image: url('../../media/images/markt/Group 33.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.bodywrapper5 {
	min-height: 100vh;
	//padding-top: 180px;
	background-color: #F1F1F1;
	//background-image: url('../../media/images/markt/Group 33.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}
.bodywrapper6 {
	min-height: 100vh;
	//padding-top: 180px;
	background-color: #F1F1F1;
	//background-image: url('../../media/images/markt/Group 33.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}


.logo {
	width: 112px;
	height: 101px;

}

@media (max-width: 575.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
		background-image: none;
	}
	.bodywrapper2 {
		height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		//background-color: #f2efef;
		background-image: url('../../media/images/b2b/landingrespo/Group 4 fent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper3 {
		height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 12vh;
		background-color: #292929;
		//background-image: url('../../media/images/markt/Group 33.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper5 {
		height: 100vh;
		padding-top: 13vh;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper6 {
		min-height: 10vh;
		height: 85vh;
		padding-top: 5vh;
		background-color: #FFFFFF;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		padding-top: 3rem;
		background-image: none;
	}
	.bodywrapper2 {
		min-height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 4 fent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper3 {
		height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-color: #292929;
		//background-image: url('../../media/images/markt/Group 33.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper5 {
		height: 100vh;
		padding-top: 13vh;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper6 {
		min-height: 10vh;
		height: 85vh;
		padding-top: 5vh;
		background-color: #FFFFFF;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
	.bodywrapper2 {
		min-height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 4 fent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper3 {
		height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 13vh;
		background-color: #292929;
		//background-image: url('../../media/images/markt/Group 33.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper5 {
		height: 100vh;
		padding-top: 13vh;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper6 {
		min-height: 10vh;
		height: 85vh;
		padding-top: 5vh;
		background-color: #FFFFFF;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.bodywrapper {
		padding-top: 4.5rem;
	}
	.bodywrapper2 {
		min-height: 100vh;
		padding-top: 36vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 4 fent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper3 {
		height: 100vh;
		padding-top: 13vh;
		padding-left: 22px;
		padding-right: 22px;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper4 {
		min-height: 100vh;
		padding-top: 13vh;
		background-color: #292929;
		//background-image: url('../../media/images/markt/Group 33.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper5 {
		height: 100vh;
		padding-top: 13vh;
		background-image: url('../../media/images/b2b/landingrespo/Group 5 lent.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper6 {
		min-height: 10vh;
		height: 85vh;
		padding-top: 5vh;
		background-color: #FFFFFF;
	}
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
	.bodywrapper {
		padding-top: 5.5rem;
	}
}
