
.bodywrapper1 {
	min-height: 100vh;
	background-image: url('../../media/images/b2b/Group 9.png')!important;
	background-size: 63% 87%;
	background-repeat: no-repeat;
	background-position: right top;
}
.bodywrapper2 {
	min-height: 100vh;
	background-image: url('../../media/images/b2b/Group 10.png')!important;
	background-size: 87% 87%;
	background-repeat: no-repeat;
	background-position: right top;
}


.logo {
	width: 112px;
	height: 101px;

}

.pagetitle {
	color: #30445B;
	font-size: 23px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px;
	margin-top: 43px;
}


@media (max-width: 575.98px) {
	body {
		padding-top: 0;
	}

	.bodywrapper {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.bodywrapper1 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		background-size: 63% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
	.bodywrapper2 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		background-size: 87% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		background-size: 40% 100%;;
		background-repeat: no-repeat;
		background-position: right center;
	}
	.bodywrapper1 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		background-size: 63% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
	.bodywrapper2 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		background-size: 87% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	body {
		padding-top: 0;
	}
	.bodywrapper {
		background-size: 50% 100%;;
		background-repeat: no-repeat;
		background-position: right center;
	}
	.bodywrapper1 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		//background-size: 63% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
	.bodywrapper2 {
		min-height: 100vh;
		background-color: #ff9500;
		background-image: none !important;
		background-size: 87% 87%;
		background-repeat: no-repeat;
		background-position: left top;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	body {
		padding-top: 0;
	}
	.bodywrapper1 {
		background-size: 90% 90%;
	}
	.bodywrapper2 {
		background-size: 100% 90%;
	}
}

@media (min-width: 1200px) {

}
