.chatlist-card-b2b {
	height: calc(100vh - 8.2rem);
	border-radius: 20px;
	margin-bottom: 1rem;
	padding-bottom: 15px;
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		text-align: center;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-left: 1rem;
		padding-right: 1rem;
		overflow: hidden;
		.scrollbar {
			height: calc(100vh - 17.5rem);
		}
		.filter-text-green {
			color: #FF9500;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0;
			height: 28px;
			display: inline-block;
			width: 65px;
		}
		.filter-text-grau {
			opacity: 0.5;
			color: #101B4F;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			height: 28px;
			display: inline-block;
			width: 65px;
		}
		.filter-text-green-inactive {
			color: #FF9500;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0;
			height: 28px;
			display: inline-block;
			width: 75px;
		}
		.filter-text-grau-inactive {
			opacity: 0.5;
			color: #101B4F;
			font-family: "Work Sans";
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			height: 28px;
			display: inline-block;
			width: 75px;
		}
		.filter-icon {
			height: 26px;
			width: 26px;
		}
		.list-group {
			max-width: 298px;
			.list-group-item {
				padding: .35rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				border-bottom:0;
				color: #000;
				.photo {
					height: 46.4px;
					width: 46.4px;
				}
				.chat-name {
					color: #101B4F;
					font-size: 16px;
				}
				.chat-name-white {
					color: #ffff;
				}
				.user-online {
					box-sizing: border-box;
					border-radius: 50%;
				  height: 17.5px;
				  width: 17.5px;
				  border: 3px solid #FFFFFF;
				  background-color: #62EC03;
					position: absolute;
					top: -4px;
					right: -5px;
				}
				.user-offline {
					box-sizing: border-box;
					border-radius: 50%;
					height: 17.5px;
					width: 17.5px;
					border: 3px solid #FFFFFF;
					background-color: rgb(161, 161, 170);
					position: absolute;
					top: -4px;
					right: -5px;
				}
				p {
					color: #9B9B9B;
					margin-bottom: 0;
				}
				span{
					font-size: 12px;
					color: #353030;
				}
			}
			.list-group-item-2 {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				border-bottom:0;
				color: #edeaea;
				.chat-name {
					color: #101B4F;
					font-size: 16px;
				}
				.chat-name-white {
					color: #ffff;
				}
				p {
					color: #9B9B9B;
					margin-bottom: 0;
				}
				span{
					font-size: 12px;
					color: #353030;
				}
			}
		}
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.chathistory-card-b2b {
	height: calc(100vh - 8.2rem);
	background-color: transparent;
	border-radius: 20px;
	margin: 0;
	padding: 0;
}



.chat-messages-card-b2b {
  height: calc(100vh - 17rem);
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
	margin-top: 10px;
	padding-left: 15px;
	.card-body {
		padding: 0;
	}
		.empty-chat {
			text-align: center!important;
			height: calc(100vh - 17rem);
			.avatar {
				margin: 0 auto;
			}
	}
	.chat-history-b2b {
		//padding-bottom: 150px;
		.chat-msg {
			margin-bottom: 54px;
			.chat-msg-content {
				color: #ffffff;
				background-color: #FF9500;
				padding-top: .4rem;
				padding-bottom: .4rem;
				padding-right: .5rem;
				padding-left: .5rem;
				font-size: 1rem;
				border-top-left-radius: 0;
				border-top-right-radius: .6rem;
				border-bottom-right-radius: .6rem;
				border-bottom-left-radius: .6rem;
				.chat-sender-name {
					font-family: "Source Sans Pro";
					font-size: 10px;
					letter-spacing: 0;
					margin-bottom: 0.5rem;
				}
			}
			.chat-time {
				font-size: .7rem;
				color: #9B9B9B;
			}
			a {
				color: #ffffff;
			}

		}
		.chat-msg-self {
			.chat-msg-content {
				background-color: #788998;
				border-top-left-radius: .6rem;
				border-top-right-radius: 0;
				text-align: right;
				box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
			}
		}
	}
}

#chatfilter-b2b.switch {
	text-align: center;
}

#chatfilter-b2b.switch label .lever {
	width: 1.8rem;
	height: 1.2rem;
	background-color: #A2A3A8;
}

#chatfilter-b2b.switch label input[type="checkbox"]:checked + .lever::after {
	left: 0.7rem;
	background-color: #FF9500;
}

#chatfilter-b2b.switch label .lever::after {
	top: -0.13rem;
	border-radius: 1.5rem;
	width: 1.5rem;
	height: 1.5rem;
	background-color: #FF9500;
}


@media (max-width: 575.98px) {

}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
