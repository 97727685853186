.newspage-card-anzeige-respo {
	margin-top: 0px;
	margin-bottom: 10px;
	min-height: 75px;
	max-height: 85vh;
	width: 100%;
	border-radius: 0 0 20px 20px;
	background-color: #FFFFFF;
}
.form-footer-respo {
	height: 110px;
	align-items: center;
	.nav-item {
		height: 30px;
		padding-right: 10px;
		padding-left: 10px;
		display: inline-flex;
		align-items:center;
		.nav-link {
			text-align: center !important;
			padding: 0rem 0rem;
			height: 30px;
			display: inline-block;
			min-width: 78px;
			span{
				height: 11px;
				color: #FFFFFF;
				font-family: "Work Sans";
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 11px;
				text-align: center;
				display: inline-block;
			}
			.ebike-logo {
				height: 44px;
				width: 177.29px;
			}
			&.active {
				&:after {
					content: "";
					height: 3px;
					width: 50px;
					background-color: #FFFFFF;
					display: block;
					margin: 5px auto 12px;
					border-radius: 2px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%,0%);
				}
			}
		}
	}
}
.navbar2b-nav-container {
	padding-top: 15px;
	padding-bottom: 10px;
	background-color: #edeaea;
	width: 100%;
	.addBike-btn-b2b {
		height: 39.93px;
		width: 100px;
		border-radius: 15px;
		background-color: #FF9500!important;
		padding-top: 6px;
		padding-left: 0px;
		padding-right: 0px;
		.addBike-logo {
			height: 15.5px;
			width: 68.38px;
		}
	}
	.landingb2b-nav {
		height: 57.3px;
		align-items: center;
		.nav-item {
			height: 57.3px;
			.nav-link {
				color: #515151;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				//line-height: 53px;
				text-align: center;
				padding: 0rem .5rem;
				.navlink-logo {
					min-width: 205px;
					text-align: left;
					margin-top: 8px;
				}
				.navlink-News {
					min-width: 80px;
						padding-top: 17px;
				}
				.navlink-blog {
					min-width: 80px;
					padding-top: 17px;
				}
				.navlink-hersteller {
					min-width: 110px;
					padding-top: 17px;
				}
				.navlink-handler {
					min-width: 80px;
					padding-top: 17px;
				}
				.navlink-events {
					min-width: 133px;
					padding-top: 17px;
				}
				.navlink-null {
					min-width: 152px;
					padding-top: 11px;
					padding-right: 15px;
					text-align: right;
				}
				.navlink-borse {
					min-width: 95px;
					padding-top: 11px;
					padding-right: 15px;
					text-align: right;
				}
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				.ebike-logo {
					height: 44px;
					width: 177.29px;
				}
				&.active {
					color: #FF9500;
					font-weight: bold;
					&:after {
						content: "";
						height: 3px;
						width: 60px;
						background-color: #FF9500;
						display: block;
						margin: 5px auto 12px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

.search-tabs-b2b-news {
	height: 35px;
	width: 75%;
	border-radius: 10px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	margin: 0 auto;
	.nav-item {
		width: 50%;
		height: 100%;
		text-align: center;
		.nav-link {
			color: #515151;
			font-family: "Work Sans";
			font-weight: 500;
			letter-spacing: 0;
			text-align: center;
			font-size: 16px;
			padding: 0;
			padding-top: 4px;
			&.active {
				height: 100%;
				color: #FFFFFF;
				font-weight: bold;
				border-radius: 10px;
				background-color: #FF9500;
			}
		}
	}
}

.form-outline-b2b-news {
	.form-control{
		height: 42px;
		border-radius: 0.3125em;
		border: .5px solid #ccc8c8 !important;
		margin-bottom: 0.375em;
		padding: 0rem 1.2rem;
		background-color: #FFFFFF;
		color: #0c9845 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.select-wrapper {
	  position: relative;
	  background-color: #e9e9ed !important;
	}
	.fadeElement{
		top: 2.6rem!important;
		left: -0.1rem;
		background-color: #000;
		width: 102%;
	.fadeIn{
			top: 2.6rem!important;
			left: -0.1rem;
			background-color: #000;
			width: 102%;
		}
	}
	.dropdown-content li{
		background-color: #000;
		line-height: 0.9rem;
		:hover {
			background-color: #5b5959;
		}
		&.active{
			background-color: #5b5959;
		}
		span{
			color: #FFFFFF!important;
		  font-family: "Work Sans";
		  font-size: 16px;
		}
	}
	.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
	  color: rgba(255, 255, 255, 0.5) !important;
	  cursor: context-menu;
	  background-color: transparent !important;
	}
	.md-form {
		margin-top: 0;
		input[type="text"]:not(.browser-default){
			border-bottom: none;
		}
	}
	.select-wrapper span.caret {
	  margin-right: 15px;
	}
	.price-currency {
		margin-left: 0.375em;
		display: inline;
		font-size: 1.375em;
		line-height: 1.09091em;
		font-weight: 600;
		font-family: "Work Sans";
	}
	.form-label{
		color: #000000 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.form-anmerkung{
		font-family: "Work Sans";
		color: gray;
		font-size: 0.8125em;
		line-height: 1.38462em;
	}
}

.b2b-product-nav {
	//height: 57.3px;
	align-items: left;
	.nav-item {
		height: 50px;
		//margin-bottom: 5px;
		width: 224px;
		&.active {
			&:after {
				background-color: #e8e8e8;
			}
		}
		.nav-link {
			color: #515151;
			text-align: left;
			padding: .5rem;
			.col-height {
				height: 26px;
			}
			.profil-logo {
				margin-top:5px;
				height: 15px;
				width: 15px;
			}
			span{
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.28px;
				line-height: 26.37px;
			}
			&:hover {
				span{
					color: #865E26;
				}
			}
			&.active {
					content: "";
					//height: 42px;
					//width: 250px;
					//background-color: #c4c1c1;
					//display: block;
					//margin: 0px auto 5px;
					//border-radius: 4px;
					span{
						color: #FF9500;
						font-family: "Work Sans";
						font-size: 14px;
						font-weight: bold;
						letter-spacing: 0.28px;
						line-height: 26.37px;
					}
					&:hover {
						span{
							color: #865E26;
						}
					}
			}
		}
	}
}
.companies-who-are-pr {
	color: #515151;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.58px;
	line-height: 16px;
}
.hersteller-profile-image {
	position: absolute;
	left: 43%;
	top: 55px;
	z-index: 1000;
	width: 250px;
	height: 250px;
	border: 1px solid #fff;
}
.hersteller-profile-header-bg-b2b {
	width: 100%;
	min-height: 350px;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	//background-size: cover;
	border-radius: 0 0 20px 20px;
	margin-bottom: 100px;
}
.resetHersteller{
	width: 45px;
	padding: 0;
	position: absolute !important;
	top: -85px;
	left: -10px;
	img{
		height: 45px;
		width: 45px;
	}
}
.pagination .page-item.active .page-link {
  background-color: #FF9500;
  box-shadow: none;
	height: 35px;
	width: 35px;
	text-align: center;
}
.pagination .page-item.active .page-link:hover {
  background-color: #FF9500;
}
.find-supplier-grow {
  height: 63px;
  width: 241.83px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
	display: inline-block;
}
.join-meetings-easily {
  height: 57px;
  width: 412px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
	display: inline-block;
}
.hersteller-such-form {
	width: 100%;
	.search-col .md-form input[type="text"].search-input {
		box-sizing: border-box;
		height: 40.17px;
		width: 270px;
		border: 1px solid #919191;
		border-radius: 10.59px;
		background-color: #FFFFFF;
	}
	.search-col .md-form label {
	  left: 12px;
	  margin-top: -2px;
	}
	.hersteller-search-btn {
		height: 39.53px;
	  width: 39.53px;
	  border-radius: 10.59px;
	  background-color: #FF9500!important;
		margin: 0;
		padding: 0;
	}
}
.filter-btn-addKategorie-b2b {
	height: 42px;
  min-width: 432px;
	border-radius: 0.3125em;
	border: 2px solid #797575;
	min-height: 42px;
	margin-bottom: 0.375em;
	padding: 0rem 1.2rem;
  color: #777470!important;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}
.filter-btn-addKategorie-b2b:hover {
	background-color: #c1bfbf !important;
	color: #0e0f0f!important;
}

.filter-btn-back-b2b {
	height: 42px;
  min-width: 432px;
	border-radius: 0.3125em;
	border: 2px solid #797575;
	margin-bottom: 0.375em;
	padding: 0rem 1.2rem;
	background-color: #c1bfbf !important;
  color: #777470!important;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}
.filter-btn-back-b2b:hover {
	color: #0e0f0f!important;
}

.form-outline-bike-b2b {
	.form-control{
		height: 42px;
		border-radius: 0.3125em;
		border: .5px solid #ccc8c8 !important;
		margin-bottom: 0.375em;
		padding: 0rem 1.2rem;
		background-color: #FFFFFF;
		color: #777470 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.select-wrapper {
	  position: relative;
	  background-color: #e9e9ed !important;
	}
	.fadeElement{
		top: 2.6rem!important;
		left: -0.1rem;
		background-color: #000;
		width: 102%;
	.fadeIn{
			top: 2.6rem!important;
			left: -0.1rem;
			background-color: #000;
			width: 102%;
		}
	}
	.dropdown-content li{
		background-color: #000;
		line-height: 0.9rem;
		:hover {
			background-color: #5b5959;
		}
		&.active{
			background-color: #5b5959;
		}
		span{
			color: #FFFFFF!important;
		  font-family: "Work Sans";
		  font-size: 16px;
		}
	}
	.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
	  color: rgba(255, 255, 255, 0.5) !important;
	  cursor: context-menu;
	  background-color: transparent !important;
	}
	.md-form {
		margin-top: 0;
		input[type="text"]:not(.browser-default){
			border-bottom: none;
		}
	}
	.select-wrapper span.caret {
	  margin-right: 15px;
	}
	.price-currency {
		margin-left: 0.375em;
		display: inline;
		font-size: 1.375em;
		line-height: 1.09091em;
		font-weight: 600;
		font-family: "Work Sans";
	}
	.form-label{
		color: #000000 !important;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 14px;
		text-align: left;
	}
	.form-anmerkung{
		font-family: "Work Sans";
		color: gray;
		font-size: 0.8125em;
		line-height: 1.38462em;
	}
}


@media (max-width: 575.98px) {
	.find-supplier-grow {
	  //height: 46px;
	  width: default;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 21px;
		display: inline-block;
	}
	.join-meetings-easily {
	  //height: 66px;
	  width: default;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 10px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 15px;
		display: inline-block;
	}
	.hersteller-such-form {
		width: 100%;
		height: 60px;
		.search-col .md-form input[type="text"].search-input {
			box-sizing: border-box;
			height: 40px;
			width: 100%;
			border: 1px solid #919191;
			border-radius: 8px;
			background-color: #FFFFFF;
		}
		.search-col .md-form label {
		  left: 12px;
		  margin-top: -2px;
		}
		.hersteller-search-btn {
			height: 40px;
		  width: 40px;
		  border-radius: 8px;
		  background-color: #FF9500!important;
			margin: 0;
			padding: 0;
		}
	}
	.filter-btn-addKategorie-b2b {
	  min-width: 100%;
	}
	.filter-btn-back-b2b {
	  min-width: 100%;
	}
	.search-tabs-b2b-news {
		height: 35px;
		width: 75%;

		background: transparent;
		margin: 0 auto;
		.nav-item {
			width: 50%;
			height: 100%;
			text-align: center;

			.nav-link {
				height: 100%;
				background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
				border-radius: 10px;
				color: #515151;
				font-family: "Work Sans";
				font-weight: 500;
				letter-spacing: 0;
				text-align: center;
				font-size: 16px;
				margin: 0 15px 0 15px;
				&.active {
					height: 100%;
					color: #FFFFFF;
					font-weight: bold;
					border-radius: 10px;
					background: #FF9500;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.hersteller-such-form {
		width: 100%;
		.search-col .md-form input[type="text"].search-input {
			box-sizing: border-box;
			height: 40px;
			width: 100%;
			border: 1px solid #919191;
			border-radius: 8px;
			background-color: #FFFFFF;
		}
		.search-col .md-form label {
		  left: 12px;
		  margin-top: -2px;
		}
		.hersteller-search-btn {
			height: 40px;
		  width: 40px;
		  border-radius: 8px;
		  background-color: #FF9500!important;
			margin: 0;
			padding: 0;
		}
	}
	.find-supplier-grow {
	  height: 63px;
	  width: 241.83px;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 20px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 27px;
		display: inline-block;
	}
	.join-meetings-easily {
	  height: 57px;
	  width: 412px;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 12px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 16px;
		display: inline-block;
	}
	.search-tabs-b2b-news {
		height: 35px;
		width: 75%;

		background: transparent;
		margin: 0 auto;
		.nav-item {
			width: 50%;
			height: 100%;
			text-align: center;

			.nav-link {
				height: 100%;
				background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
				border-radius: 10px;
				color: #515151;
				font-family: "Work Sans";
				font-weight: 500;
				letter-spacing: 0;
				text-align: center;
				font-size: 16px;
				margin: 0 15px 0 15px;
				&.active {
					height: 100%;
					color: #FFFFFF;
					font-weight: bold;
					border-radius: 10px;
					background: #FF9500;
				}
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.hersteller-such-form {
		width: 100%;
		.search-col .md-form input[type="text"].search-input {
			box-sizing: border-box;
			height: 40px;
			width: 100%;
			border: 1px solid #919191;
			border-radius: 8px;
			background-color: #FFFFFF;
		}
		.search-col .md-form label {
		  left: 12px;
		  margin-top: -2px;
		}
		.hersteller-search-btn {
			height: 40px;
		  width: 40px;
		  border-radius: 8px;
		  background-color: #FF9500!important;
			margin: 0;
			padding: 0;
		}
	}
	.find-supplier-grow {
	  height: 63px;
	  width: 241.83px;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 23px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 27px;
		display: inline-block;
	}
	.join-meetings-easily {
	  height: 57px;
	  width: 412px;
	  color: #30445B;
	  font-family: "Work Sans";
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 16px;
		display: inline-block;
	}
	.search-tabs-b2b-news {
		height: 35px;
		width: 75%;

		background: transparent;
		margin: 0 auto;
		.nav-item {
			width: 50%;
			height: 100%;
			text-align: center;

			.nav-link {
				height: 100%;
				background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
				border-radius: 10px;
				color: #515151;
				font-family: "Work Sans";
				font-weight: 500;
				letter-spacing: 0;
				text-align: center;
				font-size: 16px;
				margin: 0 15px 0 15px;
				&.active {
					height: 100%;
					color: #FFFFFF;
					font-weight: bold;
					border-radius: 10px;
					background: #FF9500;
				}
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
