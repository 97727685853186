.b2b-top-addbike {
	min-height: 88vh;
	background-image: url('../../media/images/markt/Group 17.png');
	background-color: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(242,239,239,0.96) 84.6%, #F2EFEF 100%);
	background-size: 100% auto;
	background-repeat: no-repeat;
}


@media (max-width: 575.98px) {
	.b2b-top-addbike {
		min-height: 93vh;
		padding-top: 55px;
		background-size: auto 100%;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.b2b-top-addbike {
		min-height: 93vh;
		padding-top: 0;
		background-size: auto 100%;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.b2b-top-addbike {
		min-height: 93vh;
		padding-top: 110px;
		background-size: auto 100%;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
