
.bodywrapper {
	height: 100vh;
	background-color: #ffffff;
	background-image: url('../../media/images/signinbg.png')!important;
	background-size: 60% 100%;
	background-repeat: no-repeat;
	background-position: right center;
}



.logo {
	width: 112px;
	height: 101px;

}

.pagetitle {
	color: #30445B;
	font-size: 23px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px;
	margin-top: 43px;
}

@media (max-width: 575.98px) {
	body {
		padding-top: 0;
	}

	.bodywrapper {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.bodywrapper {
		background-size: 40% 100%;;
		background-repeat: no-repeat;
		background-position: right center;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	body {
		padding-top: 0;
	}
	.bodywrapper {
		background-size: 50% 100%;;
		background-repeat: no-repeat;
		background-position: right center;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	body {
		padding-top: 0;
	}
}

@media (min-width: 1200px) {

}
