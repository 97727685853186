.netzwerk-title {
	font-size: 16px;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 20px;
}

.netzwerk-text{
	font-family: "Source Sans Pro";
  font-size: 14px;
}

.netzwerk-ib2b {
	margin-bottom: 20px;
}
.netzwerk-paypage {
	margin-bottom: 20px;
	width: 25%;
}

.netzwerk-ib2b-b2b {
	margin-bottom: 20px;
	width: 100%;
}

.netzwerk-search {
	padding-top: 3rem!important;
	padding-bottom: 3rem!important;
	border-left: 2px solid rgb(255, 255, 255);
}

.netzwerk-search-btn {
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
}

.netzwerk-contacts-link {
	display: inline-block;
	width: 34px;
	height: 34px;
	border: 1px solid #30445B;
	border-radius: 17px;
	padding: 4px;
	&.gradient {
		background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%);
		border: 0;
	}
	img {
		width: 20px;
		height: 20px;
	}
}

.image-size{
	width: 80%;
	height: auto;
}

@media (max-width: 575.98px) {
	.netzwerk-search {
		padding-top: 0!important;
		padding-bottom: 0!important;
		border-left: 0;
	}
	.image-size{
		width: 90%;
		height: auto;
	}
	.netzwerk-title {
		font-size: 16px;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 15px;
	}
	.netzwerk-text{
	  font-size: 12px;
	}
	.netzwerk-ib2b {
		margin-bottom: 10px;
	}
	.netzwerk-paypage {
		margin-bottom: 20px;
		width: 80%;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.netzwerk-search {
		padding-top: 0!important;
		padding-bottom: 0!important;
		border-left: 0;
	}
	.netzwerk-paypage {
		margin-bottom: 20px;
		width: 80%;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {

	.testimonial-card {
		.card-body {
			h4 {
				font-size: 1rem;
			}
		}
	}
	.netzwerk-paypage {
		margin-bottom: 20px;
		width: 75%;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.testimonial-card {
		.card-body {
			h4 {
				font-size: 1.1rem;
			}
		}
	}
	.netzwerk-paypage {
		margin-bottom: 20px;
		width: 50%;
	}
}
