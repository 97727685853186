.arrow-icon{
  height:50px;
  width: auto;
  margin-top: -2.5rem;
}
.arrow-row{
  min-height: 105px;
  margin-top: -20px;
}

.filter-card-ab2b {
  .card-body {
    min-height: 1px;
    padding: 1.25rem;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  background-image: url('../../media/images/b2b/Group 26.png');
	background-size: 25% auto;
	background-position: right bottom;
	background-repeat: no-repeat;
	max-height: 590px;
  min-height: 150px;
	width: 100%;
	border-radius: 20px;
	background-color: #FFFFFF;
  .group_5 {
  	height: auto;
    width: 55%;
  }
  .newpost-1 {
    //height: 58.84px;
    width: 240.05px;
    color: #292929;
    font-family: "Work Sans";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.55px;
  }
  .newpost-2 {
    //height: 40.11px;
    width: 240.05px;
    color: #FF9500;
    font-family: "Work Sans";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16.47px;
  }
}

.sticky-card-b2b {
	position: sticky;
	top: 6.5rem;
	margin-bottom: 5px;
}

.main-col {
  height: 140px;
}
.group-22-logo{
  height: 36.48px;
  width: 147px;
}


.bike-parts-logo-main {
  height: 132.58px;
  width: 379px;
}
.main-text{
  height: 32px;
  max-width: 446px;
  color: #515151;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
}
.footer1-logo {
  height: 37.98px;
  width: 37.98px;
}
.footer2-logo {
  height: auto;
  width: 81.71px;
}
.b2b-main-contact-padding {
  padding-bottom: 100px;
}
.product-filter-text {
  color: #919191;
  font-family: "Work Sans";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}
.product-filter-btn {
  width: 116px;
  height: 32px;
  border-radius: 10px;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #FF9500 !important;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.product-filtercompany-button {
  margin: 0;
  padding: 0;
  width: 100%;
  height:42px;
  .profil-logo{
    width: 17px;
    height: auto;
    margin-top: -3px;
  }
  .profilpage-menu {
  	height: 29px;
    //width: 238.5px;
    color: #919191;
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.28px;
    line-height: 26.37px;
  }
  &.active {
    &:after {
      .profilpage-menu {
        color: #FF9500;
      }
    }
  }
}

#productlist .spinner-position {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%,-50%);
  .preloader-wrapper {
    width: 2rem;
    height: 2rem;
    .spinner-blue-only{
      border-color: #FF9500!important;
    }
  }

}
#chatlist .spinner-position {
  position: absolute;
  top: 150px;
  left: 45%;
  .preloader-wrapper {
    width: 2rem;
    height: 2rem;
    .spinner-blue-only{
      border-color: #00A441!important;
    }
  }

}
#chatlistb2b .spinner-position {
  position: absolute;
  top: 150px;
  left: 45%;
  .preloader-wrapper {
    width: 2rem;
    height: 2rem;
    .spinner-blue-only{
      border-color: #FF9500!important;
    }
  }

}

.kein-bild {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  opacity: 0.3;
}

@media (max-width: 409.98px) {

}

@media (min-width: 410px) and (max-width: 575.98px) {

}

@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
