.chat-markt-completed {
	.fix-chat-container {
		position: fixed;
		top: 150px;
		left: 0;
		overflow: hidden;
	}
	.chatlist-card-markt {
		height: calc(100vh - 8.2rem);
		border-radius: 20px;
		margin-bottom: 1rem;
		padding-bottom: 15px;
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-left: 1rem;
			padding-right: 1rem;
			overflow: hidden;
			.scrollbar {
				height: calc(100vh - 14.5rem);
			}
			.filter-text-green {
				color: #00A441;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 65px;
			}
			.filter-text-grau {
				opacity: 0.5;
				color: #101B4F;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 65px;
			}
			.filter-text-green-inactive {
				color: #00A441;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 75px;
			}
			.filter-text-grau-inactive {
				opacity: 0.5;
				color: #101B4F;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 75px;
			}
			.filter-icon {
				height: auto;
				width: 32px;
			}
			.list-group {
				max-width: 298px;
				.list-group-item {
					height: 64px;
					padding: .35rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					border-bottom:0;
					color: #000;
					.nav-link {
						padding: .5rem 0rem;
						height: 64px;
					}
					.photo {
						height: 35px;
						width: 35px;
						box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
						border-radius: 50%;
					}
					.chat-name {
						color: #101B4F;
						font-size: 13px;
						height: 20px;
						width: 100%;
					  font-family: "Work Sans";
					  letter-spacing: 0;
					  //line-height: 14px;
						display: inline-block;
					}
					.chat-name-white {
						color: #ffff;
					}
					.user-online {
						box-sizing: border-box;
						border-radius: 50%;
					  height: 14.5px;
					  width: 14.5px;
					  border: 2px solid #FFFFFF;
					  background-color: #62EC03;
						position: absolute;
						top: 24px;
						right: -3px;
					}
					.user-offline {
						box-sizing: border-box;
						border-radius: 50%;
						height: 14.5px;
						width: 14.5px;
						border: 2px solid #FFFFFF;
						background-color: rgb(161, 161, 170);
						position: absolute;
						top: 24px;
						right: -3px;
					}
					p {
						color: #9B9B9B;
						margin-bottom: 0;
					}
					span{
						font-size: 12px;
						color: #353030;
					}
				}
				.list-group-item-2 {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					border-bottom:0;
					color: #edeaea;
					.chat-name {
						height: 24px;
						width: 100%;
						color: #101B4F;
					  font-family: "Work Sans";
					  letter-spacing: 0;
					  line-height: 14px;
						font-size: 16px;
						display: inline-block;
					}
					.chat-name-white {
						color: #ffff;
					}
					p {
						color: #9B9B9B;
						margin-bottom: 0;
					}
					span{
						font-size: 12px;
						color: #353030;
					}
				}
			}
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.chathistory-card-markt {
		height: calc(100vh - 8.2rem);
		background-color: transparent;
		border-radius: 20px;
		margin: 0;
		padding: 0;
	}
	.chat-header-card {
		height: 80px;
		width: 100%;
		border-radius: 15px;
		background-color: #FFFFFF;
		.chat-icon{
			height: 41px;
			width: 41px;
			border-radius: 50%
		}
		.chat-title-text {
			color: #101B4F;
			font-family: "Source Sans Pro";
			font-size: 18px;
			letter-spacing: 0;
			line-height: 25px;
		}
		.chat-name-text {
			color: #9B9B9B;
		  font-family: "Source Sans Pro";
		  font-size: 10px;
		  letter-spacing: 0;
		  line-height: 14px;
		}
		.chatProfileName-icon{
			height: 41px;
			width: 41px;
			border-radius: 50%;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			color: #fff;
			background-color: #00A441;
			font-family: "Work Sans";
			font-size: 14px;
		}
	}
	.chat-messages-card {
	  height: calc(100vh - 17rem);
	  width: 100%;
	  border-radius: 10px;
	  background-color: transparent;
		margin-top: 10px;
		padding-left: 15px;
		.card-body {
			padding: 0;
		}
			.empty-chat {
				text-align: center!important;
				height: calc(100vh - 17rem);
				.avatar {
					margin: 0 auto;
				}
		}
		.chat-history-markt {
			//padding-bottom: 150px;
			.chat-msg {
				margin-bottom: 54px;
				.chat-msg-content {
					color: #ffffff;
					background-color: #00A441;
					padding-top: .4rem;
					padding-bottom: .4rem;
					padding-right: .5rem;
					padding-left: .5rem;
					font-size: 1rem;
					border-top-left-radius: 0;
					border-top-right-radius: .6rem;
					border-bottom-right-radius: .6rem;
					border-bottom-left-radius: .6rem;
					.chat-sender-name {
						font-family: "Source Sans Pro";
						font-size: 10px;
						letter-spacing: 0;
						margin-bottom: 0.5rem;
					}
				}
				.chat-time {
					font-size: .7rem;
					color: #9B9B9B;
				}
				a {
					color: #ffffff;
				}

			}
			.chat-msg-self {
				.chat-msg-content {
					background-color: #788998;
					border-top-left-radius: .6rem;
					border-top-right-radius: 0;
					text-align: right;
					box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
				}
			}
		}
	}
	.chat-input-card {
		//min-height: 138px;
	  width: 100%;
	  border-radius: 10px;
	  background-color: transparent;
		position: absolute;
		bottom: 0;
		left: 0;
		.input-img-padding{
			padding-left: 0;
			padding-right: 1.6rem;
		}
		.input-feld {
			min-height: 46px;
			width: 100%;
			border-radius: 10px;
			background-color: #FFFFFF;
			padding-left: 0px;
			padding-right: 15px;
			.min-height-col {
				min-height: 50px;
			}
			.chat-form-icon {
				width: 25px;
				height: 25px;
				//smargin: 16px 8px;
			}
			.chat-form-btn {
				width: 25px;
				height: 25px;
			}
			.form-control {
				border: none;
				height: 46px;
			}
			.photos-content  {
			    border-radius: 10px !important;
			}
			.image-upload{
				height: 51px;
			  width: 51px;
			  border-radius: 25.5px;
			  background-color: #F2EFEF;
				text-align: center;
				padding-top: 12px;
			label {
				display: inline-flex;
			}
			> input
			{
			    display: none;}
			img {
				height: 24px;
				width: 34.53px;
				cursor: pointer;
			}
			span {
				color: #00A441;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: bold;
			}
			}
			.image-upload2{
				text-align: center;
				padding-top: 3px;
				display: inline-block;
			label {
				display: inline-flex;
				margin-bottom: 0;
			}
			> input
			{
			    display: none;}
			img {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
			span {
				color: #00A441;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: bold;
			}
			}
		}

			}
	.deep-purple.darken-4 {
	  background-color: transparent!important;
	}
	.side-nav {
	  position: fixed;
		top: 149px;
		left: 0;
		width: 100% !important;
		height: 86vh;
	  background-color: transparent!important;
	  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
	  border-radius: 0;
		padding:0;
		z-index: 1005;
		a {
			line-height: 13px!important;
			font-size: .8rem!important;
		}
		.scrollbar-container {
		  position: relative;
		  height: 100%;
		  overflow: -moz-hidden-unscrollable !important;
		}
		.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
	  display: none!important;
	  background-color: transparent;
	}
	}
}
.chat-b2b-completed {
	.fix-chat-container {
		position: fixed;
		top: 161px;
		left: 0;
		overflow: hidden;
	}
	.chatlist-card-markt {
		height: calc(100vh - 8.2rem);
		border-radius: 20px;
		margin-bottom: 1rem;
		padding-bottom: 15px;
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-left: 1rem;
			padding-right: 1rem;
			overflow: hidden;
			.scrollbar {
				height: calc(100vh - 14.5rem);
			}
			.filter-text-green {
				color: #FF9500;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 65px;
			}
			.filter-text-grau {
				opacity: 0.5;
				color: #101B4F;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 65px;
			}
			.filter-text-green-inactive {
				color: #FF9500;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 75px;
			}
			.filter-text-grau-inactive {
				opacity: 0.5;
				color: #101B4F;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				height: 28px;
				display: inline-block;
				width: 75px;
			}
			.filter-icon {
				height: auto;
				width: 32px;
			}
			.list-group {
				max-width: 298px;
				.list-group-item {
					height: 64px;
					padding: .35rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					border-bottom:0;
					color: #000;
					.nav-link {
						padding: .5rem 0rem;
						height: 64px;
					}
					.photo {
						height: 35px;
						width: 35px;
						box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
						border-radius: 50%;
					}
					.chat-name {
						color: #101B4F;
						font-size: 13px;
						height: 20px;
						width: 100%;
					  font-family: "Work Sans";
					  letter-spacing: 0;
					  //line-height: 14px;
						display: inline-block;
					}
					.chat-name-white {
						color: #ffff;
					}
					.user-online {
						box-sizing: border-box;
						border-radius: 50%;
					  height: 14.5px;
					  width: 14.5px;
					  border: 2px solid #FFFFFF;
					  background-color: #62EC03;
						position: absolute;
						top: 24px;
						right: -3px;
					}
					.user-offline {
						box-sizing: border-box;
						border-radius: 50%;
						height: 14.5px;
						width: 14.5px;
						border: 2px solid #FFFFFF;
						background-color: rgb(161, 161, 170);
						position: absolute;
						top: 24px;
						right: -3px;
					}
					p {
						color: #9B9B9B;
						margin-bottom: 0;
					}
					span{
						font-size: 12px;
						color: #353030;
					}
				}
				.list-group-item-2 {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					border-bottom:0;
					color: #edeaea;
					.chat-name {
						height: 24px;
						width: 100%;
						color: #101B4F;
					  font-family: "Work Sans";
					  letter-spacing: 0;
					  line-height: 14px;
						font-size: 16px;
						display: inline-block;
					}
					.chat-name-white {
						color: #ffff;
					}
					p {
						color: #9B9B9B;
						margin-bottom: 0;
					}
					span{
						font-size: 12px;
						color: #353030;
					}
				}
			}
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.chathistory-card-markt {
		height: calc(100vh - 8.2rem);
		background-color: transparent;
		border-radius: 20px;
		margin: 0;
		padding: 0;
	}
	.chat-header-card {
		height: 80px;
		width: 100%;
		border-radius: 15px;
		background-color: #FFFFFF;
		.chat-icon{
			height: 41px;
			width: 41px;
			border-radius: 50%
		}
		.chat-title-text {
			color: #101B4F;
			font-family: "Source Sans Pro";
			font-size: 18px;
			letter-spacing: 0;
			line-height: 25px;
		}
		.chat-name-text {
			color: #9B9B9B;
		  font-family: "Source Sans Pro";
		  font-size: 10px;
		  letter-spacing: 0;
		  line-height: 14px;
		}
		.chatProfileName-icon{
			height: 41px;
			width: 41px;
			border-radius: 50%;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			color: #fff;
			background-color: #00A441;
			font-family: "Work Sans";
			font-size: 14px;
		}
	}
	.chat-messages-card {
	  height: calc(100vh - 19.5rem);
	  width: 100%;
	  border-radius: 10px;
	  background-color: transparent;
		margin-top: 10px;
		padding-left: 15px;
		.card-body {
			padding: 0;
		}
			.empty-chat {
				text-align: center!important;
				height: calc(100vh - 17rem);
				.avatar {
					margin: 0 auto;
				}
		}
		.chat-history-markt {
			//padding-bottom: 150px;
			.chat-msg {
				margin-bottom: 54px;
				.chat-msg-content {
					color: #ffffff;
					background-color: #FF9500;
					padding-top: .4rem;
					padding-bottom: .4rem;
					padding-right: .5rem;
					padding-left: .5rem;
					font-size: 1rem;
					border-top-left-radius: 0;
					border-top-right-radius: .6rem;
					border-bottom-right-radius: .6rem;
					border-bottom-left-radius: .6rem;
					.chat-sender-name {
						font-family: "Source Sans Pro";
						font-size: 10px;
						letter-spacing: 0;
						margin-bottom: 0.5rem;
					}
				}
				.chat-time {
					font-size: .7rem;
					color: #9B9B9B;
				}
				a {
					color: #ffffff;
				}

			}
			.chat-msg-self {
				.chat-msg-content {
					background-color: #788998;
					border-top-left-radius: .6rem;
					border-top-right-radius: 0;
					text-align: right;
					box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
				}
			}
		}
	}
	.chat-input-card {
		//min-height: 138px;
	  width: 100%;
	  border-radius: 10px;
	  background-color: transparent;
		position: absolute;
		bottom: 0;
		left: 0;
		.input-img-padding{
			padding-left: 0;
			padding-right: 1.6rem;
		}
		.input-feld {
			min-height: 46px;
			width: 100%;
			border-radius: 10px;
			background-color: #FFFFFF;
			padding-left: 0px;
			padding-right: 15px;
			.min-height-col {
				min-height: 50px;
			}
			.chat-form-icon {
				width: 25px;
				height: 25px;
				//smargin: 16px 8px;
			}
			.chat-form-btn {
				width: 25px;
				height: 25px;
			}
			.form-control {
				border: none;
				height: 46px;
			}
			.photos-content  {
			    border-radius: 10px !important;
			}
			.image-upload{
				height: 51px;
			  width: 51px;
			  border-radius: 25.5px;
			  background-color: #F2EFEF;
				text-align: center;
				padding-top: 12px;
			label {
				display: inline-flex;
			}
			> input
			{
			    display: none;}
			img {
				height: 24px;
				width: 34.53px;
				cursor: pointer;
			}
			span {
				color: #00A441;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: bold;
			}
			}
			.image-upload2{
				text-align: center;
				padding-top: 3px;
				display: inline-block;
			label {
				display: inline-flex;
				margin-bottom: 0;
			}
			> input
			{
			    display: none;}
			img {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
			span {
				color: #00A441;
			  font-family: "Work Sans";
			  font-size: 14px;
			  font-weight: bold;
			}
			}
		}

			}
	.deep-purple.darken-4 {
	  background-color: transparent!important;
	}
	.side-nav {
	  position: fixed;
		top: 160px;
		left: 0;
		width: 100% !important;
		height: 80vh;
	  background-color: transparent!important;
	  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
	  border-radius: 0;
		padding:0;
		z-index: 1005;
		a {
			line-height: 13px!important;
			font-size: .8rem!important;
		}
		.scrollbar-container {
		  position: relative;
		  height: 100%;
		  overflow: -moz-hidden-unscrollable !important;
		}
		.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
	  display: none!important;
	  background-color: transparent;
	}
	}
}
#sidenav-overlay {
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 997;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  will-change: opacity;
}


@media (max-width: 575.98px) {
	.chat-markt-completed {
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 112px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1000;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.respochat-padding-top {
			//padding-top: 150px;
		}
		.chatlist-card-markt {
			height: 100%;
			border-radius: 0;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.photo {
							height: 38px;
							width: 38px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.avatar {
							height: 38px;
							width: 38px;
						  min-width: .9rem;
						  display: inline-block;
						  position: relative;
						  border-radius: 50%;
						  box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
						}
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
							font-family: "Work Sans";
							letter-spacing: 0;
							line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.text-truncate {
							height: 24px;
						}
						.row-height-chatlist{
							height: 50px;
							img {
								margin-top: 5px;
							}
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
							height: 15.5px;
							width: 15.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 28px;
							left: 27px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 12.5px;
							width: 12.5px;
							border: 1px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 28px;
							left: 27px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							color: #101B4F;
							font-size: 16px;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.chat-input-card {
			//min-height: 138px;
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			position: absolute;
			padding-left: 10px;
			bottom: 0;
			left: 0;
			.input-img-padding{
				padding-left: 0;
				padding-right: 1rem;
				.user-photo-navbar {
				  height: 42px;
				  width: 42px;
				  border-radius: 50%;
				  border: 1px solid #7F8A83;
				  box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.15);
				}
			}
			.input-feld {
				min-height: 42px;
				width: 100%;
				border-radius: 10px;
				background-color: #FFFFFF;
				padding-left: 0px;
				padding-right: 0px;
				.min-height-col {
					min-height: 50px;
				}
				.chat-form-icon {
					width: 22px;
					height: 22px;
					//smargin: 16px 8px;
				}
				.chat-form-btn {
					width: 25px;
					height: 25px;
				}
				.form-control {
					border: none;
					height: 42px;
					padding: .375rem .25rem;
				}
				.photos-content  {
				    border-radius: 10px !important;
				}
				.image-upload{
					height: 51px;
				  width: 51px;
				  border-radius: 25.5px;
				  background-color: #F2EFEF;
					text-align: center;
					padding-top: 12px;
				label {
					display: inline-flex;
				}
				> input
				{
				    display: none;}
				img {
					height: 24px;
					width: 34.53px;
					cursor: pointer;
				}
				span {
					color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: bold;
				}
				}
				.image-upload2{
					text-align: center;
					padding-top: 0px;
					display: inline-flex;
					align-items: center;
					height: 100%;
				label {
					display: inline-flex;
					margin-bottom: 0;
				}
				> input
				{
				    display: none;}
				img {
					width: 22px;
					height: 22px;
					cursor: pointer;
				}
				span {
					color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: bold;
				}
				}
			}

				}
		.chathistory-card-markt {
			height: calc(100vh - 10.5rem);
			background-color: transparent;
			border-radius: 20px;
			margin: 0;
			padding: 0;
		}
		.chat-header-card {
			height: 60px;
			width: 100%;
			border-radius: 10px;
			background-color: #FFFFFF;
			.chat-icon{
				height: 41px;
				width: 41px;
				border-radius: 50%
			}
			.chat-title-text {
				color: #101B4F;
				font-family: "Source Sans Pro";
				font-size: 14px;
				letter-spacing: 0;
				line-height: 17px;
			}
			.chat-name-text {
				color: #9B9B9B;
			  font-family: "Source Sans Pro";
			  font-size: 10px;
			  letter-spacing: 0;
			  line-height: 14px;
			}
			.chatProfileName-icon{
				height: 41px;
				width: 41px;
				border-radius: 50%;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
		}
		.chat-messages-card {
		  height: calc(100vh - 19rem);
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			margin-top: 10px;
			padding-left: 10px;
			.card-body {
				padding: 0;
			}
				.empty-chat {
					text-align: center!important;
					height: calc(100vh - 26rem);
					margin-top: 110px;
					.avatar {
						margin: 0 auto;
					}
			}
			.chat-history-markt {
				//padding-bottom: 150px;
				.chat-msg {
					margin-bottom: 35px;
					.chat-msg-content {
						color: #ffffff;
						background-color: #00A441;
						padding-top: .4rem;
						padding-bottom: .4rem;
						padding-right: .5rem;
						padding-left: .5rem;
						font-size: 1rem;
						border-top-left-radius: 0;
						border-top-right-radius: .6rem;
						border-bottom-right-radius: .6rem;
						border-bottom-left-radius: .6rem;
						.chat-sender-name {
							font-family: "Source Sans Pro";
							font-size: 10px;
							letter-spacing: 0;
							margin-bottom: 0.5rem;
						}
					}
					.chat-time {
						font-size: .7rem;
						color: #9B9B9B;
					}
					a {
						color: #ffffff;
					}

				}
				.chat-msg-self {
					.chat-msg-content {
						background-color: #788998;
						border-top-left-radius: .6rem;
						border-top-right-radius: 0;
						text-align: right;
						box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
					}
				}
			}
		}
	}
	.chat-b2b-completed {
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 125px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1020;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.respochat-padding-top {
			//padding-top: 150px;
		}
		.chatlist-card-markt {
			height: 100%;
			border-radius: 0;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.photo {
							height: 38px;
							width: 38px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.avatar {
							height: 38px;
							width: 38px;
						  min-width: .9rem;
						  display: inline-block;
						  position: relative;
						  border-radius: 50%;
						  box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
						}
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
							font-family: "Work Sans";
							letter-spacing: 0;
							line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.text-truncate {
							height: 24px;
						}
						.row-height-chatlist{
							height: 50px;
							img {
								margin-top: 5px;
							}
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
							height: 15.5px;
							width: 15.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 28px;
							left: 27px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 12.5px;
							width: 12.5px;
							border: 1px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 28px;
							left: 27px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							color: #101B4F;
							font-size: 16px;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.chat-input-card {
			//min-height: 138px;
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			position: absolute;
			padding-left: 10px;
			bottom: 0;
			left: 0;
			.input-img-padding{
				padding-left: 0;
				padding-right: 1rem;
				.user-photo-navbar {
				  height: 42px;
				  width: 42px;
				  border-radius: 50%;
				  border: 1px solid #7F8A83;
				  box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.15);
				}
			}
			.input-feld {
				min-height: 42px;
				width: 100%;
				border-radius: 10px;
				background-color: #FFFFFF;
				padding-left: 0px;
				padding-right: 0px;
				.min-height-col {
					min-height: 50px;
				}
				.chat-form-icon {
					width: 22px;
					height: 22px;
					//smargin: 16px 8px;
				}
				.chat-form-btn {
					width: 25px;
					height: 25px;
				}
				.form-control {
					border: none;
					height: 42px;
					padding: .375rem .25rem;
				}
				.photos-content  {
				    border-radius: 10px !important;
				}
				.image-upload{
					height: 51px;
				  width: 51px;
				  border-radius: 25.5px;
				  background-color: #F2EFEF;
					text-align: center;
					padding-top: 12px;
				label {
					display: inline-flex;
				}
				> input
				{
				    display: none;}
				img {
					height: 24px;
					width: 34.53px;
					cursor: pointer;
				}
				span {
					color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: bold;
				}
				}
				.image-upload2{
					text-align: center;
					padding-top: 0px;
					display: inline-flex;
					align-items: center;
					height: 100%;
				label {
					display: inline-flex;
					margin-bottom: 0;
				}
				> input
				{
				    display: none;}
				img {
					width: 22px;
					height: 22px;
					cursor: pointer;
				}
				span {
					color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				  font-weight: bold;
				}
				}
			}

				}
		.chathistory-card-markt {
			height: calc(100vh - 11rem);
			background-color: transparent;
			border-radius: 20px;
			margin: 0;
			padding: 0;
		}
		.chat-header-card {
			height: 60px;
			width: 100%;
			border-radius: 10px;
			background-color: #FFFFFF;
			.chat-icon{
				height: 41px;
				width: 41px;
				border-radius: 50%
			}
			.chat-title-text {
				color: #101B4F;
				font-family: "Source Sans Pro";
				font-size: 14px;
				letter-spacing: 0;
				line-height: 17px;
			}
			.chat-name-text {
				color: #9B9B9B;
			  font-family: "Source Sans Pro";
			  font-size: 10px;
			  letter-spacing: 0;
			  line-height: 14px;
			}
			.chatProfileName-icon{
				height: 41px;
				width: 41px;
				border-radius: 50%;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
				background-color: #00A441;
				font-family: "Work Sans";
				font-size: 14px;
			}
		}
		.chat-messages-card {
		  height: calc(100vh - 19rem);
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			margin-top: 10px;
			padding-left: 10px;
			.card-body {
				padding: 0;
			}
				.empty-chat {
					text-align: center!important;
					height: calc(100vh - 26rem);
					margin-top: 110px;
					.avatar {
						margin: 0 auto;
					}
			}
			.chat-history-markt {
				//padding-bottom: 150px;
				.chat-msg {
					margin-bottom: 35px;
					.chat-msg-content {
						color: #ffffff;
						background-color: #FF9500;
						padding-top: .4rem;
						padding-bottom: .4rem;
						padding-right: .5rem;
						padding-left: .5rem;
						font-size: 1rem;
						border-top-left-radius: 0;
						border-top-right-radius: .6rem;
						border-bottom-right-radius: .6rem;
						border-bottom-left-radius: .6rem;
						.chat-sender-name {
							font-family: "Source Sans Pro";
							font-size: 10px;
							letter-spacing: 0;
							margin-bottom: 0.5rem;
						}
					}
					.chat-time {
						font-size: .7rem;
						color: #9B9B9B;
					}
					a {
						color: #ffffff;
					}

				}
				.chat-msg-self {
					.chat-msg-content {
						background-color: #788998;
						border-top-left-radius: .6rem;
						border-top-right-radius: 0;
						text-align: right;
						box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
					}
				}
			}
		}
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.chat-markt-completed {
		.chathistory-card-markt {
			height: calc(100vh - 10.5rem);
		}
		.chat-messages-card {
		  height: calc(100vh - 19rem);
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			margin-top: 10px;
			padding-left: 15px;
			.card-body {
				padding: 0;
			}
				.empty-chat {
					text-align: center!important;
					height: calc(100vh - 17rem);
					.avatar {
						margin: 0 auto;
					}
			}
			.chat-history-markt {
				//padding-bottom: 150px;
				.chat-msg {
					margin-bottom: 54px;
					.chat-msg-content {
						color: #ffffff;
						background-color: #00A441;
						padding-top: .4rem;
						padding-bottom: .4rem;
						padding-right: .5rem;
						padding-left: .5rem;
						font-size: 1rem;
						border-top-left-radius: 0;
						border-top-right-radius: .6rem;
						border-bottom-right-radius: .6rem;
						border-bottom-left-radius: .6rem;
						.chat-sender-name {
							font-family: "Source Sans Pro";
							font-size: 10px;
							letter-spacing: 0;
							margin-bottom: 0.5rem;
						}
					}
					.chat-time {
						font-size: .7rem;
						color: #9B9B9B;
					}
					a {
						color: #ffffff;
					}

				}
				.chat-msg-self {
					.chat-msg-content {
						background-color: #788998;
						border-top-left-radius: .6rem;
						border-top-right-radius: 0;
						text-align: right;
						box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
					}
				}
			}
		}
		.chatlist-card-markt {
			height: calc(100vh - 8.2rem);
			border-radius: 0px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 13px;
							height: 20px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 24px;
							right: -3px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 122px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1000;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.side-nav {
		  position: fixed;
			top: 161px;
			left: 0;
			width: 100% !important;
			height: 86vh;
		  background-color: transparent!important;
		  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
		  border-radius: 0;
			padding:0;

		}
	}
	.chat-b2b-completed {
		.chathistory-card-markt {
			height: calc(100vh - 10.5rem);
		}
		.chat-messages-card {
		  height: calc(100vh - 19rem);
		  width: 100%;
		  border-radius: 10px;
		  background-color: transparent;
			margin-top: 10px;
			padding-left: 15px;
			.card-body {
				padding: 0;
			}
				.empty-chat {
					text-align: center!important;
					height: calc(100vh - 17rem);
					.avatar {
						margin: 0 auto;
					}
			}
			.chat-history-markt {
				//padding-bottom: 150px;
				.chat-msg {
					margin-bottom: 54px;
					.chat-msg-content {
						color: #ffffff;
						background-color: #FF9500;
						padding-top: .4rem;
						padding-bottom: .4rem;
						padding-right: .5rem;
						padding-left: .5rem;
						font-size: 1rem;
						border-top-left-radius: 0;
						border-top-right-radius: .6rem;
						border-bottom-right-radius: .6rem;
						border-bottom-left-radius: .6rem;
						.chat-sender-name {
							font-family: "Source Sans Pro";
							font-size: 10px;
							letter-spacing: 0;
							margin-bottom: 0.5rem;
						}
					}
					.chat-time {
						font-size: .7rem;
						color: #9B9B9B;
					}
					a {
						color: #ffffff;
					}

				}
				.chat-msg-self {
					.chat-msg-content {
						background-color: #788998;
						border-top-left-radius: .6rem;
						border-top-right-radius: 0;
						text-align: right;
						box-shadow: 0 6px 8px 0 rgba(16,27,79,0.15);
					}
				}
			}
		}
		.chatlist-card-markt {
			height: calc(100vh - 8.2rem);
			border-radius: 0px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 13px;
							height: 20px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 24px;
							right: -3px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 122px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1000;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.side-nav {
		  position: fixed;
			top: 161px;
			left: 0;
			width: 100% !important;
			height: 86vh;
		  background-color: transparent!important;
		  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
		  border-radius: 0;
			padding:0;

		}
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.chat-markt-completed {
		.chathistory-card-markt {
			height: calc(100vh - 10.2rem);
		}
		.chatlist-card-markt {
			height: calc(100vh - 8.2rem);
			border-radius: 0px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 13px;
							height: 20px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 24px;
							right: -3px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 110px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1000;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.side-nav {
		  position: fixed;
			top: 149px;
			left: 0;
			width: 100% !important;
			height: 86vh;
		  background-color: transparent!important;
		  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
		  border-radius: 0;
			padding:0;

		}
	}
	.chat-b2b-completed {
		.chathistory-card-markt {
			height: calc(100vh - 11rem);
		}
		.chatlist-card-markt {
			height: calc(100vh - 8.5rem);
			border-radius: 0px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 13px;
							height: 20px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 24px;
							right: -3px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.grau-back {
			height: 33px;
		}
		.chatlist-respo-btn {
			position:fixed!important;
			top: 110px;
			left: 16px;
			margin: 0;
			padding: 0;
			z-index: 1000;
			.respo-hamburger-menu {
				width:30px;
				height: auto;
			}
		}
		.side-nav {
		  position: fixed;
			top: 149px;
			left: 0;
			width: 100% !important;
			height: 86vh;
		  background-color: transparent!important;
		  box-shadow: 0 0 0 0 rgba(0,0,0,0.16),0 0 0 0 rgba(0,0,0,0.12);
		  border-radius: 0;
			padding:0;

		}
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.chat-markt-completed {
		.chatlist-card-markt {
			height: calc(100vh - 8.2rem);
			border-radius: 20px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #00A441;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 10px;
							height: 13px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 22px;
							right: 12px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 10px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
	}
	.chat-b2b-completed {
		.chatlist-card-markt {
			height: calc(100vh - 8.2rem);
			border-radius: 20px;
			margin-bottom: 1rem;
			padding-bottom: 15px;
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				overflow: hidden;
				.scrollbar {
					height: calc(100vh - 14.5rem);
				}
				.filter-text-green {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-grau {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 65px;
				}
				.filter-text-green-inactive {
					color: #FF9500;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-text-grau-inactive {
					opacity: 0.5;
					color: #101B4F;
					font-family: "Work Sans";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					height: 28px;
					display: inline-block;
					width: 75px;
				}
				.filter-icon {
					height: auto;
					width: 32px;
				}
				.list-group {
					max-width: 298px;
					.list-group-item {
						height: 64px;
						padding: .35rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #000;
						.nav-link {
							padding: .5rem 0rem;
							height: 64px;
						}
						.photo {
							height: 35px;
							width: 35px;
							box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
							border-radius: 50%;
						}
						.chat-name {
							color: #101B4F;
							font-size: 10px;
							height: 13px;
							width: 100%;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  //line-height: 14px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						.user-online {
							box-sizing: border-box;
							border-radius: 50%;
						  height: 14.5px;
						  width: 14.5px;
						  border: 2px solid #FFFFFF;
						  background-color: #62EC03;
							position: absolute;
							top: 24px;
							right: -3px;
						}
						.user-offline {
							box-sizing: border-box;
							border-radius: 50%;
							height: 14.5px;
							width: 14.5px;
							border: 2px solid #FFFFFF;
							background-color: rgb(161, 161, 170);
							position: absolute;
							top: 22px;
							right: 12px;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 10px;
							color: #353030;
						}
					}
					.list-group-item-2 {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						border-bottom:0;
						color: #edeaea;
						.chat-name {
							height: 24px;
							width: 100%;
							color: #101B4F;
						  font-family: "Work Sans";
						  letter-spacing: 0;
						  line-height: 14px;
							font-size: 16px;
							display: inline-block;
						}
						.chat-name-white {
							color: #ffff;
						}
						p {
							color: #9B9B9B;
							margin-bottom: 0;
						}
						span{
							font-size: 12px;
							color: #353030;
						}
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
	}
}
