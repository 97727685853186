.fixed-top-landingb2b-respo {
	position: fixed;
	top: 0;
	z-index: 1005;
}
.landingb2bResponziv-nav-container {
	padding-top: 0px;
	padding-bottom: 10px;
	background-color: #FF9500;
	width: 100%;
	height: 100vh;
	.hamburegermenu-row {
		margin: 0;
		padding: 20px 15px 50px 15px;
	}
	.hamburegermenu-row-2{
		margin: 0;
		padding: 20px 15px 15px 15px;
	}
	.addBike-btn-landingb2b {
		text-transform: unset !important;
		margin: 10px 0 10px 0;
		padding: 5px 0 5px 0;
		height: 40px;
		width: 130px;
		border-radius: 11.45px;
		background-color: #FFFFFF !important;
		.addBike-logo {
			height: auto;
  		width: 88px;
		}
		.add-bike-text {
			color: #00A441;
			font-family: "Work Sans";
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.36px;
			line-height: 26.37px;
			text-align: center;
			height: 26px;
			width: 124px;
		}
	}
	.hambureger-menu {
		margin: 0;
		padding: 4px 7px 0 0;
		.hambureger-icon {
			height: 24px;
			width: auto;
		}
	}
	.logo-nav {
		.nav-link {
			margin-top:-8px;
			padding:0;
		}
	}
	.landingb2b-nav {
		//height: 57.3px;
		align-items: center;
		.linie {
			box-sizing: border-box;
		  height: 1px;
		  width: 241px;
		  border: 1px solid #FFFFFF;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.nav-item {
			height: 72px;
			width: 100%;
			.signinmarkt-logo{
				height: 16.63px;
  			width: 19px;
			}
			.nav-link {
				color: #30445B;
				text-align: center;
				padding: 0rem .5rem;
				width: 100%;
				margin-top: 10px;
				margin-bottom: 15px;
				.display-position {
					display: inline flex;
				}
				.userProfileName-icon {
				  height: 44px;
				  width: 44px;
				  border-radius: 50%;
				  display: inline-block;
				  color: #fff;
				  background-color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				}
				span{
					height: 26px;
				  width: 224px;
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18px;
				  font-weight: 500;
				  letter-spacing: 0.36px;
				  line-height: 26.37px;
				  text-align: center;
				}
				.ebike-logo {
					height: 44px;
  				width: 177.29px;
				}
				&.active {
					span{
					  color: #ffffff;
						font-weight: bold;
				 }
				}
			}
		}
	}
	.landingb2b-2-nav {
		//height: 57.3px;
		align-items: center;
		.linie {
			box-sizing: border-box;
		  height: 1px;
		  width: 241px;
		  border: 1px solid #FFFFFF;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.nav-item {
			height: 58px;
			width: 100%;
			.signinmarkt-logo{
				height: 16.63px;
  			width: 19px;
			}
			.nav-link {
				color: #30445B;
				text-align: center;
				padding: 0rem .5rem;
				width: 100%;
				margin-top: 10px;
				margin-bottom: 15px;
				.display-position {
					display: inline flex;
				}
				.userProfileName-icon {
				  height: 44px;
				  width: 44px;
				  border-radius: 50%;
				  display: inline-block;
				  color: #fff;
				  background-color: #00A441;
				  font-family: "Work Sans";
				  font-size: 14px;
				}
				span{
					height: 26px;
				  width: 224px;
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18px;
				  font-weight: 500;
				  letter-spacing: 0.36px;
				  line-height: 26.37px;
				  text-align: center;
				}
				.ebike-logo {
					height: 44px;
  				width: 177.29px;
				}
				&.active {
					span{
					  color: #ffffff;
						font-weight: bold;
				 }
				}
			}
		}
	}
}

.navbarcomponentRespo-nav-container-b2b {
	padding: 0;
	.signin-btn-dealer {
		max-width: 150px;
		width: 95%;
		height: 32px;
		border-radius: 16px;
		padding-top: 4px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #FF9500!important;
		text-transform: none;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 22px;
	}
	.hambureger-menu-mainpage {
		position: fixed;
		top:0;
		right:0;
		margin: 0;
		padding: 0 0 0 0;
		.hambureger-icon {
			height: 72px;
			width: auto;
		}
	}
	.blog-card-b2b {
		height: 64vh;
	  width: 100%;
		border-radius: 8px;
		background-color: #F1F1F1;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 3rem;
	}
	.e-bikeborse-logo3-b2b {
	  height: auto;
	  width: 70%;
	}
	.b2b-btn-respo-main {
	  height: 50px;
	  width: 50px;
	  border-radius: 13px;
	  background-color: #FF9500 !important;
	  margin: 0rem;
	  padding: 0rem 0rem;
		.vector-1 {
			height: 20px;
			width: 20px;
		}
	}
	.signin-btn-supplier {
		max-width: 150px;
		width: 95%;
		height: 32px;
		border-radius: 16px;
		padding-top: 4px;
		padding-left: 0px;
		padding-right: 0px;
		background-color: #292929!important;
		text-transform: none;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 22px;
	}
	.landing-nav-container, .landing-nav-container-scroll {
		transition: padding-top 0.8s, background-color 0.6s;
		padding-right: 0px;
		.hambureger-menu {
			margin: 0;
			padding: 0;
			.hambureger-icon {
				height: 72px;
  			width: auto;
			}
		}
		.fix-nav {
			height: 129px;
			width: 100%;
			box-shadow: 0 6px 9px 0 rgba(0,0,0,0.05);
			background-color: #F1F1F1;
		}
	}
	.landing-nav-container {
		padding-top: 20px;
		background-color: transparent;
		.hamburegermenu-row {
			margin: 0;
			padding: 0 15px 15px 15px;
		}
	}
	.landing-nav-container-scroll {
		//padding-top: 20px;
		background-color: #f0eded;
		height: 72px;
	  width: 100%;
	  box-shadow: 0 6px 9px 0 rgba(0,0,0,0.05);
		.hamburegermenu-row {
			margin: 0;
			padding: 0 0 0 0;
			height: 100%;
		}
		.product-search-markt-header {
			padding-left: 0px;
			padding-right: 0px;
			//margin-top: 4.5px;
			width: 100%;
			.form-control{
				height: 40px;
				border-radius: 11px;
				background-color: #FFFFFF;
				padding-left: 15px;
				padding-top:7px;
				color: #919191;
				font-family: "Work Sans";
				font-size: 12px;
			}
		  .borse-btn-header {
		    height: 40px;
		    width: 40px;
		    border-radius: 11px;
		    background-color: #00A441!important;
		    margin: 0rem;
		    padding: 0rem 0rem;
		    .vector-1 {
		      height: 20px;
		      width: 20px;
		    }
		  }
		}
		.ebike-logo-respo {
			height: 32px;
			width: auto;
			position: absolute;
			top: 15px;
			left: 15px;
		}
	}
	.landing-nav-container-scroll2 {
		transition: height 0.8s, background-color 0.8s;
		background-color: #f0eded;
		min-height: 130px;
	  width: 100%;
	  box-shadow: 0 6px 9px 0 rgba(0,0,0,0.05);
		.hamburegermenu-row {
			margin: 0;
			padding: 0 0 0 0;
			height: 72px;
		}
		.product-search-markt-header {
			padding-left: 22px;
			padding-right: 22px;
			height: 50px;
			width: 100%;
			.form-control{
				height: 40px;
				border-radius: 11px;
				background-color: #FFFFFF;
				padding-left: 15px;
				padding-top:7px;
				color: #919191;
				font-family: "Work Sans";
				font-size: 12px;
			}
		  .borse-btn-header {
		    height: 40px;
		    width: 40px;
		    border-radius: 11px;
		    background-color: #FF9500!important;
		    margin: 0rem;
		    padding: 0rem 0rem;
		    .vector-1 {
		      height: 20px;
		      width: 20px;
		    }
		  }
		}
		.ebike-logo-respo {
			height: 32px;
			width: auto;
			position: absolute;
			top: 15px;
			left: 15px;
		}
	}
	.ebike-logo-respo-main {
		height: 53.86px;
  	width: 217px;
	}
	.hambureger-menu {
		margin: 0;
		padding: 4px 7px 0 0;
		.hambureger-icon {
			height: 24px;
			width: auto;
		}
	}
	.bike-respo-main-text {
		height: 56px;
	  width: 273px;
	  color: #515151;
	  font-family: "Work Sans";
	  font-size: 11px;
	  font-weight: 500;
	  letter-spacing: 0.92px;
	  line-height: 16px;
	  text-align: center;
	}
	.product-search-markt-main {
		padding-left: 0px;
		padding-right: 0;
		max-width: 450px;
		width: 100%;
		.form-control{
			height: 50px;
			border-radius: 13px;
			background-color: #FFFFFF;
			padding-left: 15px;
			padding-top:7px;
			color: #919191;
			font-family: "Work Sans";
			font-size: 12px;
		}
		.borse-btn-header {
			height: 50px;
			width: 50px;
			border-radius: 13px;
			background-color: #00A441!important;
			margin: .375rem;
			padding: .54rem .75rem;
			.vector-1 {
				height: 20px;
				width: 20px;
			}
		}
	}
	.news-respo-logo {
		height: 48px;
  	width: 220.52px;
	}
	.gradient3 {
		left: 0px;
		bottom: 0px;
		position: absolute;
		height: 258px;
		width: 100%;
		border-radius: 8px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
	}
	.gradient4 {
		left: 0px;
		bottom: 0px;
		position: absolute;
		height: 360px;
		width: 100%;
		border-radius: 8px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
	}
	.bike-parts-logo-main-respo {
	  height: auto;
	  width: 80%;
	}
	.arrow-icon-respo {
	  height: 43px;
	  width: auto;
	  margin-top: -1.3rem;
		margin-bottom:5px;
	}
	.blog-card-b2b-respo {
		width: 100%;
		height: 67vh;
		padding:0;
		background-color: transparent;
	}
	.product-search-b2b-respo {
		padding-left: 0px;
		padding-right: 0;
		max-width: 600px;
		width: 100%;
		.form-control{
			height: 50px;
			border-radius: 13px;
			background-color: #FFFFFF;
			padding-left: 15px;
			padding-top: 7px;
			color: #919191;
			font-family: "Work Sans";
			font-size: 12px;
		}
	}
	.icon-newspage-2 {
		color: rgb(0, 0, 0);
		height: 67vh;
		display: inline-block;
		width: 100%;
			.allnews-btn{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
			  background-color: #FF9500;
				position: absolute;
				top: 85%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
			.allnews-btn-b2b{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
				background-color: #FF9500;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
			.allblog-btn{
				text-transform: unset !important;
				height: 40px;
			  width: 184px;
			  border-radius: 32.09px;
			  background-color: #00A441;
				position: absolute;
				top: 60%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding:0;
				margin: 0;
			}
			.allnews-respo{
			  color: #FFFFFF;
			  font-family: "Work Sans";
			  font-size: 18.41px;
			  font-weight: bold;
			  letter-spacing: 0.77px;
			  line-height: 21px;
			}
			.allblog-respo{
			  color: #FFFFFF;
			  font-family: "Work Sans";
			  font-size: 18.41px;
			  font-weight: bold;
			  letter-spacing: 0.77px;
			  line-height: 21px;
			}
	}
	.interb2b-card-respo {
		min-height: 290px;
		width: 100%;
		border-radius: 8px;
		margin-bottom: 20px;
		padding-top: 25px;
		padding-bottom: 15px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #FFFFFF;
		box-shadow: 3px 10px 9px 6px rgba(16, 27, 79, 0.1);
		.card-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 1rem;
			margin-right: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #ccc;
			color: #0360EC;
			text-align: center;
			a {
				color: #000;
			}
		}
		.card-body {
			padding:0;
			padding-left: 1rem;
			padding-right: 1rem;
			.list-group {
				.list-group-item {
					padding: .75rem 0;
					border-left:0;
					border-right:0;
					border-top:0;
					color: #000;
				}
			}
		}
		.card-footer {
			background-color: #ffffff;
			border-top: 0;
			border-radius: .5rem;
		}
	}
	.balazs-boda-interb-2-b-respo {
		//height: 54px;
		width: 187px;
		color: #FFFFFF;
		font-family: "Work Sans";
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 18px;
		display:inline-flex;
	}
	.profile {
	  height: 60px;
	  width: 60px;
	  border-radius: 50%;
	}
	.liebe-interessenten {
		height: 112px;
		width: 100%;
		max-width: 270px;
		color: #808080;
		font-family: "Work Sans";
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 20px;
		display:inline-block;
	}
	.rectangle-1 {
		height: 120px;
  	width: 5px;
	}
	.firmen {
	  height: auto;
	  width: 40%;
	}
	.rectangle-footer-markt-respo {
	  min-height: 12vh;
	  width: 100%;
		background-color: #1D1D1D;
		position: absolute;
		bottom: 0;
		margin-top:5px;
	}
	.innerref-padding {
		height: 75px;
	}
	.landingpage-first-bild {
		height: auto;
		width: 55%;
	}
	.landingpage-second-bild {
		height: auto;
		width: 85%;
	}
	.landingpage-first-bild-bike {
		height: auto;
		width: 90%;
	}
	.landingpage-second-bild-bike {
		height: auto;
		width: 85%;
	}
	.landingpage-rectangle-1 {
		height: 130px;
  	width: 8px;
	}
	.landingpage-text-b2b {
		//height: 100px;
	  //width: 249px;
	  color: #808080;
	  font-family: "Work Sans";
	  font-size: 13px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 24px;
	}
	.landingpage-title{
		//height: 28px;
		//width: 264px;
		color: #292929;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 28px;
		text-align: center;
	}
}

.menu-modal-b2b {
	max-height: 100vh;
	.card-body {
	  padding: 0rem;
		height: 80vh;
	}
	.main-logo{
		width: 150px;
		height: auto;
	}
	.search-logo{
		width: 25px;
		height: auto;
		margin-left: 9px;
		margin-top: -8px;
	}
}

.sortierungbutton-b2b{
	margin: 0;
	padding: 0;
	img {
		height: auto;
		width: 25px;
	}
}
.sortierung-text-b2b {
	color: #515151;
	font-family: "Work Sans";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.58px;
	line-height: 16px;
	text-align: center;
	margin-right: -15px;
}
.footer-row-bottom-b2b {
	padding-bottom: 7.5rem;
}

@media (min-width: 576px) and (max-width: 767.98px) {

	.landingb2bResponziv-nav-container {
		padding-top: 15px;
		padding-bottom: 10px;
		background-color: #00A441;
		width: 100%;
		height: 100vh;
		.addBike-btn-landingb2b {
			margin: 10px 0 10px 0;
			padding: 0;
			height: 43.11px;
			width: 142px;
			border-radius: 11.45px;
			background-color: #FFFFFF !important;
			.addBike-logo {
				height: 19.07px;
	  		width: 84.1px;
			}
		}
		.close-button {
			padding: .5rem 0.5rem;
			.close-logo {
				height: 16px;
	  		width: 16px;
			}
		}
		.landingb2b-nav {
			//height: 57.3px;
			align-items: center;
			.linie {
				box-sizing: border-box;
			  height: 1px;
			  width: 241px;
			  border: 1px solid #FFFFFF;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.nav-item {
				//height: 57.3px;
				width: 100%;
				.signinmarkt-logo{
					height: 16.63px;
	  			width: 19px;
				}
				.nav-link {
					color: #30445B;
					text-align: center;
					padding: 0rem .5rem;
					width: 100%;
					margin-top: 15px;
					margin-bottom: 15px;
					.display-position {
						display: inline flex;
					}
					.userProfileName-icon {
					  height: 44px;
					  width: 44px;
					  border-radius: 50%;
					  display: inline-block;
					  color: #fff;
					  background-color: #00A441;
					  font-family: "Work Sans";
					  font-size: 14px;
					}
					span{
						height: 26px;
					  width: 224px;
					  color: #FFFFFF;
					  font-family: "Work Sans";
					  font-size: 18px;
					  font-weight: 500;
					  letter-spacing: 0.36px;
					  line-height: 26.37px;
					  text-align: center;
					}
					.ebike-logo {
						height: 44px;
	  				width: 177.29px;
					}
					&.active {
						span{
						  color: #000000;
					 }
					}
				}
			}
		}
	}

	.navbarcomponentRespo-nav-container-b2b {
		padding: 0;
		.landing-nav-container {
			padding-top: 20px;
			background-color: transparent;
			.hamburegermenu-row {
				margin: 0;
				padding: 0 15px 15px 15px;
			}
		}
		.landing-nav-container-scroll {
			background-color: #f0eded;
			height: 72px;
		  width: 100%;
		  box-shadow: 0 6px 9px 0 rgba(0,0,0,0.05);
			.hamburegermenu-row {
				margin: 0;
				padding: 0 15px 15px 15px;
			}
			.product-search-markt-header {
				padding-left: 0px;
				padding-right: 0px;
				//margin-top: 4.5px;
				width: 100%;
				.form-control{
					height: 40px;
					border-radius: 11px;
					background-color: #FFFFFF;
					padding-left: 15px;
					padding-top:7px;
					color: #919191;
					font-family: "Work Sans";
					font-size: 12px;
				}
				.borse-btn-header {
					height: 40px;
					width: 40px;
					border-radius: 11px;
					background-color: #00A441!important;
					margin: 0rem;
					padding: 0rem 0rem;
					.vector-1 {
						height: 20px;
						width: 20px;
					}
				}
			}
			.ebike-logo-respo {
				height: 32px;
				width: auto;
				position: absolute;
				top: 15px;
				left: 15px;
			}
		}

		.ebike-logo-respo-main {
			height: 53.86px;
	  	width: 217px;
		}
		.bike-respo-main-text {
			height: 56px;
		  width: 273px;
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 11px;
		  font-weight: 500;
		  letter-spacing: 0.92px;
		  line-height: 16px;
		  text-align: center;
		}
		.product-search-markt-main {
			padding-left: 0px;
			padding-right: 0;
			max-width: 450px;
			width: 100%;
			.form-control{
				height: 50px;
				border-radius: 13px;
				background-color: #FFFFFF;
				padding-left: 15px;
				padding-top:7px;
				color: #919191;
				font-family: "Work Sans";
				font-size: 16px;
			}
			.borse-btn-header {
				height: 50px;
				width: 50px;
				border-radius: 13px;
				background-color: #00A441!important;
				margin: .375rem;
				padding: .54rem .75rem;
				.vector-1 {
					height: 20px;
					width: 20px;
				}
			}
		}
		.news-respo-logo {
			height: 48px;
	  	width: 220.52px;
		}
		.gradient3 {
			left: 0px;
			bottom: 0px;
			position: absolute;
			height: 258px;
			width: 100%;
			border-radius: 8px;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
		}
		.gradient4 {
			left: 0px;
			bottom: 0px;
			position: absolute;
			height: 258px;
			width: 100%;
			border-radius: 8px;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
		}
		.icon-newspage-2 {
			color: rgb(0, 0, 0);
			height: 74vh;
			display: inline-block;
			.allnews-btn{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
				background-color: #FF9500;
				position: absolute;
				top: 85%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
			.allnews-btn-b2b{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
				background-color: #FF9500;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
				.allblog-btn{
					text-transform: unset !important;
					height: 40px;
				  width: 184px;
				  border-radius: 32.09px;
				  background-color: #00A441;
					position: absolute;
					top: 60%;
					left: 50%;
					transform: translate(-50%,-50%);
					z-index: 999;
					padding:0;
					margin: 0;
				}
				.allnews-respo{
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18.41px;
				  font-weight: bold;
				  letter-spacing: 0.77px;
				  line-height: 21px;
				}
				.allblog-respo{
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18.41px;
				  font-weight: bold;
				  letter-spacing: 0.77px;
				  line-height: 21px;
				}
		}

		.interb2b-card-respo {
			min-height: 290px;
			width: 100%;
			border-radius: 8px;
			margin-bottom: 20px;
			padding-top: 25px;
			padding-bottom: 15px;
			padding-left: 20px;
			padding-right: 20px;
			background-color: #FFFFFF;
			box-shadow: 3px 10px 9px 6px rgba(16, 27, 79, 0.1);
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				.list-group {
					.list-group-item {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						color: #000;
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.balazs-boda-interb-2-b-respo {
			//height: 54px;
			width: 187px;
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
			display:inline-flex;
		}
		.profile {
		  height: 60px;
		  width: 60px;
		  border-radius: 50%;
		}
		.liebe-interessenten {
			//height: 180px;
			max-width: 270px;
			color: #808080;
			font-family: "Work Sans";
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 20px;
			display:inline-block;
		}
		.rectangle-1 {
			height: 71px;
	  	width: 5px;
		}
		.firmen {
		  height: auto;
		  width: 45%;
		}
		.rectangle-footer-markt-respo {
		  min-height: 12vh;
		  width: 100%;
			background-color: #1D1D1D;
		}
		.innerref-padding {
			height: 140px;
		}
	}

	.menu-modal-b2b {
		.modal-dialog {
		  width: 100%;
		  margin: 0rem;
		}
		.modal-content {
		  border-radius: 0px !important;
		}
		.modal-body {
		  padding: 0rem;
		}
		.main-logo{
			width: 150px;
			height: auto;
		}
	}

	.sortierungbutton-b2b{
		margin: 0;
		padding: 0;
		img {
			height: auto;
			width: 25px;
		}
	}
	.sortierung-text-b2b {
		color: #515151;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.58px;
		line-height: 16px;
		text-align: center;
		margin-right: -55px;
}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.landingb2bResponziv-nav-container {
		padding-top: 15px;
		padding-bottom: 10px;
		background-color: #00A441;
		width: 100%;
		height: 100vh;
		.addBike-btn-landingb2b {
			margin: 10px 0 10px 0;
			padding: 0;
			height: 43.11px;
			width: 142px;
			border-radius: 11.45px;
			background-color: #FFFFFF !important;
			.addBike-logo {
				height: 19.07px;
	  		width: 84.1px;
			}
		}
		.close-button {
			padding: .5rem 0.5rem;
			.close-logo {
				height: 16px;
	  		width: 16px;
			}
		}
		.landingb2b-nav {
			//height: 57.3px;
			align-items: center;
			.linie {
				box-sizing: border-box;
			  height: 1px;
			  width: 241px;
			  border: 1px solid #FFFFFF;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.nav-item {
				//height: 57.3px;
				width: 100%;
				.signinmarkt-logo{
					height: 16.63px;
	  			width: 19px;
				}
				.nav-link {
					color: #30445B;
					text-align: center;
					padding: 0rem .5rem;
					width: 100%;
					margin-top: 15px;
					margin-bottom: 15px;
					.display-position {
						display: inline flex;
					}
					.userProfileName-icon {
					  height: 44px;
					  width: 44px;
					  border-radius: 50%;
					  display: inline-block;
					  color: #fff;
					  background-color: #00A441;
					  font-family: "Work Sans";
					  font-size: 14px;
					}
					span{
						height: 26px;
					  width: 224px;
					  color: #FFFFFF;
					  font-family: "Work Sans";
					  font-size: 18px;
					  font-weight: 500;
					  letter-spacing: 0.36px;
					  line-height: 26.37px;
					  text-align: center;
					}
					.ebike-logo {
						height: 44px;
	  				width: 177.29px;
					}
					&.active {
						span{
						  color: #000000;
					 }
					}
				}
			}
		}
	}

	.navbarcomponentRespo-nav-container-b2b {
		padding: 0;
		.landing-nav-container, .landing-nav-container-scroll {
			transition: padding-top 0.8s, background-color 0.6s;
			padding-right: 0px;
		}
		.ebike-logo-respo-main {
			height: 53.86px;
	  	width: 217px;
		}
		.bike-respo-main-text {
			height: 56px;
		  width: 273px;
		  color: #515151;
		  font-family: "Work Sans";
		  font-size: 11px;
		  font-weight: 500;
		  letter-spacing: 0.92px;
		  line-height: 16px;
		  text-align: center;
		}
		.product-search-markt-main {
			padding-left: 0px;
			padding-right: 0;
			max-width: 450px;
			width: 100%;
			.form-control{
				height: 50px;
				border-radius: 13px;
				background-color: #FFFFFF;
				padding-left: 15px;
				padding-top:7px;
				color: #919191;
				font-family: "Work Sans";
				font-size: 12px;
			}
			.borse-btn-header {
				height: 50px;
				width: 50px;
				border-radius: 13px;
				background-color: #00A441!important;
				margin: .375rem;
				padding: .54rem .75rem;
				.vector-1 {
					height: 20px;
					width: 20px;
				}
			}
		}
		.news-respo-logo {
			height: 48px;
	  	width: 220.52px;
		}
		.gradient3 {
			left: 0px;
			bottom: 0px;
			position: absolute;
			height: 258px;
			width: 100%;
			border-radius: 8px;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
		}
		.gradient4 {
			left: 0px;
			bottom: 0px;
			position: absolute;
			height: 360px;
			width: 100%;
			border-radius: 8px;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.58) 26.65%, rgba(255,255,255,0.87) 44.3%, #FFFFFF 100%);
		}
		.icon-newspage-2 {
			color: rgb(0, 0, 0);
			height: 74vh;
			display: inline-block;
			.allnews-btn{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
				background-color: #FF9500;
				position: absolute;
				top: 85%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
			.allnews-btn-b2b{
				text-transform: unset !important;
				height: 40px;
				width: 150px;
				border-radius: 32.09px;
				background-color: #FF9500;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				padding: 0;
				margin: 0;
			}
				.allblog-btn{
					text-transform: unset !important;
					height: 40px;
				  width: 184px;
				  border-radius: 32.09px;
				  background-color: #00A441;
					position: absolute;
					top: 60%;
					left: 50%;
					transform: translate(-50%,-50%);
					z-index: 999;
					padding:0;
					margin: 0;
				}
				.allnews-respo{
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18.41px;
				  font-weight: bold;
				  letter-spacing: 0.77px;
				  line-height: 21px;
				}
				.allblog-respo{
				  color: #FFFFFF;
				  font-family: "Work Sans";
				  font-size: 18.41px;
				  font-weight: bold;
				  letter-spacing: 0.77px;
				  line-height: 21px;
				}
		}
		.interb2b-card-respo {
			min-height: 290px;
			width: 100%;
			border-radius: 8px;
			margin-bottom: 20px;
			padding-top: 25px;
			padding-bottom: 15px;
			padding-left: 20px;
			padding-right: 20px;
			background-color: #FFFFFF;
			box-shadow: 3px 10px 9px 6px rgba(16, 27, 79, 0.1);
			.card-title {
				font-size: 14px;
				font-weight: 600;
				margin-left: 1rem;
				margin-right: 1rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ccc;
				color: #0360EC;
				text-align: center;
				a {
					color: #000;
				}
			}
			.card-body {
				padding:0;
				padding-left: 1rem;
				padding-right: 1rem;
				.list-group {
					.list-group-item {
						padding: .75rem 0;
						border-left:0;
						border-right:0;
						border-top:0;
						color: #000;
					}
				}
			}
			.card-footer {
				background-color: #ffffff;
				border-top: 0;
				border-radius: .5rem;
			}
		}
		.balazs-boda-interb-2-b-respo {
			//height: 54px;
			width: 187px;
			color: #FFFFFF;
			font-family: "Work Sans";
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
			display:inline-flex;
		}
		.profile {
		  height: 60px;
		  width: 60px;
		  border-radius: 50%;
		}
		.liebe-interessenten {
			//height: 180px;
			max-width: 270px;
			color: #808080;
			font-family: "Work Sans";
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 20px;
			display:inline-block;
		}
		.rectangle-1 {
			height: 71px;
	  	width: 5px;
		}
		.firmen {
		  height: auto;
		  width: 30%;
		}
		.rectangle-footer-markt-respo {
		  min-height: 12vh;
		  width: 100%;
			background-color: #1D1D1D;
			position: absolute;
			bottom: 0;
			margin-top:5px;
		}
		.innerref-padding {
			height: 75px;
		}
		.landingpage-first-bild {
			height: auto;
			width: 35%;
		}
		.landingpage-second-bild {
			height: auto;
			width: 60%;
		}
		.landingpage-first-bild-bike {
			height: auto;
			width: 70%;
		}
		.landingpage-second-bild-bike {
			height: auto;
			width: 70%;
		}
		.landingpage-rectangle-1 {
			height: 130px;
	  	width: 8px;
		}
		.landingpage-text-b2b {
			//height: 100px;
		  //width: 249px;
		  color: #808080;
		  font-family: "Work Sans";
		  font-size: 13px;
		  font-weight: 500;
		  letter-spacing: 0;
		  line-height: 24px;
		}
		.landingpage-title{
			//height: 28px;
			//width: 264px;
			color: #292929;
			font-family: "Work Sans";
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 28px;
			text-align: center;
		}
	}

	.menu-modal-b2b {
		.modal-dialog {
		  width: 100%;
		  margin: 0rem;
		}
		.modal-content {
		  border-radius: 0px !important;
		}
		.modal-body {
		  padding: 0rem;
		}
		.main-logo{
			width: 150px;
			height: auto;
		}
	}

	.sortierungbutton-b2b{
		margin: 0;
		padding: 0;
		img {
			height: auto;
			width: 25px;
		}
	}
	.sortierung-text-b2b {
		color: #515151;
		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.58px;
		line-height: 16px;
		text-align: center;
		margin-right: -75px;
}
}
